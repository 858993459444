import React from "react";

import styled from "styled-components";
import { Divider, Progress } from "antd";
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis } from "recharts";
import devices from "../../helpers/sizes";
import { largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import { useClassDashboard } from "../../hooks/useClassDashboard";
import { validationNumber } from "../../helpers/validationNumber";
import CustomTooltip from "../customTooltip/CustomTooltip";
import { ResponsiveBar } from "@nivo/bar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { urlBase } from "../../services/services";

const MidCardSupervisor = ({
	ventasDia,
	porcentajeVentas,
	porcentajeApplication,
	porcentajeDirectas,
	totalCulminadas,
	porcentajeTotalCulminadas,
	dataGraphRelation,
	dataGraphSales,
	mostSoldPlans,
	totalVentas,
	porcentajeTotalVentas,
}) => {
	const dataState = useSelector((state) => state.authState);

	const percentSignoSales = Math.abs(porcentajeVentas);
	const percentSignoApplication = Math.abs(porcentajeApplication);
	const percentSignoDirect = Math.abs(porcentajeDirectas);
	const percentSignoCompletedSales = Math.abs(porcentajeTotalCulminadas);
	const percentSignoTotalSales = Math.abs(porcentajeTotalVentas);

	const ventas = useClassDashboard(porcentajeVentas);
	const application = useClassDashboard(porcentajeApplication);
	const directas = useClassDashboard(porcentajeDirectas);
	const ventasCulminadas = useClassDashboard(porcentajeTotalCulminadas);
	const totalSales = useClassDashboard(porcentajeTotalVentas);

	const percentSignoSalesFixed = percentSignoSales.toFixed(2);
	const percentSignoApplicationFixed = percentSignoApplication.toFixed(2);
	const percentSignoDirectFixed = percentSignoDirect.toFixed(2);
	const percentSignoCompletedSalesFixed = percentSignoCompletedSales.toFixed(2);
	const percentSignoTotalSalesFixed = percentSignoTotalSales.toFixed(2);

	const newDataSalesRelationGraph = dataGraphRelation.map((item) => {
		if (item.name === "App") {
			return {
				...item,
				styledClasses: application,
				percentSign: percentSignoApplication,
				percentSignFixed: percentSignoApplicationFixed,
			};
		} else {
			return {
				...item,
				styledClasses: directas,
				percentSign: percentSignoDirect,
				percentSignFixed: percentSignoDirectFixed,
			};
		}
	});

	const months = [
		"ENE",
		"FEB",
		"MAR",
		"ABR",
		"MAY",
		"JUN",
		"JUL",
		"AGO",
		"SEP",
		"OCT",
		"NOV",
		"DIC",
	];

	const newDataGraphSales = dataGraphSales.map((item) => {
		return {
			...item,
			mes: months[item.mes],
		};
	});

	return (
		<Container>
			<div className="mid_section">
				<div className="recents">
					<div className="space-between description">
						<div className="midCardsRow align-center">
							<p className="title-card">Relación de ventas</p>
							<CustomTooltip
								subtitle={`Resumen de venta de servicios de ${dataState?.operatorName} por los distintos canales.`}
								iconColor="hardBlue"
								position={"bottomLeft"}
								arrow={false}
							/>
						</div>
					</div>
					<div className="sales">
						<p className="number-sales">{ventasDia} ventas</p>
						<div className="midCardsRow align-center">
							<p className={ventas?.textSales}>
								(
								{validationNumber(percentSignoSales)
									? percentSignoSalesFixed
									: percentSignoSales}
								%)
							</p>
							<span className="text-respect" style={{ marginLeft: "10px" }}>
								Respecto al día anterior
							</span>
						</div>
					</div>

					<div style={{ height: "400px" }}>
						<ResponsiveBar
							data={newDataSalesRelationGraph}
							indexBy="name"
							keys={["quantity"]}
							margin={{ top: 20, bottom: 30, left: 40, right: 40 }}
							colors={{ scheme: "category10" }}
							padding={0.8}
							borderRadius={15}
							groupMode="grouped"
							enableLabel={false}
							tooltip={(bar) => {
								return (
									<div className="bar-graph-modal">
										<div className="midCardsRow align-center">
											<p className="bar-graph-sales">{bar.data.quantity}</p>
											<p className="bar-graph-text">&nbsp;Ventas</p>
										</div>
										<div className="midCardsRow align-center">
											<p className={bar.data.styleClasses?.textCard}>
												{validationNumber(bar.data.percentSign)
													? bar.data.percentSignFixed
													: bar.data.percentSign}{" "}
												%
											</p>
											<i className={bar.data.styleClasses?.classCard}></i>
										</div>
										<span className="text-respect">
											Respecto al día anterior
										</span>
									</div>
								);
							}}
						/>
					</div>
				</div>

				<div className="graph_1">
					<div className="tittle_cards">
						<p className="title-card">Ventas culminadas</p>
						<CustomTooltip
							subtitle={`Total de ventas culminadas asociadas a ${dataState?.operatorName}.`}
							iconColor="hardBlue"
							position={"bottomLeft"}
							arrow={false}
						/>
					</div>

					<div className="sales">
						<p className="number-sales">{totalCulminadas} ventas</p>
						<div className="midCardsRow align-center">
							<p className={ventasCulminadas?.textSales}>
								{validationNumber(percentSignoCompletedSales)
									? percentSignoCompletedSalesFixed
									: percentSignoCompletedSales}{" "}
								%
							</p>
							<i className={ventasCulminadas?.classCard}></i>
							<span className="text-respect" style={{ marginLeft: "10px" }}>
								Respecto al día anterior
							</span>
						</div>
					</div>

					<div className="progress-circle">
						<Progress
							percent={
								validationNumber(percentSignoCompletedSales)
									? percentSignoCompletedSalesFixed
									: percentSignoCompletedSales
							}
							strokeColor="#F2A900"
							type="circle"
							size={220}
						/>
					</div>

					<Divider style={{ margin: 15, minWidth: "96%", width: "90%" }} />

					<Link to="listadoDeVentas" className="show_more">
						Ir a listado de ventas
					</Link>
				</div>

				{dataState.rol === "vendedor" && (
					<div className="graph_1">
						<div className="tittle_cards">
							<p className="title-card">Planes más vendidos</p>
							<CustomTooltip
								subtitle={"Top ranking de los 10 planes más vendidos"}
								iconColor="hardBlue"
								position={"bottomLeft"}
								arrow={false}
							/>
						</div>

						<div className="midCardsRow" style={{ marginTop: "25px" }}>
							<span className="table_title flex">Nombre</span>
							<span className="table_title flex">Ventas culminadas</span>
						</div>

						<Divider style={{ margin: "19px 0" }} />

						<div className="plans_container custom_scroll">
							{mostSoldPlans.plan.map((plan, index) => (
								<div key={index}>
									<div className="midCardsRow">
										<div className="midCardsRow align-center flex">
											<img
												className="plan_image"
												src={urlBase.slice(0, -1) + plan.plan__image_miniature}
												alt={plan.plan__tradename}
											/>
											<span className="plan_table_name flex">
												{plan.plan__tradename}
											</span>
										</div>
										<div className="midCardsRow flex">
											<span className="plan_table_info flex">
												{plan.plan__count}
											</span>
											<span className="plan_table_info flex">
												{mostSoldPlans.porcentaje[index]}
											</span>
										</div>
									</div>

									<Divider style={{ margin: "16px 0" }} />
								</div>
							))}
						</div>
					</div>
				)}

				{(dataState.rol === "administrador_isp" ||
					dataState.rol === "supervisor_comercial") && (
					<div className="completed-sales">
						<div className="space-between description">
							<div className="midCardsRow align-center">
								<p className="title-card">Total de ventas</p>
								<CustomTooltip
									subtitle={
										"Total de ventas consolidadas de los últimos 6 meses."
									}
									iconColor="hardBlue"
									position={"bottomLeft"}
									arrow={false}
								/>
							</div>
						</div>

						<div className="completed-percent">
							<p className="number-sales">{totalVentas} ventas</p>
							<div className="midCardsRow align-center">
								<p className={totalSales?.textSales}>
									{validationNumber(percentSignoTotalSales)
										? percentSignoTotalSalesFixed
										: percentSignoTotalSales}{" "}
									%
								</p>
								<i className={totalSales?.classCard}></i>
								<span className="text-respect">Respecto al día anterior</span>
							</div>
						</div>
						<div style={{ height: "390px" }}>
							<ResponsiveContainer width="100%" height="100%">
								<AreaChart
									width={680}
									height={200}
									data={newDataGraphSales}
									margin={{
										top: 10,
										right: 0,
										left: 0,
										bottom: 0,
									}}
								>
									<XAxis dataKey="mes" />
									<YAxis />
									<Area
										type="monotone"
										dataKey="venta"
										stackId="1"
										stroke="#0058FF"
										fill="#29CB9733"
									/>
								</AreaChart>
							</ResponsiveContainer>
						</div>
					</div>
				)}
			</div>
		</Container>
	);
};

export default MidCardSupervisor;

const Container = styled.div`
	.space-between {
		justify-content: space-between;
	}

	.align-center {
		align-items: center;
	}

	.midCardsRow {
		display: flex;
	}

	.midCardsColumn {
		display: flex;
		flex-direction: column;
	}

	.flex {
		flex: 1;
	}

	.mid_section {
		width: 100%;
		min-height: 400px;
		display: flex;
		justify-content: space-between;
		margin: 1.3em 0;
	}

	.recents {
		width: 27%;
		min-height: 400px;
		background: white;
		border-radius: 3px;
		border: 1px solid #dde4eb;
		padding: 33px 25px;
	}

	.title-card {
		font-size: ${smallSizes.dashboardMid.titleCards};
		font-weight: 700;
		color: #5a607f;
	}

	.description {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.sales {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin: 0px 0px 10px 0;
	}

	.number-sales {
		font-size: ${smallSizes.dashboardMid.numberSales};
		color: #131523;
		font-weight: 700;
	}

	.bar-graph-modal {
		display: flex;
		flex-direction: column;
		padding: 6px 12px;
		background: white;
		box-shadow: 0px 1px 8px #53559b2d;
		border-radius: 4px;
	}

	.bar-graph-sales {
		font-size: 14px;
		color: #000000;
		font-weight: bold;
	}

	.bar-graph-text {
		font-size: 10px;
		color: #000000;
	}

	.percent_sales_up {
		font-family: roboto;
		font-weight: 700;
		color: #06d186;
		font-size: ${smallSizes.dashboardMid.percentSales};
	}

	.percent_sales_equal {
		font-family: roboto;
		font-weight: 700;
		color: #2b80ff;
		font-size: ${smallSizes.dashboardMid.percentSales};
	}

	.percent_sales_down {
		font-family: roboto;
		font-weight: 700;
		color: #f0142f;
		font-size: ${smallSizes.dashboardMid.percentSales};
	}

	.text-respect {
		font-size: ${smallSizes.dashboardMid.textRespect};
		color: #868e96;
	}

	.text-sales {
		font-size: ${smallSizes.dashboardMid.textSales};
		color: #5a607f;
		font-weight: 700;
	}

	.percent_up {
		color: #3dd598;
		font-size: ${smallSizes.dashboardMid.percent};
	}

	.arrow_icon_up {
		color: #3dd598;
		font-size: ${smallSizes.dashboardMid.arrowIcon};
		margin: 0 14px;
	}

	.percent_equal {
		color: #2b80ff;
		font-size: ${smallSizes.dashboardMid.percent};
	}

	.arrow_icon_equal {
		color: #2b80ff;
		font-size: ${smallSizes.dashboardMid.arrowIcon};
		margin: 0 10px;
	}

	.percent_down {
		color: #f0142f;
		font-size: ${smallSizes.dashboardMid.percent};
	}

	.arrow_icon_down {
		color: #f0142f;
		font-size: ${smallSizes.dashboardMid.arrowIcon};
		margin: 0 10px;
	}

	//--------------------------------------

	.graph_1 {
		width: 35%;
		min-height: 400px;
		background: white;
		border-radius: 3px;
		border: 1px solid #dde4eb;
		padding: 25px 20px 35px;
	}

	.tittle_cards {
		display: flex;
		align-items: center;
	}

	.progress-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 65px 0;
	}

	.plans_container {
		overflow: scroll;
		overflow-x: hidden;
		max-height: 380px;
	}

	.table_title {
		font-size: 12px;
		color: #7e84a3;
	}

	.plan_table_name {
		font-size: 12px;
		font-weight: bold;
		color: #426da9;
		margin-left: 10px;
	}

	.plan_table_info {
		font-size: 12px;
		color: #131523;
	}

	.plan_image {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: grey;
	}

	//--------------------------------------

	.completed-sales {
		width: 35%;
		min-height: 400px;
		background: white;
		padding: 2em;
		border-radius: 3px;
		border: 1px solid #dde4eb;
	}

	.completed-percent {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin: 0px 0px 10px 0;
	}

	.percent-average-up {
		margin-right: 2%;
		font-size: ${smallSizes.dashboardMid.percentAverage};
		color: #3dd598;
	}

	.percent-average-equal {
		margin-right: 2%;
		font-size: ${smallSizes.dashboardMid.percentAverage};
		color: #2b80ff;
	}

	.percent-average-down {
		margin-right: 2%;
		font-size: ${smallSizes.dashboardMid.percentAverage};
		color: #f0142f;
	}

	.show_more {
		color: #1458e8;
		font-size: ${smallSizes.dashboardBottom.showMore};
		width: 100%;
		cursor: pointer;
		margin: 0 0 30px 35px;
	}

	// ----------------------------------------

	@media screen and (min-width: ${devices.smallDesk}) {
		.title-card {
			font-size: ${smallSizes.dashboardMid.titleCards};
		}

		.number-sales {
			font-size: ${smallSizes.dashboardMid.numberSales};
		}

		.bar-graph-sales {
			font-size: 14px;
		}

		.bar-graph-text {
			font-size: 10px;
		}

		.percent_sales_up {
			font-size: ${smallSizes.dashboardMid.percentSales};
		}

		.percent_sales_equal {
			font-size: ${smallSizes.dashboardMid.percentSales};
		}

		.percent_sales_dow {
			font-size: ${smallSizes.dashboardMid.percentSales};
		}

		.text-respect {
			font-size: ${smallSizes.dashboardMid.textRespect};
		}

		.text-sales {
			font-size: ${smallSizes.dashboardMid.textSales};
		}

		.percent_up {
			font-size: ${smallSizes.dashboardMid.percent};
		}

		.arrow_icon_up {
			font-size: ${smallSizes.dashboardMid.arrowIcon};
		}

		.percent_equal {
			font-size: ${smallSizes.dashboardMid.percent};
		}

		.arrow_icon_equal {
			font-size: ${smallSizes.dashboardMid.arrowIcon};
		}

		.percent_down {
			font-size: ${smallSizes.dashboardMid.percent};
		}

		.arrow_icon_down {
			font-size: ${smallSizes.dashboardMid.arrowIcon};
		}

		.percent-average-up {
			font-size: ${smallSizes.dashboardMid.percentAverage};
		}

		.percent-average-equal {
			font-size: ${smallSizes.dashboardMid.percentAverage};
		}

		.percent-average-down {
			font-size: ${smallSizes.dashboardMid.percentAverage};
		}

		.show_more {
			font-size: ${smallSizes.dashboardBottom.showMore};
		}

		.table_title {
			font-size: 12px;
		}

		.plan_table_name {
			font-size: 12px;
		}

		.plan_table_info {
			font-size: 12px;
		}

		.plan_image {
			width: 20px;
			height: 20px;
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.title-card {
			font-size: ${mediumSizes.dashboardMid.titleCards};
		}

		.number-sales {
			font-size: ${mediumSizes.dashboardMid.numberSales};
		}

		.bar-graph-sales {
			font-size: 15.75px;
		}

		.bar-graph-text {
			font-size: 11.25px;
		}

		.percent_sales_up {
			font-size: ${mediumSizes.dashboardMid.percentSales};
		}

		.percent_sales_equal {
			font-size: ${mediumSizes.dashboardMid.percentSales};
		}

		.percent_sales_dow {
			font-size: ${mediumSizes.dashboardMid.percentSales};
		}

		.text-respect {
			font-size: ${mediumSizes.dashboardMid.textRespect};
		}

		.text-sales {
			font-size: ${mediumSizes.dashboardMid.textSales};
		}

		.percent_up {
			font-size: ${mediumSizes.dashboardMid.percent};
		}

		.arrow_icon_up {
			font-size: ${mediumSizes.dashboardMid.arrowIcon};
		}

		.percent_equal {
			font-size: ${mediumSizes.dashboardMid.percent};
		}

		.arrow_icon_equal {
			font-size: ${mediumSizes.dashboardMid.arrowIcon};
		}

		.percent_down {
			font-size: ${mediumSizes.dashboardMid.percent};
		}

		.arrow_icon_down {
			font-size: ${mediumSizes.dashboardMid.arrowIcon};
		}

		.percent-average-up {
			font-size: ${mediumSizes.dashboardMid.percentAverage};
		}

		.percent-average-equal {
			font-size: ${mediumSizes.dashboardMid.percentAverage};
		}

		.percent-average-down {
			font-size: ${mediumSizes.dashboardMid.percentAverage};
		}

		.show_more {
			font-size: ${mediumSizes.dashboardBottom.showMore};
		}

		.table_title {
			font-size: 13.5px;
		}

		.plan_table_name {
			font-size: 13.5px;
		}

		.plan_table_info {
			font-size: 13.5px;
		}

		.plan_image {
			width: 22.5px;
			height: 22.5px;
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.title-card {
			font-size: ${largeSizes.dashboardMid.titleCards};
		}

		.number-sales {
			font-size: ${largeSizes.dashboardMid.numberSales};
		}

		.bar-graph-sales {
			font-size: 17.71px;
		}

		.bar-graph-text {
			font-size: 12.65px;
		}

		.percent_sales_up {
			font-size: ${largeSizes.dashboardMid.percentSales};
		}

		.percent_sales_equal {
			font-size: ${largeSizes.dashboardMid.percentSales};
		}

		.percent_sales_dow {
			font-size: ${largeSizes.dashboardMid.percentSales};
		}

		.text-respect {
			font-size: ${largeSizes.dashboardMid.textRespect};
		}

		.text-sales {
			font-size: ${largeSizes.dashboardMid.textSales};
		}

		.percent_up {
			font-size: ${largeSizes.dashboardMid.percent};
		}

		.arrow_icon_up {
			font-size: ${largeSizes.dashboardMid.arrowIcon};
		}

		.percent_equal {
			font-size: ${largeSizes.dashboardMid.percent};
		}

		.arrow_icon_equal {
			font-size: ${largeSizes.dashboardMid.arrowIcon};
		}

		.percent_down {
			font-size: ${largeSizes.dashboardMid.percent};
		}

		.arrow_icon_down {
			font-size: ${largeSizes.dashboardMid.arrowIcon};
		}

		.percent-average-up {
			font-size: ${largeSizes.dashboardMid.percentAverage};
		}

		.percent-average-equal {
			font-size: ${largeSizes.dashboardMid.percentAverage};
		}

		.percent-average-down {
			font-size: ${largeSizes.dashboardMid.percentAverage};
		}

		.show_more {
			font-size: ${largeSizes.dashboardBottom.showMore};
		}

		.table_title {
			font-size: 15.18px;
		}

		.plan_table_name {
			font-size: 15.18px;
		}

		.plan_table_info {
			font-size: 15.18px;
		}

		.plan_image {
			width: 25.31px;
			height: 25.31px;
		}
	}
`;
