import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import TableData from "../../components/table/TableData";
import { myFetchGet, myFetch, myExportData } from "../../services/services";
import dayjs from "dayjs";
import CustomModal from "../../components/modal/CustomModal";
import FormOperator from "./components/FormOperator";
import { Button, Select, DatePicker, notification } from "antd";
import { useHistory } from "react-router-dom";
import { Loading } from "../../components/loading/Loading";
import { useReduxToken } from "../../hooks/useReduxToken";
import { useSelector } from "react-redux";
import ColorCircle from "../../components/colorCircle/ColorCircle";
import TextColored from "../../components/textColored/TextColored";
import TableText from "../../components/tableText/TableText";
import TableLink from "../../components/tableLink/TableLink";
import CustomTooltip from "../../components/customTooltip/CustomTooltip";
import fileDownload from "js-file-download";
import styles from "./Operators.module.css";
import TablePagination from "../../components/tablePagination/TablePagination";

const Operators = () => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);

	const addOperator = dataState.permissions.includes("add_operator");
	const viewOperator = dataState.permissions.includes(
		"datatable_view_operator"
	);

	const [loadingTable, setLoadingTable] = useState(true);
	const [tableColumns, setTableColumns] = useState([]);
	const [totalTableSize, setTotalTableSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 });
	const [isVisibleForm, setIsVisibleForm] = useState(false);

	const [verifiedFilter, setVerifiedFilter] = useState();
	const [startDateFilter, setStartDateFilter] = useState();
	const [endDateFilter, setEndDateFilter] = useState();
	const [sorterOrder, setSorterOrder] = useState("desc");
	const [columnFilterValue, setColumnFilterValue] = useState([]);
	const [exportFilters, setExportFilters] = useState({});

	const { RangePicker } = DatePicker;

	const initial = async () => {
		const isActive = `["is_active","equal","` + verifiedFilter + `"]`;
		const period =
			`["created","gt","` +
			startDateFilter +
			` 00:00"], ["created","lte","` +
			endDateFilter +
			` 23:59"]`;
		const head = `[`;
		const tail = `]`;
		let filters = {};
		if (
			verifiedFilter !== undefined &&
			startDateFilter === undefined &&
			endDateFilter === undefined
		) {
			filters = {
				filters: head + isActive + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			startDateFilter !== undefined &&
			endDateFilter !== undefined &&
			verifiedFilter === undefined
		) {
			filters = {
				filters: head + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			verifiedFilter !== undefined &&
			startDateFilter !== undefined &&
			endDateFilter !== undefined
		) {
			filters = {
				filters: head + isActive + `, ` + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else {
			filters = {
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		}

		let newFilters = {
			...filters,
			order_field: "ID",
			order_type: sorterOrder,
		};

		if (columnFilterValue.length === 1 && !newFilters.filters) {
			newFilters = {
				...newFilters,
				filters: `[["status","equal","` + columnFilterValue[0] + `"]]`,
			};
		} else if (
			(columnFilterValue.length === 0 && !newFilters.filters) ||
			(columnFilterValue.length === 0 && newFilters.filters)
		) {
			newFilters = newFilters;
		} else if (columnFilterValue.length === 1 && newFilters.filters) {
			newFilters.filters = newFilters.filters.replace(
				"]",
				`], ` + `["status","equal","` + columnFilterValue[0] + `"]`
			);
		}

		setExportFilters(newFilters);

		try {
			const response = await myFetch(
				"api/v1/operador/datatables/",
				token,
				newFilters
			);
			if (response) {
				if (response.size) {
					setTotalTableSize(response.size);
				}
				if (response.data) {
					const rows = response.data.map((row) => {
						const createdFormat = dayjs(row.created).format("DD/MM/YYYY");
						let isOperatorActive = "";
						let operatorStatus = "";
						if (row?.is_active == true) {
							isOperatorActive = "Verificado";
						} else if (row?.is_active == false) {
							isOperatorActive = "Sin verificar";
						}

						if (row?.status == 1) {
							operatorStatus = "Activo";
						} else if (row?.status == 2) {
							operatorStatus = "Inactivo";
						}
						return {
							...row,
							key: row.ID,
							created: createdFormat,
							is_active: isOperatorActive,
							web_site: row.web_site || "Sitio web no suministrado",
							status: operatorStatus,
						};
					});
					setTableRows(rows);
				}
			}
			setLoadingTable(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const history = useHistory();

	const rowSelection = (item) => {
		history.push("/detalleOperador", { params: item });
	};

	const getColumns = async () => {
		try {
			const data = await myFetchGet("api/v1/operador/datatables_struct", token);
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					onCell:
						columnsTittle === "ID"
							? (record, rowIndex) => {
									return {
										onClick: (event) => {
											rowSelection(record);
										},
										style: { cursor: "pointer" },
										className: "hover-class",
									};
							  }
							: null,
					sorter: columnsTittle === "ID" ? true : null,
					filters:
						columnsTittle === "Estatus"
							? [
									{ text: "Activo", value: 1 },
									{ text: "Inactivo", value: 2 },
							  ]
							: null,
					filterMultiple: false,
					title: columnsTittle,
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					render:
						columnsTittle === "ID"
							? (value) => <TextColored value={value} />
							: columnsTittle === "Página Web"
							? (value) => <TableLink value={value} />
							: columnsTittle === "País"
							? (value) => (
									<img
										src={`https://flagcdn.com/28x21/${value}.png`}
										alt={value}
									/>
							  )
							: columnsTittle === "Verificado"
							? (value) => (
									<span
										style={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "space-evenly",
										}}
									>
										{value === "Verificado" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													width: "100%",
												}}
											>
												<ColorCircle color={"#26A805"} />
												<TableText value={value} />
											</div>
										) : (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													width: "100%",
												}}
											>
												<ColorCircle color={"#F53C56"} />
												<TableText value={value} />
											</div>
										)}
									</span>
							  )
							: (value) => <TableText value={value} />,
				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	};

	const tableChangeHandler = (pagination, filter, sorter, extra) => {
		if (sorter.order === "ascend") {
			setSorterOrder("asc");
		} else if (sorter.order === "descend" || sorter.order === undefined) {
			setSorterOrder("desc");
		}
		if (filter.status) {
			setColumnFilterValue(filter.status);
		} else {
			setColumnFilterValue([]);
		}
	};

	useEffect(() => {
		initial();
		getColumns();
	}, [
		verifiedFilter,
		startDateFilter,
		endDateFilter,
		rowsParams,
		sorterOrder,
		columnFilterValue,
	]);

	useEffect(() => {
		if (viewOperator === false) {
			history.replace("/inicio");
		}
	}, []);

	const verifiedFilterHanlder = (value) => {
		setVerifiedFilter(value);
	};

	const dateFormat = "DD/MM/YYYY";

	const dateSelectHandler = (dates, dateStrings) => {
		if (dateStrings[0] == "" && dateStrings[1] == "") {
			const clearedDate = undefined;
			setStartDateFilter(clearedDate);
			setEndDateFilter(clearedDate);
		} else {
			setStartDateFilter(dateStrings[0]);
			setEndDateFilter(dateStrings[1]);
		}
	};

	const disabledDate = (current) => {
		return current > dayjs().endOf("day");
	};

	const onCloseModal = () => {
		setIsVisibleForm(false);
	};

	const onExportData = async () => {
		try {
			const file = await myExportData(
				"api/v1/operador/datatables_download/",
				token,
				exportFilters
			);
			fileDownload(file, "Listado de Operadores.xlsx");
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<div className="col-md-12">
			<Panel>
				<PanelHeader>
					<div className={styles["header-container"]}>
						<div className={`${styles["row"]} ${styles["align-center"]}`}>
							<span className={styles["header-title"]}>
								Listado de operadores
							</span>
							<CustomTooltip
								subtitle={"Listado de operadores registrados en el sistema"}
							/>
							<span className={styles["header-breadcrumb"]}>
								Operadores | Listado de Operadores
							</span>
						</div>

						{addOperator && (
							<Button
								className={styles["add-operator-button"]}
								onClick={setIsVisibleForm}
								icon={
									<i
										className={`fa-solid fa-plus ${styles["add-operator-icon"]}`}
									></i>
								}
							>
								Crear operador
							</Button>
						)}
					</div>
				</PanelHeader>
				<PanelBody>
					<FilterContainer>
						<span className={styles["filters-title"]}>
							<b>Opciones de filtrado</b>
						</span>
						<div className={styles["row"]}>
							<div className={styles["column"]}>
								<Select
									className={styles["select"]}
									placeholder="Verificación"
									onChange={verifiedFilterHanlder}
									allowClear={true}
									onClear={verifiedFilterHanlder}
								>
									<Select.Option value={true}>Verificado</Select.Option>
									<Select.Option value={false}>Sin verificar</Select.Option>
								</Select>
							</div>
							<div className={styles["column"]}>
								<RangePicker
									className={styles["date-picker"]}
									placeholder={["Fecha Inicio", "Fecha Fin"]}
									onChange={dateSelectHandler}
									format={dateFormat}
									disabledDate={disabledDate}
								/>
							</div>
						</div>
					</FilterContainer>
					{isVisibleForm && (
						<CustomModal onClick={onCloseModal}>
							<FormOperator
								visible={isVisibleForm}
								setVisible={setIsVisibleForm}
								initial={initial}
							/>
						</CustomModal>
					)}

					{loadingTable ? (
						<Loading />
					) : (
						<>
							<TableData
								rows={tableRows}
								columns={tableColumns}
								onChange={tableChangeHandler}
							/>
							<div
								className={`${styles["row"]} ${styles["pagination-container"]}`}
							>
								<TablePagination
									paginationSettings={paginationSettings}
									totalTableSize={totalTableSize}
									onChange={modifyPagination}
									showTotal={(total, range) => (
										<div className={styles["column"]}>
											<span className={styles["pagination"]}>
												Mostrando del {range[0]} al {range[1]} de un total de{" "}
												{total} registros
											</span>
											<span
												className={styles["export-button"]}
												onClick={onExportData}
											>
												<i
													className={`fa-solid fa-file-excel ${styles["export-button-icon"]}`}
												></i>
												Exportar data
											</span>
										</div>
									)}
								/>
							</div>
						</>
					)}
				</PanelBody>
			</Panel>
		</div>
	);
};

export default Operators;

const FilterContainer = styled.div`
	display: flex;
	flex-direction: column;

	.ant-select-selector {
		border-radius: 5px !important;
		border: 1px solid #e0e0e0 !important;
		display: flex;
		align-items: center;
		height: 38px !important;
		font-size: 12px;
	}

	.ant-select-clear {
		display: flex;
		align-items: center;
	}

	.ant-picker .ant-picker-input > input {
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 15.18px;
		}
	}
`;
