import React, { useState, useEffect } from "react";
import { Panel, PanelSubHeader } from "../../../components/panel/panel";
import TableData from "../../../components/table/TableData";
import { myFetchGet, myFetch, myExportData } from "../../../services/services";
import styled from "styled-components";
import { DatePicker, notification } from "antd";
import { Loading } from "../../../components/loading/Loading";
import { useReduxToken } from "../../../hooks/useReduxToken";
import TableText from "../../../components/tableText/TableText";
import fileDownload from "js-file-download";
import styles from "./Sellers.module.css";
import TablePagination from "../../../components/tablePagination/TablePagination";

const Sellers = ({ data }) => {
	const token = useReduxToken();

	const [loadingTable, setLoadingTable] = useState(true);
	const [totalTableSize, setTotalTableSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);
	const [tableColumns, setTableColumns] = useState([]);
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 });

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [sorterOrder, setSorterOrder] = useState("desc");
	const [columnFilterValue, setColumnFilterValue] = useState([]);
	const [exportFilters, setExportFilters] = useState({});

	const { RangePicker } = DatePicker;

	const { ID } = data;

	const clearData = undefined;

	const initial = async () => {
		const operator = `["operator", "equal", "` + ID + `"]`;
		const period =
			`["created","gt","` +
			startDate +
			` 00:00"], ["created","lte","` +
			endDate +
			` 23:59"]`;
		const head = `[`;
		const tail = `]`;
		let filters = {};

		if (startDate !== undefined && endDate !== undefined) {
			filters = {
				filters: head + operator + `, ` + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else {
			filters = {
				filters: head + operator + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		}

		let newFilters = {
			...filters,
			order_field: "ID",
			order_type: sorterOrder,
		};

		if (columnFilterValue.length === 1 && !newFilters.filters) {
			newFilters = {
				...newFilters,
				filters: `[["last_sale","equal","` + columnFilterValue[0] + `"]]`,
			};
		} else if (
			(columnFilterValue.length === 0 && !newFilters.filters) ||
			(columnFilterValue.length === 0 && newFilters.filters)
		) {
			newFilters = newFilters;
		} else if (columnFilterValue.length === 1 && newFilters.filters) {
			newFilters.filters = newFilters.filters.replace(
				"]",
				`], ` + `["last_sale","equal","` + columnFilterValue[0] + `"]`
			);
		}

		setExportFilters(newFilters);

		try {
			const response = await myFetch(
				`api/v1/seller/datatables/`,
				token,
				newFilters
			);
			if (response) {
				if (response.size) {
					setTotalTableSize(response.size);
				}
				if (response.data) {
					const rows = response.data.map((row) => {
						return {
							...row,
							key: row.ID,
						};
					});
					setTableRows(rows);
				}
			}
			setLoadingTable(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const getColumns = async () => {
		try {
			const data = await myFetchGet("api/v1/seller/datatables_struct", token);
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					sorter: columnsTittle === "ID" ? true : null,
					filters:
						columnsTittle === "Última venta"
							? [
									{ text: "Últimos 7 días", value: 7 },
									{ text: "Últimos 15 días", value: 15 },
									{ text: "Últimos 30 días", value: 30 },
									{ text: "Últimos 60 días", value: 60 },
							  ]
							: null,
					filterMultiple: false,
					title: columnsTittle,
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					render: (value) => <TableText value={value} />,
				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	};

	const tableChangeHandler = (pagination, filter, sorter, extra) => {
		if (sorter.order === "ascend") {
			setSorterOrder("asc");
		} else if (sorter.order === "descend" || sorter.order === undefined) {
			setSorterOrder("desc");
		}
		if (filter.last_sale) {
			setColumnFilterValue(filter.last_purchase);
		} else {
			setColumnFilterValue([]);
		}
	};

	useEffect(() => {
		initial();
		getColumns();
	}, [startDate, endDate, rowsParams, sorterOrder, columnFilterValue]);

	const dateFormat = "DD/MM/YYYY";

	const selectDateHandler = (dates, dateStrings) => {
		if (dateStrings[0] === "" && dateStrings[1] === "") {
			setStartDate(clearData);
			setEndDate(clearData);
		} else {
			setStartDate(dateStrings[0]);
			setEndDate(dateStrings[1]);
		}
	};

	const onExportData = async () => {
		try {
			const file = await myExportData(
				"api/v1/seller/datatables_download/",
				token,
				exportFilters
			);
			fileDownload(file, "Listado de Vendedores.xlsx");
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<div className="col-md-12">
			<Panel>
				<PanelSubHeader>
					<SubHeaderContainer>
						<span className={styles["subheader-title"]}>
							Relación de vendedores registrados del operador.
						</span>
						<RangePicker
							className={styles["date-picker"]}
							placeholder={["Fecha Inicio", "Fecha Fin"]}
							onChange={selectDateHandler}
							placement="bottomRight"
							format={dateFormat}
						/>
					</SubHeaderContainer>
				</PanelSubHeader>

				{loadingTable ? (
					<Loading />
				) : (
					<>
						<TableData
							rows={tableRows}
							columns={tableColumns}
							onChange={tableChangeHandler}
						/>

						<div
							className={`${styles["row"]} ${styles["pagination-container"]}`}
						>
							<TablePagination
								paginationSettings={paginationSettings}
								totalTableSize={totalTableSize}
								onChange={modifyPagination}
								showTotal={(total, range) => (
									<div className={styles["column"]}>
										<span className={styles["pagination"]}>
											Mostrando del {range[0]} al {range[1]} de un total de{" "}
											{total} registros
										</span>
										<span
											className={styles["export-button"]}
											onClick={onExportData}
										>
											<i
												className={`fa-solid fa-file-excel ${styles["export-button-icon"]}`}
											></i>
											Exportar data
										</span>
									</div>
								)}
							/>
						</div>
					</>
				)}
			</Panel>
		</div>
	);
};

export default Sellers;

const SubHeaderContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	.ant-picker .ant-picker-input > input {
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-picker .ant-picker-input > input {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-picker .ant-picker-input > input {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-picker .ant-picker-input > input {
			font-size: 15.18px;
		}
	}
`;
