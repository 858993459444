import React from "react";
import styled from "styled-components";
import { largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import devices from "../../helpers/sizes";
import { useClassDashboard } from "../../hooks/useClassDashboard";
import { validationNumber } from "../../helpers/validationNumber";

export default function TopCards({
	card1Title,
	card1Total,
	card1Percent,
	card2Title,
	card2Total,
	card2Percent,
	card3Title,
	card3Total,
	card3Percent,
	card4Operator,
	card4Plan,
	card4Percent,
	img1,
	img2,
	img3,
}) {
	const percentSignoCard1 = Math.abs(card1Percent);
	const percentSignoCard2 = Math.abs(card2Percent);
	const percentSignoCard3 = Math.abs(card3Percent);
	const percentSignoCard4 = Math.abs(card4Percent);

	const card1 = useClassDashboard(card1Percent);
	const card2 = useClassDashboard(card2Percent);
	const card3 = useClassDashboard(card3Percent);
	const card4 = useClassDashboard(card4Percent);

	const percentSignoCard1Fixed = percentSignoCard1.toFixed(2);
	const percentSignoCard2Fixed = percentSignoCard2.toFixed(2);
	const percentSignoCard3Fixed = percentSignoCard3.toFixed(2);
	const percentSignoCard4Fixed = percentSignoCard4.toFixed(2);

	return (
		<Container>
			<div className="cards_container">
				<div className="card">
					<div className="row space-between responsived">
						<div className="text">
							<p>Total de nuevos</p>
							<p style={{ fontWeight: "700" }}>{card1Title}</p>
							<h1 className="number">{card1Total}</h1>
						</div>
						<div className="circleIcon">
							<img className="icon_size" src={img1} alt="" />
						</div>
					</div>
					<div className="row center">
						<p className={card1?.textCard}>
							{validationNumber(percentSignoCard1)
								? percentSignoCard1Fixed
								: percentSignoCard1}{" "}
							%
						</p>
						<i className={card1?.classCard}></i>
						<p className="text-respect">Respecto al día anterior</p>
					</div>
				</div>
				{/* --------- */}

				<div className="card">
					<div className="row space-between responsived">
						<div className="text">
							<p>Total de nuevos</p>
							<p style={{ fontWeight: "700" }}>{card2Title}</p>
							<h1 className="number">{card2Total}</h1>
						</div>
						<div className="circleIcon">
							<img className="icon_size" src={img2} />
						</div>
					</div>
					<div className="row center">
						<p className={card2?.textCard}>
							{validationNumber(percentSignoCard2)
								? percentSignoCard2Fixed
								: percentSignoCard2}{" "}
							%
						</p>
						<i className={card2?.classCard}></i>
						<p className="text-respect">Respecto al día anterior</p>
					</div>
				</div>

				{/* --------- */}

				<div className="card">
					<div className="row space-between responsived">
						<div className="text">
							<p>Total de nuevos</p>
							<p style={{ fontWeight: "700" }}>{card3Title}</p>
							<h1 className="number">{card3Total}</h1>
						</div>
						<div className="circleIcon">
							<img className="icon_size" src={img3} />
						</div>
					</div>
					<div className="row center">
						<p className={card3?.textCard}>
							{validationNumber(percentSignoCard3)
								? percentSignoCard3Fixed
								: percentSignoCard3}{" "}
							%
						</p>
						<i className={card3?.classCard}></i>
						<p className="text-respect">Respecto al día anterior</p>
					</div>
				</div>

				{/* --------- */}

				<div className="card">
					<div className="row space-between responsived">
						<div className="text">
							<p>Plan más</p>
							<p style={{ fontWeight: "700" }}>Vendido</p>
						</div>
						<div className="operator">
							<span>{card4Operator}</span>
						</div>
						<h1 className="plan">{card4Plan}</h1>
					</div>
					<div className="row center">
						<p className={card4?.textCard}>
							{validationNumber(percentSignoCard4)
								? percentSignoCard4Fixed
								: percentSignoCard4}{" "}
							%
						</p>
						<i className={card4?.classCard}></i>
						<p style={{ fontSize: 12, color: "#7E84A3" }}>
							Respecto al día anterior
						</p>
					</div>
				</div>
			</div>
		</Container>
	);
}

const Container = styled.div`
	.row {
		display: flex;
		margin: 0;
		padding: 0;
	}

	.space-between {
		justify-content: space-between;
	}

	.cards_container {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1.3em;
		align-self: center;
		margin-top: 2em;
	}

	.card {
		width: 100%;
		border-radius: 3px;
		border: 1px solid #dde4eb;
		padding: 2em;
		display: flex;
		justify-content: center;
		flex-flow: column;
	}

	.text p {
		color: #5a607f;
		font-size: ${smallSizes.dashboardTop.text};
		font-weight: 500;
	}

	.number {
		color: #131523;
		font-size: ${smallSizes.dashboardTop.number};
		font-weight: 700;
		margin: 0.4em 0 !important;
	}

	.circleIcon {
		width: ${smallSizes.dashboardTop.circleWidth};
		height: ${smallSizes.dashboardTop.circleHeight};
		background: #f2a9001a;
		border: 1px solid #f2a9001a;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.icon_size {
		width: ${smallSizes.dashboardTop.iconSizeWidth};
		height: ${smallSizes.dashboardTop.iconSizeHeight};
	}

	.percent_up {
		color: #3dd598;
		font-size: ${smallSizes.dashboardTop.percent};
		font-weight: 400;
	}

	.arrow_icon_up {
		color: #3dd598;
		font-size: ${smallSizes.dashboardTop.arrowIcon};
		margin: 0 14px;
	}

	.text-respect {
		font-size: ${smallSizes.dashboardTop.textRespect};
		color: #7e84a3;
	}

	.percent_down {
		color: #f0142f;
		font-size: ${smallSizes.dashboardTop.percent};
	}

	.arrow_icon_down {
		color: #f0142f;
		font-size: ${smallSizes.dashboardTop.arrowIcon};
		margin: 0 14px;
	}

	.arrow_icon_equal {
		color: #2b80ff;
		font-size: ${smallSizes.dashboardTop.arrowIcon};
		margin: 0 14px;
	}

	.percent_equal {
		color: #2b80ff;
		font-size: ${smallSizes.dashboardTop.percent};
	}

	.operator {
		width: ${smallSizes.dashboardTop.operatorWidth};
		height: ${smallSizes.dashboardTop.operatorHeight};
		background: #f2a900;
		border: 1px solid #f2a900;
		border-radius: 6%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.operator span {
		color: white;
		font-weight: 700;
		font-size: ${smallSizes.dashboardTop.textOperator};
	}

	.plan {
		color: #131523;
		font-size: ${smallSizes.dashboardTop.textPlan};
		font-weight: 700;
		margin: 0.4em 0 !important;
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		.text p {
			font-size: ${smallSizes.dashboardTop.text};
		}

		.number {
			font-size: ${smallSizes.dashboardTop.number};
		}

		.circleIcon {
			width: ${smallSizes.dashboardTop.circleWidth};
			height: ${smallSizes.dashboardTop.circleHeight};
		}

		.icon_size {
			width: ${smallSizes.dashboardTop.iconSizeWidth};
			height: ${smallSizes.dashboardTop.iconSizeHeight};
		}

		.percent_up {
			font-size: ${smallSizes.dashboardTop.percent};
		}

		.arrow_icon {
			font-size: ${smallSizes.dashboardTop.arrowIcon};
		}

		.text-respect {
			font-size: ${smallSizes.dashboardTop.textRespect};
		}

		.percent_down {
			font-size: ${smallSizes.dashboardTop.percent};
		}

		.percent_equal {
			font-size: ${smallSizes.dashboardTop.percent};
		}

		.arrow_icon_down {
			font-size: ${smallSizes.dashboardTop.arrowIcon};
		}

		.arrow_icon_equal {
			font-size: ${smallSizes.dashboardTop.arrowIcon};
		}

		.operator {
			width: ${smallSizes.dashboardTop.operatorWidth};
			height: ${smallSizes.dashboardTop.operatorHeight};
		}

		.operator span {
			font-size: ${smallSizes.dashboardTop.textOperator};
		}

		.plan {
			font-size: ${smallSizes.dashboardTop.textPlan};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.text p {
			font-size: ${mediumSizes.dashboardTop.text};
		}

		.number {
			font-size: ${mediumSizes.dashboardTop.number};
		}

		.circleIcon {
			width: ${mediumSizes.dashboardTop.circleWidth};
			height: ${mediumSizes.dashboardTop.circleHeight};
		}

		.icon_size {
			width: ${mediumSizes.dashboardTop.iconSizeWidth};
			height: ${mediumSizes.dashboardTop.iconSizeHeight};
		}

		.percent_up {
			font-size: ${mediumSizes.dashboardTop.percent};
		}

		.arrow_icon {
			font-size: ${mediumSizes.dashboardTop.arrowIcon};
		}

		.text-respect {
			font-size: ${mediumSizes.dashboardTop.textRespect};
		}

		.percent_down {
			font-size: ${mediumSizes.dashboardTop.percent};
		}

		.percent_equal {
			font-size: ${mediumSizes.dashboardTop.percent};
		}

		.arrow_icon_down {
			font-size: ${mediumSizes.dashboardTop.arrowIcon};
		}

		.arrow_icon_equal {
			font-size: ${mediumSizes.dashboardTop.arrowIcon};
		}

		.operator {
			width: ${mediumSizes.dashboardTop.operatorWidth};
			height: ${mediumSizes.dashboardTop.operatorHeight};
		}

		.operator span {
			font-size: ${mediumSizes.dashboardTop.textOperator};
		}

		.plan {
			font-size: ${mediumSizes.dashboardTop.textPlan};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.text p {
			font-size: ${largeSizes.dashboardTop.text};
		}

		.number {
			font-size: ${largeSizes.dashboardTop.number};
		}

		.circleIcon {
			width: ${largeSizes.dashboardTop.circleWidth};
			height: ${largeSizes.dashboardTop.circleHeight};
		}

		.icon_size {
			width: ${largeSizes.dashboardTop.iconSizeWidth};
			height: ${largeSizes.dashboardTop.iconSizeHeight};
		}

		.percent_up {
			font-size: ${largeSizes.dashboardTop.percent};
		}

		.arrow_icon {
			font-size: ${largeSizes.dashboardTop.arrowIcon};
		}

		.text-respect {
			font-size: ${largeSizes.dashboardTop.textRespect};
		}

		.percent_down {
			font-size: ${largeSizes.dashboardTop.percent};
		}

		.percent_equal {
			font-size: ${largeSizes.dashboardTop.percent};
		}

		.arrow_icon_down {
			font-size: ${largeSizes.dashboardTop.arrowIcon};
		}

		.arrow_icon_equal {
			font-size: ${largeSizes.dashboardTop.arrowIcon};
		}

		.operator {
			width: ${largeSizes.dashboardTop.operatorWidth};
			height: ${largeSizes.dashboardTop.operatorHeight};
		}

		.operator span {
			font-size: ${largeSizes.dashboardTop.textOperator};
		}

		.plan {
			font-size: ${largeSizes.dashboardTop.textPlan};
		}
	}
`;
