import React from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import routes from "./../../config/page-route.jsx";
import ProtectedRoute from "./../../middleweare/ProtectedRoute";
import Config from "./../../config";
import styled from "styled-components";

function setTitle(path, routeArray) {
	var pageTitle;
	for (var i = 0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = `${Config.TITLE} | ${routeArray[i].title}`;
		}
	}
	document.title = pageTitle ? pageTitle : Config.TITLE;
}

class Content extends React.Component {
	componentDidMount() {
		setTitle(this.props.history.location.pathname, routes);
	}
	UNSAFE_componentWillMount() {
		this.props.history.listen(() => {
			setTitle(this.props.history.location.pathname, routes);
		});
	}

	render() {
		return (
			<ContentContainer className="custom_scroll">
				<Switch>
					{routes.map((route, index) =>
						route.private ? (
							<ProtectedRoute
								key={index}
								path={route.path}
								exact={route.exact}
								component={route.component}
							/>
						) : (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								component={route.component}
							/>
						)
					)}
					{/* Default Route */}
					<Route render={() => <Redirect to="/inicio" />} />
				</Switch>
			</ContentContainer>
		);
	}
}

export default withRouter(Content);

const ContentContainer = styled.div`
	width: 100%;
	background: #f3f3f3 !important;
	flex: 1;

	display: flex;
	flex-flow: column;
	padding: 30px 5px 0 15px;
	box-sizing: border-box !important;
	overflow-y: scroll;
	overflow-x: hidden;
`;
