import { Tabs } from "react-tabs";
import styled from "styled-components";
import { largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import devices from "../../helpers/sizes";

export const TabsPanel = styled(Tabs)`
	background-color: white;
	box-shadow: -12px -10px 5px -12px grey;

	.TabsList {
		width: 100%;
		display: flex;
		flex: 1;
		background: white;
		padding: 0 !important;

		@media (max-width: 800px) {
			width: 100%;
		}
	}

	.Tab {
		list-style: none;
		padding: 10px 15px;
		font-size: ${smallSizes.tabsPanel.tab};
		color: #007aff;
		cursor: pointer;
		font-weight: 400;

		&.is-selected {
			color: #000;
			background: #dde4eb;
			font-weight: 600;
			border: none;
			outline: none;
			border-radius: 5px;
		}
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		.Tab {
			font-size: ${smallSizes.tabsPanel.tab};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.Tab {
			font-size: ${mediumSizes.tabsPanel.tab};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.Tab {
			font-size: ${largeSizes.tabsPanel.tab};
		}
	}
`;
