import React, { useState, useEffect } from "react";
import { myFetchGet } from "../../../services/services";
import { Button, Checkbox, Divider, Timeline } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import { LoadingHistory } from "../../../components/loading/Loading";
import { useReduxToken } from "../../../hooks/useReduxToken";
import styles from "./HistoryPanel.module.css";
import Backdrop from "../../../components/backdrop/Backdrop";

const HistoryPanel = ({ data }) => {
	const token = useReduxToken();

	const { id_unique } = data;

	const [changes, setChanges] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showFilters, setShowFilters] = useState(false);
	const [checkedFilters, setCheckedFilters] = useState([]);
	const [filterTag, setFilterTag] = useState("cambios");
	const [sessionHistory, setSessionHistory] = useState([]);

	const showFiltersHandler = () => {
		setShowFilters(!showFilters);
	};

	const handleCheckedConfig = (list) => {
		if (list[list.length - 1] === 1) {
			setFilterTag("sesión");
		} else {
			setFilterTag("cambios");
		}

		setCheckedFilters([list[list.length - 1]]);
	};

	const clearFiltersHandler = () => {
		setCheckedFilters([]);
		setFilterTag("cambios");
	};

	const filtersHandler = () => {
		setShowFilters(!showFilters);
	};

	useEffect(() => {
		const initial = async () => {
			setLoading(true);
			try {
				if (checkedFilters[0] === 2 || checkedFilters.length === 0) {
					const response = await myFetchGet(
						`api/v1/userapp/${id_unique}/history/`,
						token
					);
					setChanges(response?.changes.map((e) => e[0]));
					setLoading(false);
				} else {
					const response = await myFetchGet(
						`api/v1/history-session/${id_unique}`,
						token
					);
					setSessionHistory(response);
					setLoading(false);
				}
			} catch (e) {
				console.log("error", e);
			}
		};

		initial();
	}, [checkedFilters, id_unique]);

	return (
		<Container>
			<div className={styles["row"]}>
				<div
					className={`${styles["column"]} ${styles["pointer"]}`}
					onClick={showFiltersHandler}
				>
					<div className={`${styles["row"]} ${styles["align-center"]}`}>
						<span className={styles["history-filters-title"]}>
							Registro de {filterTag}
						</span>
						<i
							className={`fa-solid fa-angle-down ${styles["history-filters-icon"]}`}
						></i>
					</div>
				</div>
			</div>
			{showFilters && (
				<>
					<div className={styles["history-filters-box"]}>
						<div className={`${styles["column"]} ${styles["flex"]}`}>
							<span className={styles["filters-box-title"]}>
								Opciones de filtrado
							</span>
							<Checkbox.Group
								className={styles["column"]}
								onChange={handleCheckedConfig}
								value={checkedFilters}
							>
								<div className={styles["row"]}>
									<Checkbox value={1} key={1}>
										<span className={styles["filters-box-checkbox"]}>
											Registro de sesión
										</span>
									</Checkbox>
								</div>
								<div className={styles["row"]}>
									<Checkbox value={2} key={2}>
										<span className={styles["filters-box-checkbox"]}>
											Registro de cambios
										</span>
									</Checkbox>
								</div>
							</Checkbox.Group>
							<Divider style={{ margin: "10px 0" }} />
							<div className={`${styles["row"]} ${styles["space-between"]}`}>
								<Button
									type="link"
									className={styles["clear-filters-button"]}
									onClick={clearFiltersHandler}
								>
									Limpiar
								</Button>
								<Button
									className={styles["confirm-filters-button"]}
									onClick={filtersHandler}
								>
									Ok
								</Button>
							</div>
						</div>
					</div>

					<Backdrop onClose={filtersHandler} />
				</>
			)}

			<br />

			<div className={`${styles["row"]} ${styles["space-between"]}`}>
				{filterTag === "cambios" ? (
					<span className={styles["history-paragraph"]}>
						Histórico relacionado a los cambios realizados en la información del
						usuario.
					</span>
				) : (
					filterTag === "sesión" && (
						<span className={styles["history-paragraph"]}>
							Histórico relacionado a la actividad asociada al inicio de sesión
							del perfil de usuario.
						</span>
					)
				)}
			</div>

			<br />
			<br />

			{loading ? (
				<LoadingHistory />
			) : (
				<>
					{filterTag === "cambios" ? (
						<Timeline mode="left" style={{ fontFamily: "Lato" }}>
							{changes.map((e) => (
								<Timeline.Item
									label={dayjs(e?.date, "DD-MM-YYYY HH:mm").format(
										"DD/MM/YYYY h:mm a"
									)}
									key={e?.ID}
									color={"#1D428A"}
								>
									{e?.field}
								</Timeline.Item>
							))}
						</Timeline>
					) : (
						filterTag === "sesión" && (
							<Timeline mode="left" style={{ fontFamily: "Lato" }}>
								{sessionHistory.map((e) => (
									<Timeline.Item
										label={dayjs(e?.created, "DD-MM-YYYY HH:mm").format(
											"DD/MM/YYYY h:mm a"
										)}
										key={e?.ID}
										color={"#1D428A"}
									>
										<div className={styles["row"]}>
											<span style={{ color: "#426DA9", fontWeight: "bold" }}>
												{e?.ip}&nbsp;
											</span>
											{e?.activate} conectado
										</div>
									</Timeline.Item>
								))}
							</Timeline>
						)
					)}
				</>
			)}
		</Container>
	);
};

export default HistoryPanel;

const Container = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;

	.ant-timeline .ant-timeline-item {
		padding-bottom: 35px;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-label {
		width: 21%;
		text-align: left;
		padding-left: 60px;
		font-size: 12px;
		color: #131523;
		font-weight: bold;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-head {
		margin-left: -5px;
		left: 22%;
		width: 12px;
		height: 12px;
		border: 2px solid;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
		left: 22%;
		border-left: 2px solid #2b80ff;
	}

	.ant-timeline.ant-timeline-label
		.ant-timeline-item-left
		.ant-timeline-item-content {
		left: 21%;
		width: 60%;
		font-size: 12px;
		color: #131523;
	}

	@media screen and (min-width: 1280px) {
		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: 21%;
			font-size: 12px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: 22%;
			width: 12px;
			height: 12px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: 22%;
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: 21%;
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: 21%;
			font-size: 13.5px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: 22%;
			width: 13.5px;
			height: 13.5px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: 22%;
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: 21%;
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: 17%;
			font-size: 15.18px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: 16%;
			width: 15.18px;
			height: 15.18px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: 16.2%;
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: 16%;
			font-size: 15.18px;
		}
	}
`;
