import React, { useState, useEffect } from "react";
import { Panel, PanelSubHeader } from "../../../components/panel/panel";
import { TileLayer, MapContainer, FeatureGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { myFetch, myFetchGet, myExportData } from "../../../services/services";
import styled from "styled-components";
import CustomModal from "../../../components/modal/CustomModal";
import FormOperatorCoverage from "./FormOperatorCoverage";
import { Button, Input, notification, Pagination, Space } from "antd";
import TableData from "../../../components/table/TableData";
import { Loading } from "../../../components/loading/Loading";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { useSelector } from "react-redux";
import MapSearch from "../../../components/mapSearch/MapSearch";
import ColorCircle from "../../../components/colorCircle/ColorCircle";
import TextColored from "../../../components/textColored/TextColored";
import TableText from "../../../components/tableText/TableText";
import fileDownload from "js-file-download";
import styles from "./OperatorCoverage.module.css";
import TablePagination from "../../../components/tablePagination/TablePagination";

const OperatorCoverage = ({ data }) => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);

	const addCoordinates = dataState.permissions.includes("add_cordenadas");
	const changeCoordinates = dataState.permissions.includes("change_cordenadas");
	const deleteCoordinates = dataState.permissions.includes("delete_cordenadas");

	const { ID } = data;

	const [totalTableSize, setTotalTableSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);
	const [tableColumns, setTableColumns] = useState([]);
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 });
	const [selectItem, setSelectItem] = useState();

	const center = dataState.operatorCountryCoordinates
		? {
				lat: dataState.operatorCountryCoordinates.latitude,
				lng: dataState.operatorCountryCoordinates.longitude,
		  }
		: { lat: -33.4377756, lng: -70.6504502 };
	const [key, setKey] = useState(0);
	// const [mapLayers, setMapLayers] = useState([]);
	const [operatorCoordinates, setOperatorCoordinates] = useState();

	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [isVisibleModifyForm, setIsVisibleModifyForm] = useState(false);
	const [changeView, setChangeView] = useState(false);
	const [loadingTable, setLoadingTable] = useState(false);

	const [invertedCoordinates, setInvertedCoordinates] = useState([]);
	const [coordinatesCenter, setCoordinatesCenter] = useState();

	const [searchData, setSearchData] = useState();
	const [sorterOrder, setSorterOrder] = useState("desc");
	const [exportFilters, setExportFilters] = useState({});

	//const [arrayLocation, setArrayLocation] = useState();
	//const [polygonCenter, setPolygonCenter] = useState();

	const ZOOM_LEVEL = dataState.operatorMapZoom ? dataState.operatorMapZoom : 14;

	const { Search } = Input;

	const clearData = undefined;

	const initial = async () => {
		try {
			const data = await myFetchGet(
				`api/v1/coordinates/?operator=${ID}`,
				token
			);
			const resultFilter = data
				.filter((obj) => !obj.deleted)
				.filter((obj) => obj.location.type !== "Point");
			const latLon = resultFilter?.map((e) => ({
				id: e.ID,
				name: e.name,
				coordinates: e.location.coordinates[0].map((e) => [e[1], e[0]]),
				color: e.color,
			}));
			setOperatorCoordinates(latLon);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const getRows = async () => {
		let filters = {};

		if (searchData !== undefined) {
			filters = {
				filters: `[["operator", "equal", "` + ID + `"]]`,
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else {
			filters = {
				filters: `[["operator", "equal", "` + ID + `"]]`,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		}

		const newFilters = {
			...filters,
			order_field: "ID",
			order_type: sorterOrder,
		};

		setExportFilters(newFilters);

		setLoadingTable(true);
		try {
			const response = await myFetch(
				`api/v1/coordinates/datatables/`,
				token,
				newFilters
			);
			if (response) {
				if (response.size) {
					setTotalTableSize(response.size);
				}
				if (response.data) {
					const rows = response.data.map((row) => {
						return {
							...row,
							key: row.ID,
						};
					});
					setTableRows(rows);
				}
			}
			setLoadingTable(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const editItem = (item) => {
		setSelectItem(item);
		setIsVisibleModifyForm(true);
	};

	const getColumns = async () => {
		try {
			const data = await myFetchGet(
				"api/v1/coordinates/datatables_struct",
				token
			);
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					onCell:
						columnsTittle === "ID" && changeCoordinates
							? (record, rowIndex) => {
									return {
										onClick: (event) => {
											event.stopPropagation();
											editItem(record);
										},
										style: { cursor: "pointer" },
										className: "hover-class",
									};
							  }
							: null,
					sorter: columnsTittle === "ID" ? true : null,
					title: columnsTittle,
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					render:
						columnsTittle === "ID" && changeCoordinates
							? (value) => <TextColored value={value} />
							: columnsTittle === "Color"
							? (value) => (
									<span
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<ColorCircle color={value} />
										<TableText value={value} />
									</span>
							  )
							: (value) => <TableText value={value} />,
				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	};

	const tableChangeHandler = (pagination, filter, sorter, extra) => {
		if (sorter.order === "ascend") {
			setSorterOrder("asc");
		} else if (sorter.order === "descend" || sorter.order === undefined) {
			setSorterOrder("desc");
		}
	};

	useEffect(() => {
		initial();
	}, []);

	useEffect(() => {
		getRows();
		getColumns();
	}, [changeView === true, searchData, rowsParams, sorterOrder]);

	{
		/*function handleMap(data) {
		const inverted = data.map((e) => {
			return [e.lng, e.lat];
		});
		inverted.push(inverted[0]);
		setArrayLocation(inverted);
	}*/
	}

	function refreshMap() {
		setKey((prevKey) => prevKey + 1);
	}

	const _onCreate = (e) => {
		//handleMap(e.layer._latlngs[0]);

		const inverted = e.layer._latlngs[0].map((e) => {
			return [e.lng, e.lat];
		});
		inverted.push(inverted[0]);

		setInvertedCoordinates(inverted);

		const { layerType, layer } = e;
		if (layerType === "polygon") {
			const { _leaflet_id } = layer;
			//setPolygonCenter(layer.getCenter());

			const center = { ...layer.getCenter() };
			const polygonCenter = Object.values(center);

			if (addCoordinates === true) {
				setCoordinatesCenter(polygonCenter);
				setIsVisibleForm(true);
			} else {
				notification.warning({
					className: "notification",
					message:
						"No tiene permisos para crear cobertura, comuníquese con un administrador para que le otorgue permisos",
					placement: "bottomLeft",
					duration: 2.5,
				});
				refreshMap();
			}

			{
				/*setMapLayers((layers) => [
				...layers,
				{
					id: _leaflet_id,
					latlngs: layer.getLatLngs()[0],
					center: layer.getCenter(),
				},
			]);*/
			}
		}
	};

	const _onEdit = (e) => {
		const {
			layers: { _layers },
		} = e;

		Object.values(_layers).map(({ _leaflet_id, editing, options }) => {
			const edited = editing.latlngs[0];
			const polygonID = options.id;

			const inverted = edited[0].map((e) => {
				return [e.lng, e.lat];
			});
			inverted.push(inverted[0]);

			if (changeCoordinates === true) {
				modifyCoverage(
					{
						location: {
							type: "Polygon",
							coordinates: [inverted],
						},
					},
					polygonID
				);
			} else {
				notification.warning({
					className: "notification",
					message:
						"No tiene permisos para modificar cobertura, comuníquese con un administrador para que le otorgue permisos",
					placement: "bottomLeft",
					duration: 2.5,
				});
				refreshMap();
			}

			{
				/*setMapLayers((layers) =>
				layers.map((l) =>
					l.id === _leaflet_id
						? { ...l, latlngs: { ...editing.latlngs[0] } }
						: l
				)
				);*/
			}
		});
	};

	const modifyCoverage = async (data, polygonID) => {
		try {
			await myFetch(`api/v1/coordinates/${polygonID}/`, token, data, "PATCH");
			notification.success({
				className: "notification",
				message: "Cobertura modificada exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			initial();
			refreshMap();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const _onDelete = (e) => {
		const {
			layers: { _layers },
		} = e;

		Object.values(_layers).map(({ _leaflet_id, options }) => {
			if (deleteCoordinates === true) {
				openNotification(options);
			} else {
				notification.warning({
					className: "notification",
					message:
						"No tiene permisos para eliminar cobertura, comuníquese con un administrador para que le otorgue permisos",
					placement: "bottomLeft",
					duration: 2.5,
				});
				refreshMap();
			}

			//setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
		});
	};

	const openNotification = (options) => {
		notification.warning({
			className: "notification",
			message: "¿Eliminar?",
			description: "¡No podrás recuperarlo si lo borras!",
			placement: "bottomLeft",
			// duration: 2.5,
			btn: (
				<Space>
					<Button
						type="link"
						size="small"
						onClick={() => {
							notification.destroy();
							cancelDelete();
						}}
					>
						Cancelar
					</Button>
					<Button
						type="primary"
						size="small"
						onClick={() => confirmDelete(options)}
					>
						Confirmar
					</Button>
				</Space>
			),
		});
	};

	const cancelDelete = () => {
		refreshMap();
	};

	const confirmDelete = async (options) => {
		try {
			await myFetch(`api/v1/coordinates/${options.id}/`, token, "", "DELETE");
			notification.success({
				className: "notification",
				message: "Cobertura eliminada exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			initial();
			refreshMap();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	/*function LocationMarker() {
		const [position, setPosition] = useState(null);
		const map = useMapEvents({
			click() {
				map.locate();
			},
			locationfound(e) {
				setPosition(e.latlng);
				map.flyTo(e.latlng, map.getZoom());
			},
		});

		return position === null ? null : (
			<Marker position={position}>
				<Popup>Estás aquí</Popup>
			</Marker>
		);
	}*/

	const onCloseModal = () => {
		setIsVisibleForm(false);
		refreshMap();
	};

	const onCloseModifyModal = () => {
		setIsVisibleModifyForm(false);
	};

	const changeViewHandler = () => {
		setChangeView(!changeView);
		initial();
	};

	const searchInputHandler = (value) => {
		if (value === "") {
			setSearchData(clearData);
		} else {
			setSearchData(value);
		}
	};

	const onExportData = async () => {
		try {
			const file = await myExportData(
				"api/v1/coordinates/datatables_download/",
				token,
				exportFilters
			);
			fileDownload(file, "Listado de Coordenadas.xlsx");
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<div className="col-md-12">
			<Panel className={!changeView ? "panel_no_margin" : null}>
				{isVisibleForm && (
					<CustomModal onClick={onCloseModal}>
						<FormOperatorCoverage
							modalHeader={"Detalles"}
							visible={isVisibleForm}
							setVisible={setIsVisibleForm}
							invertedCoordinates={invertedCoordinates}
							coordinatesCenter={coordinatesCenter}
							operatorID={ID}
							initial={initial}
							refreshMap={refreshMap}
						/>
					</CustomModal>
				)}

				{!changeView ? (
					<Container>
						<Button
							className={styles["hide-coverage-button"]}
							onClick={changeViewHandler}
						>
							Zonas de cobertura
						</Button>

						<MapContainer
							key={key}
							center={center}
							zoom={ZOOM_LEVEL}
							//ref={mapRef}
							style={{ height: "56vh", zIndex: "0", top: "-45px" }}
						>
							<FeatureGroup>
								<EditControl
									position="bottomright"
									onCreated={_onCreate}
									onEdited={_onEdit}
									onDeleted={_onDelete}
									draw={{
										rectangle: false,
										polyline: false,
										circle: false,
										circlemarker: false,
										marker: false,
									}}
								/>
								{operatorCoordinates?.map((e) => (
									<Polygon
										pathOptions={{
											color: e.color,
										}}
										positions={e.coordinates}
										id={e.id}
										key={e.id}
									/>
								))}
							</FeatureGroup>
							<MapSearch />
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
						</MapContainer>
					</Container>
				) : (
					<Container>
						<div className={`${styles["row"]} ${styles["align-center"]}`}>
							<Button
								className={styles["show-coverage-button"]}
								onClick={changeViewHandler}
							>
								<i
									className="fa-solid fa-angle-left"
									style={{ marginRight: "20px" }}
								></i>
								Regresar
							</Button>
						</div>

						<PanelSubHeader>
							<SubHeaderContainer>
								<span className={styles["subheader-title"]}>
									Listado consolidado de detalle de zonas de cobertura del
									operador.
								</span>
								<Search
									placeholder="Realizar búsqueda"
									style={{ width: "219px" }}
									allowClear
									searchInputHandler={searchInputHandler}
								/>
							</SubHeaderContainer>
						</PanelSubHeader>

						{isVisibleModifyForm && (
							<CustomModal onClick={onCloseModifyModal}>
								<FormOperatorCoverage
									modalHeader={"Modificar detalles"}
									data={selectItem ? selectItem : {}}
									cancel={() => setSelectItem()}
									setVisible={setIsVisibleModifyForm}
									visible={isVisibleModifyForm}
									getRows={getRows}
									operatorID={ID}
								/>
							</CustomModal>
						)}

						{loadingTable ? (
							<Loading />
						) : (
							<>
								<TableData
									rows={tableRows}
									columns={tableColumns}
									onChange={tableChangeHandler}
								/>

								<div
									className={`${styles["row"]} ${styles["pagination-container"]}`}
								>
									<TablePagination
										paginationSettings={paginationSettings}
										totalTableSize={totalTableSize}
										onChange={modifyPagination}
										showTotal={(total, range) => (
											<div className={styles["column"]}>
												<span className={styles["pagination"]}>
													Mostrando del {range[0]} al {range[1]} de un total de{" "}
													{total} registros
												</span>
												<span
													className={styles["export-button"]}
													onClick={onExportData}
												>
													<i
														className={`fa-solid fa-file-excel ${styles["export-button-icon"]}`}
													></i>
													Exportar data
												</span>
											</div>
										)}
									/>
								</div>
							</>
						)}
					</Container>
				)}
			</Panel>
		</div>
	);
};

export default OperatorCoverage;

const Container = styled.div`
	width: 100%;

	.leaflet-touch .leaflet-geosearch-bar form input {
		font-size: 14px;
		height: 38px;
	}

	.leaflet-touch .leaflet-control-geosearch button.reset {
		font-size: 18px;
		top: 5px;
		right: 5px;
	}
`;

const SubHeaderContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	.ant-input-affix-wrapper:not(:last-child) {
		border-radius: 5px 0 0 5px !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		font-size: 12px;
	}

	.ant-input-search
		> .ant-input-group
		> .ant-input-group-addon:last-child
		.ant-input-search-button {
		border-radius: 0 5px 5px 0 !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 15.18px;
		}
	}
`;
