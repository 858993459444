import React, { useState, useEffect } from "react";
import {
	Select,
	DatePicker,
	Input,
	Button,
	Divider,
	Space,
	InputNumber,
	notification,
	Checkbox,
	Switch,
} from "antd";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import { myFetchGet, myFetch } from "../../../services/services";
import { useSelector } from "react-redux";
import AuthenticationGoggle from "../../../components/authenticationGoggle/AuthenticationGoggle";
import { useReduxToken } from "../../../hooks/useReduxToken";
import dayjs from "dayjs";
import { regex } from "../../../helpers/regex";
import CustomTooltip from "../../../components/customTooltip/CustomTooltip";
import { LoadingForm } from "../../../components/loading/Loading";
import styles from "./FormOperatorPromotions.module.css";

const FormOperatorPromotions = ({
	data,
	setVisible,
	cancel,
	visible,
	modalHeader,
	operatorID,
	initial,
}) => {
	const dataState = useSelector((state) => state.authState);
	const token = useReduxToken();
	const [loading, setLoading] = useState(true);

	/* ------------STATES QUE VIENEN DEL DATATABLE------------ */
	const [name, setName] = useState(data?.name);
	const [status, setStatus] = useState(data?.status || 1);

	const [planID, setPlanID] = useState();
	const [indefinitePromotion, setIndefinitePromotion] = useState(false);
	const [dateRange, setDateRange] = useState([]);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [indefiniteCountPeriod, setIndefiniteCountPeriod] = useState(false);
	const [periodType, setPeriodType] = useState();
	const [countPeriod, setCountPeriod] = useState();
	const [baseDiscountType, setBaseDiscountType] = useState();
	const [baseDiscount, setBaseDiscount] = useState();
	const [installationDiscountType, setInstallationDiscountType] = useState();
	const [installationDiscount, setInstallationDiscount] = useState();
	const [description, setDescription] = useState();
	const [promotionID, setPromotionID] = useState();
	const [planType, setPlanType] = useState();
	const [planCategory, setPlanCategory] = useState([]);

	const [kindPlan, setKindPlan] = useState([]);
	const [operatorPlans, setOperatorPlans] = useState();
	const [operatorPlanDetail, setOperatorPlanDetail] = useState();

	const datePeriodHandler = () => {
		if (startDate && endDate && !indefinitePromotion) {
			setDateRange([
				dayjs(startDate, "DD-MM-YYYY"),
				dayjs(endDate, "DD-MM-YYYY"),
			]);
		}
	};

	const [promotion, setPromotion] = useState(true);
	const [visibleVerify, setVisibleVerify] = useState(false);

	/*Validaciones */
	const [errors, setErrors] = useState({
		name: "",
		dateRange: "",
		baseDiscountType: "",
		baseDiscount: "",
		installationDiscountType: "",
		installationDiscount: "",
		planType: "",
		planCategory: "",
		planID: "",
		periodType: "",
		countPeriod: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		name: false,
		dateRange: false,
		baseDiscountType: false,
		baseDiscount: false,
		installationDiscountType: false,
		installationDiscount: false,
		planType: false,
		planCategory: false,
		planID: false,
		periodType: false,
		countPeriod: false,
	});

	const getKindPlan = async () => {
		try {
			const response = await myFetchGet("api/v1/kindplan", token);
			setKindPlan(response);
			setLoading(false);
		} catch (e) {
			console.log("error", e);
		}
	};

	const getPlans = async (type, sortedCategory) => {
		try {
			const response = await myFetchGet(
				`api/v1/plans/?operator=${operatorID}`,
				token
			);
			const filteredByType = response.filter((obj) => obj.type_plan === type);
			const filteredByCategory = filteredByType.filter(
				(obj) => obj.category.toString() === sortedCategory.toString()
			);
			setOperatorPlans(filteredByCategory);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const getPlanDetails = async (ID) => {
		try {
			const response = await myFetchGet(`api/v1/plans/${ID}/`, token);
			setOperatorPlanDetail(response);
		} catch (e) {
			console.log("error", e);
		}
	};

	const getPromotion = async () => {
		setLoading(true);
		try {
			const response = await myFetchGet(
				`api/v1/promotions/${data?.id_unique}/`,
				token
			);
			setPromotionID(response.ID);
			if (!response.indefinite_promotion) {
				setIndefinitePromotion(false);
				setStartDate(response.start_date);
				setEndDate(response.end_date);
			} else {
				setIndefinitePromotion(response.indefinite_promotion);
				setStartDate(undefined);
				setEndDate(undefined);
			}
			if (!response.indefinite_count_period) {
				setIndefiniteCountPeriod(false);
				setPeriodType(response.period_type);
				setCountPeriod(response.count_period);
			} else {
				setIndefiniteCountPeriod(response.indefinite_count_period);
				setPeriodType(undefined);
				setCountPeriod(undefined);
			}
			setBaseDiscountType(response.discount_plan_type);
			setBaseDiscount(response.discount_plan_cost);
			setInstallationDiscountType(response.discount_installation_type);
			setInstallationDiscount(response.discount_installation_cost);
			setDescription(response.description);
			setOperatorPlanDetail(response.plan_detail);
			setPlanID(response.plan_detail.ID);
			setPlanCategory(response.plan_detail.category);
			setPlanType(response.plan_detail.type_plan);
			getPlans(response.plan_detail.type_plan, response.plan_detail.category);

			getKindPlan();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		if (name) {
			getPromotion();
		} else {
			getKindPlan();
		}
	}, []);

	useEffect(() => {
		datePeriodHandler();
	}, [startDate, endDate]);

	useEffect(() => {
		let nameError = "";
		let dateRangeError = "";
		let baseDiscountTypeError = "";
		let baseDiscountError = "";
		let installationDiscountTypeError = "";
		let installationDiscountError = "";
		let planTypeError = "";
		let planCategoryError = "";
		let planIDError = "";
		let periodTypeError = "";
		let countPeriodError = "";

		if (!name) {
			nameError = "Ingrese el nombre de la promoción";
		}

		if (!startDate && !indefinitePromotion) {
			dateRangeError = "Seleccione un rango de fechas";
		}

		if (!baseDiscountType) {
			baseDiscountTypeError = "Ingrese el tipo de descuento al costo base";
		}

		if (regex.regexAmount.test(baseDiscount) === false) {
			baseDiscountError = "Ingrese el monto del descuento";
		}

		if (!installationDiscountType) {
			installationDiscountTypeError =
				"Ingrese el tipo de descuento al costo de instalación";
		}

		if (regex.regexAmount.test(installationDiscount) === false) {
			installationDiscountError = "Ingrese el monto del descuento";
		}

		if (!baseDiscountType && installationDiscountType) {
			baseDiscountTypeError = "";
			baseDiscountError = "";
		}

		if (!installationDiscountType && baseDiscountType) {
			installationDiscountTypeError = "";
			installationDiscountError = "";
		}

		if (!planType) {
			planTypeError = "Seleccione el tipo de plan";
		}

		if (!planCategory) {
			planCategoryError = "Seleccione la categoría";
		} else if (planType === 2 && planCategory.length < 2) {
			planCategoryError = "Debe seleccionar dos categorías";
		} else if (planType === 3 && planCategory.length < 3) {
			planCategoryError = "Debe seleccionar tres categorías";
		}

		if (!planID) {
			planIDError = "Seleccione un plan";
		}

		if (!periodType && !indefiniteCountPeriod) {
			periodTypeError = "Seleccione el periodo de la promoción";
		}

		if (!countPeriod && !indefiniteCountPeriod) {
			countPeriodError = "Ingrese la cantidad del periodo";
		} else if (
			regex.regexNumbers.test(countPeriod) === false &&
			!indefiniteCountPeriod
		) {
			countPeriodError = "Ingrese solo números";
		}

		setErrors({
			name: nameError,
			dateRange: dateRangeError,
			baseDiscountType: baseDiscountTypeError,
			baseDiscount: baseDiscountError,
			installationDiscountType: installationDiscountTypeError,
			installationDiscount: installationDiscountError,
			planType: planTypeError,
			planCategory: planCategoryError,
			planID: planIDError,
			periodType: periodTypeError,
			countPeriod: countPeriodError,
		});
	}, [
		name,
		startDate,
		endDate,
		baseDiscountType,
		baseDiscount,
		installationDiscountType,
		installationDiscount,
		planType,
		planCategory,
		planID,
		periodType,
		countPeriod,
		indefinitePromotion,
		indefiniteCountPeriod,
		errorStatus,
	]);

	const validateInput = () => {
		if (errors.name) {
			setErrorStatus((prevState) => ({ ...prevState, name: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, name: false }));
		}

		if (errors.dateRange) {
			setErrorStatus((prevState) => ({ ...prevState, dateRange: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, dateRange: false }));
		}

		if (errors.baseDiscountType) {
			setErrorStatus((prevState) => ({ ...prevState, baseDiscountType: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				baseDiscountType: false,
			}));
		}

		if (errors.baseDiscount) {
			setErrorStatus((prevState) => ({ ...prevState, baseDiscount: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, baseDiscount: false }));
		}

		if (errors.installationDiscountType) {
			setErrorStatus((prevState) => ({
				...prevState,
				installationDiscountType: true,
			}));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				installationDiscountType: false,
			}));
		}

		if (errors.installationDiscount) {
			setErrorStatus((prevState) => ({
				...prevState,
				installationDiscount: true,
			}));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				installationDiscount: false,
			}));
		}

		if (errors.planType) {
			setErrorStatus((prevState) => ({ ...prevState, planType: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, planType: false }));
		}

		if (errors.planCategory) {
			setErrorStatus((prevState) => ({ ...prevState, planCategory: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, planCategory: false }));
		}

		if (errors.planID) {
			setErrorStatus((prevState) => ({ ...prevState, planID: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, planID: false }));
		}

		if (errors.periodType) {
			setErrorStatus((prevState) => ({ ...prevState, periodType: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, periodType: false }));
		}

		if (errors.countPeriod) {
			setErrorStatus((prevState) => ({ ...prevState, countPeriod: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, countPeriod: false }));
		}

		setErrorStatus({
			name: false,
			dateRange: false,
			baseDiscountType: false,
			baseDiscount: false,
			installationDiscountType: false,
			installationDiscount: false,
			planType: false,
			planCategory: false,
			planID: false,
			periodType: false,
			countPeriod: false,
		});
	};

	const onSubmit = async (data, ID) => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			name: false,
			dateRange: false,
			baseDiscountType: false,
			baseDiscount: false,
			installationDiscountType: false,
			installationDiscount: false,
			planType: false,
			planCategory: false,
			planID: false,
			periodType: false,
			countPeriod: false,
		});

		if (ID) {
			try {
				await myFetch(`api/v1/promotions/${ID}/`, token, data, "PATCH");
				notification.success({
					className: "notification",
					message: "Promoción modificada exitosamente",
					placement: "bottomLeft",
					duration: 2.5,
				});
				onCloseModal();
				initial();
			} catch (e) {
				console.log("error", e);
				notification.error({
					className: "notification",
					message: "Se ha producido un error inesperado",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		} else {
			try {
				await myFetch("api/v1/promotions/", token, data);
				notification.success({
					className: "notification",
					message: "Promoción creada exitosamente",
					placement: "bottomLeft",
					duration: 2.5,
				});
				onCloseModal();
				initial();
			} catch (e) {
				console.log("error", e);
				notification.error({
					className: "notification",
					message: "Se ha producido un error inesperado",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		}
	};

	const { RangePicker } = DatePicker;

	const { TextArea } = Input;

	const selectBaseDiscountBefore = (
		<Select
			defaultValue="USD"
			style={{
				width: 60,
			}}
		>
			<Select.Option key={"USD-BD"} value="USD">
				$
			</Select.Option>
			<Select.Option key={"EUR-BD"} value="EUR">
				€
			</Select.Option>
			<Select.Option key={"GBP-BD"} value="GBP">
				£
			</Select.Option>
			<Select.Option key={"CNY-BD"} value="CNY">
				¥
			</Select.Option>
		</Select>
	);

	const selectInstallationDiscountBefore = (
		<Select
			defaultValue="USD"
			style={{
				width: 60,
			}}
		>
			<Select.Option key={"USD-ID"} value="USD">
				$
			</Select.Option>
			<Select.Option key={"EUR-ID"} value="EUR">
				€
			</Select.Option>
			<Select.Option key={"GBP-ID"} value="GBP">
				£
			</Select.Option>
			<Select.Option key={"CNY-ID"} value="CNY">
				¥
			</Select.Option>
		</Select>
	);

	const onCloseModal = () => {
		setVisible(false);
		cancel();
	};

	const checkIndefinitePeriodHandler = (e) => {
		setIndefinitePromotion(e.target.checked);
		if (e.target.checked) {
			setStartDate(undefined);
			setEndDate(undefined);
			setDateRange([undefined, undefined]);
		}
		validateInput();
	};

	const dateFormat = "DD/MM/YYYY";

	const dateSelectHandler = (range) => {
		const startDate = range[0].format("DD-MM-YYYY");
		const endDate = range[1].format("DD-MM-YYYY");

		setStartDate(startDate);
		setEndDate(endDate);
		setDateRange([range[0], range[1]]);
	};

	const baseDiscountTypeHandler = (value) => {
		setBaseDiscountType(value);
	};

	const installationDiscountTypeHandler = (value) => {
		setInstallationDiscountType(value);
	};

	const planTypeSelectHandler = (value) => {
		setPlanType(value);
		setPlanCategory([]);
		setOperatorPlans([]);
		setPlanID();
		setOperatorPlanDetail();
	};

	const validateOptions = (selectedOptions, maxCount) => {
		if (selectedOptions.length > maxCount) {
			return false;
		}

		if (planType === 1 && selectedOptions.length > 1) {
			return false;
		}

		if (planType === 2 && selectedOptions.length > 2) {
			return false;
		}

		return true;
	};

	const planCategorySelectHandler = (value) => {
		const sortedValue = value.sort(function (a, b) {
			return a - b;
		});
		setPlanCategory(sortedValue);
		getPlans(planType, sortedValue);
		setPlanID(undefined);
	};

	const planSelectHandler = (value) => {
		setPlanID(value);
		getPlanDetails(value);
	};

	const checkIndefinitePeriod = (checked) => {
		setIndefiniteCountPeriod(checked);
		setCountPeriod(undefined);
		setPeriodType(undefined);
	};

	const periodTypeSelectHandler = (value) => {
		setPeriodType(value);
	};

	const statusSelectHandler = (value) => {
		setStatus(value);
	};

	const verifyStatus = () => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			name: false,
			dateRange: false,
			discountType: false,
			discount: false,
			assignment: false,
			planType: false,
			planCategory: false,
			planID: false,
			periodType: false,
			countPeriod: false,
		});

		const verifyAuthenticationLogin = dataState.authentication;
		if (verifyAuthenticationLogin === true) {
			setTimeout(() => {
				setPromotion(false);
				setVisibleVerify(true);
			}, 200);
		} else {
			setVisibleVerify(false);
			notification.error({
				className: "notification",
				message: "Activar verificación en dos pasos",
				description: (
					<>
						Para realizar alguna modificación en dicha sección debe activar{" "}
						<b>Google Authenticator</b>
					</>
				),
				placement: "bottomLeft",
				duration: 2.5,
				onClose: () => {
					cancel();
					setVisible(false);
				},
			});
		}
	};

	const confirmResponse = () => {
		onSubmit(
			{
				period_type: periodType,
				count_period: countPeriod,
				name: name,
				start_date: startDate,
				end_date: endDate,
				description: description,
				status: status,
				discount_plan_type: baseDiscountType,
				discount_plan_cost: baseDiscount,
				discount_installation_type: installationDiscountType,
				discount_installation_cost: installationDiscount,
				plan: planID,
				operator: operatorID,
				indefinite_count_period: indefiniteCountPeriod,
				indefinite_promotion: indefinitePromotion,
			},
			promotionID
		);
	};

	if (visible) {
		return (
			<Container>
				{promotion && (
					<div
						className={`${styles["form-container"]} animate__animated animate__fadeInRight ${styles["custom-scroll"]}`}
					>
						<div
							className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
						>
							<span className={styles["form-title"]}>
								{modalHeader} promoción
							</span>
							<CloseOutlined
								className={styles["close-form-icon"]}
								onClick={onCloseModal}
							/>
						</div>

						<Divider />

						{loading ? (
							<LoadingForm />
						) : (
							<>
								<span className={styles["form-section-title"]}>
									Información general
								</span>
								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Nombre <span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											style={{
												width: "95%",
											}}
											placeholder="Promo 2x1"
											value={name}
											onChange={(e) => setName(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.name ? "error" : ""}
										/>
										{errorStatus.name && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.name}
												</span>
											</div>
										)}
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											<span className={styles["form-input-name"]}>
												Vigencia <span className={styles["blue"]}>*</span>
											</span>
											<Checkbox
												className={styles["checkbox"]}
												onChange={checkIndefinitePeriodHandler}
												checked={indefinitePromotion}
											>
												Indefinida
											</Checkbox>
										</div>
										<RangePicker
											className={styles["form-input"]}
											style={{
												backgroundColor: indefinitePromotion
													? "#f5f5f5"
													: "white",
											}}
											onChange={dateSelectHandler}
											onBlur={validateInput}
											status={errorStatus.dateRange ? "error" : ""}
											placeholder={["Fecha Inicio", "Fecha Fin"]}
											format={dateFormat}
											disabled={indefinitePromotion}
											value={indefinitePromotion ? null : dateRange}
										/>
										{errorStatus.dateRange && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.dateRange}
												</span>
											</div>
										)}
									</div>
								</div>

								<br />
								<br />

								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Descuento a costo base del plan
										</span>
										<span className={styles["form-input-name"]}>
											<b>Valor</b> <span className={styles["blue"]}>*</span>
										</span>
										<div className={styles["row"]}>
											<div
												className={styles["column"]}
												style={{ width: "40%" }}
											>
												<Select
													className={styles["form-input"]}
													status={errorStatus.baseDiscountType ? "error" : ""}
													onChange={baseDiscountTypeHandler}
													onBlur={validateInput}
													value={baseDiscountType}
													bordered={false}
													placeholder="Seleccione"
												>
													<Select.Option key={1} value={1}>
														Monto
													</Select.Option>
													<Select.Option key={2} value={2}>
														Porcentaje
													</Select.Option>
												</Select>
											</div>
											<div
												className={styles["column"]}
												style={{ width: "50%", marginLeft: "5%" }}
											>
												<InputNumber
													addonBefore={selectBaseDiscountBefore}
													placeholder="1000"
													style={{
														borderRadius: "7px",
													}}
													value={baseDiscount}
													onChange={(value) => setBaseDiscount(value)}
													onBlur={validateInput}
													status={errorStatus.baseDiscount ? "error" : ""}
												/>
											</div>
										</div>
										{errorStatus.baseDiscountType && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.baseDiscountType}
												</span>
											</div>
										)}
										{errorStatus.baseDiscount && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.baseDiscount}
												</span>
											</div>
										)}
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Descuento a costo de instalación del plan
										</span>
										<span className={styles["form-input-name"]}>
											<b>Valor</b> <span className={styles["blue"]}>*</span>
										</span>
										<div className={styles["row"]}>
											<div
												className={styles["column"]}
												style={{ width: "40%" }}
											>
												<Select
													className={styles["form-input"]}
													status={
														errorStatus.installationDiscountType ? "error" : ""
													}
													onChange={installationDiscountTypeHandler}
													onBlur={validateInput}
													value={installationDiscountType}
													bordered={false}
													placeholder="Seleccione"
												>
													<Select.Option key={1} value={1}>
														Monto
													</Select.Option>
													<Select.Option key={2} value={2}>
														Porcentaje
													</Select.Option>
												</Select>
											</div>
											<div
												className={styles["column"]}
												style={{ width: "50%", marginLeft: "5%" }}
											>
												<InputNumber
													addonBefore={selectInstallationDiscountBefore}
													placeholder="1000"
													style={{
														borderRadius: "7px",
													}}
													value={installationDiscount}
													onChange={(value) => setInstallationDiscount(value)}
													onBlur={validateInput}
													status={
														errorStatus.installationDiscount ? "error" : ""
													}
												/>
											</div>
										</div>
										{errorStatus.installationDiscountType && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.installationDiscountType}
												</span>
											</div>
										)}
										{errorStatus.installationDiscount && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.installationDiscount}
												</span>
											</div>
										)}
									</div>
								</div>

								<br />
								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Descripción
										</span>
										<TextArea
											rows={3}
											className={styles["form-textarea"]}
											value={description}
											onChange={(e) => setDescription(e?.target?.value)}
										/>
									</div>
								</div>

								<br />
								<br />

								<span className={styles["form-section-title"]}>
									Datos técnicos del plan
								</span>
								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Plan <span className={styles["blue"]}>*</span>
										</span>
										<div className={styles["row"]}>
											<div
												className={styles["column"]}
												style={{ width: "35%" }}
											>
												<Select
													className={styles["form-input"]}
													value={planType}
													onChange={planTypeSelectHandler}
													onBlur={validateInput}
													status={errorStatus.planType ? "error" : ""}
													bordered={false}
													placeholder="Seleccione"
												>
													<Select.Option key={1} value={1}>
														Simple
													</Select.Option>
													<Select.Option key={2} value={2}>
														Duo
													</Select.Option>
													<Select.Option key={3} value={3}>
														Trio
													</Select.Option>
												</Select>
												{errorStatus.planType && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.planType}
														</span>
													</div>
												)}
											</div>
											<div
												className={styles["column"]}
												style={{ width: "55%", marginLeft: "5%" }}
											>
												<Select
													mode="multiple"
													className={styles["form-input"]}
													dropdownStyle={{ borderRadius: 7 }}
													bordered={false}
													maxTagCount="responsive"
													placeholder="Seleccione"
													onChange={(value) => {
														if (validateOptions(value, 3)) {
															planCategorySelectHandler(value);
														}
													}}
													onBlur={validateInput}
													status={errorStatus.planCategory ? "error" : ""}
													value={planCategory}
												>
													{kindPlan.map((item) => (
														<Select.Option key={item.ID} value={item.ID}>
															{item.name}
														</Select.Option>
													))}
												</Select>
												{errorStatus.planCategory && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.planCategory}
														</span>
													</div>
												)}
											</div>
										</div>
									</div>
									<div
										className={`${styles["column"]} ${styles["flex"]} ${styles["justify-end"]}`}
									>
										<Select
											className={styles["form-input"]}
											onChange={planSelectHandler}
											onBlur={validateInput}
											status={errorStatus.planID ? "error" : ""}
											value={planID}
											bordered={false}
											placeholder="Seleccione"
										>
											{operatorPlans?.map((item) => (
												<Select.Option key={item?.ID} value={item?.ID}>
													{item?.tradename}
												</Select.Option>
											))}
										</Select>
										{errorStatus.planID && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.planID}
												</span>
											</div>
										)}
									</div>
								</div>

								<br />
								<br />

								<div className={`${styles["row"]} ${styles["align-center"]}`}>
									<span className={styles["form-input-name"]}>
										<b>¿La duración de la promoción es indefinida?</b>
									</span>
									<CustomTooltip
										subtitle={
											"Tiempo de duración de la promoción posterior a la contratación."
										}
									/>
									<div
										className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
										style={{ width: "15%", marginLeft: "5%" }}
									>
										<span className={styles["form-input-name"]}>No</span>
										<Switch
											onChange={checkIndefinitePeriod}
											checked={indefiniteCountPeriod}
										/>
										<span className={styles["form-input-name"]}>Sí</span>
									</div>
								</div>

								<br />

								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<div className={styles["row"]}>
											<span className={styles["form-input-name"]}>
												Periodo <span className={styles["blue"]}>*</span>
											</span>
										</div>
										<div className={styles["row"]}>
											<div
												className={styles["column"]}
												style={{ width: "35%" }}
											>
												<Select
													className={styles["form-input"]}
													style={{
														backgroundColor: indefiniteCountPeriod
															? "#f5f5f5"
															: "white",
													}}
													onChange={periodTypeSelectHandler}
													onBlur={validateInput}
													status={errorStatus.periodType ? "error" : ""}
													value={periodType}
													bordered={false}
													disabled={indefiniteCountPeriod}
													placeholder="Seleccione"
												>
													<Select.Option key={1} value={1}>
														Días
													</Select.Option>
													<Select.Option key={2} value={2}>
														Meses
													</Select.Option>
												</Select>
												{errorStatus.periodType && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.periodType}
														</span>
													</div>
												)}
											</div>
											<div
												className={styles["column"]}
												style={{ width: "55%", marginLeft: "5%" }}
											>
												<Input
													className={styles["form-input"]}
													style={{
														backgroundColor: indefiniteCountPeriod
															? "#f5f5f5"
															: "white",
													}}
													value={countPeriod}
													disabled={indefiniteCountPeriod}
													onChange={(e) => setCountPeriod(e?.target?.value)}
													onBlur={validateInput}
													status={errorStatus.countPeriod ? "error" : ""}
												/>
												{errorStatus.countPeriod && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.countPeriod}
														</span>
													</div>
												)}
											</div>
										</div>
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>Estatus</span>
										<Select
											className={styles["form-input"]}
											onChange={statusSelectHandler}
											value={status}
											bordered={false}
										>
											<Select.Option key={1} value={1}>
												Activo
											</Select.Option>
											<Select.Option key={2} value={2}>
												Inactivo
											</Select.Option>
										</Select>
									</div>
								</div>

								<br />
								<br />

								{countPeriod ||
								(planID && indefiniteCountPeriod) ||
								modalHeader === "Modificar" ? (
									<div className={styles["breadcrumb"]}>
										<div className={styles["column"]} style={{ width: "100%" }}>
											<div className={styles["row"]}>
												<b className={styles["breadcrumb-header"]}>
													Resumen de promoción
												</b>
											</div>
											<div className={styles["row"]}>
												<div
													className={styles["column"]}
													style={{ width: "60%" }}
												>
													<div className={styles["row"]}>
														<b>Nombre:&nbsp;</b> {name}
													</div>
												</div>
												<div
													className={`${styles["column"]} ${styles["flex"]}`}
												>
													<div className={styles["row"]}>
														<b>Vigencia:&nbsp;</b>{" "}
														{indefinitePromotion ? (
															<span>Indefinido</span>
														) : (
															<span>
																{startDate} - {endDate}
															</span>
														)}
													</div>
												</div>
											</div>
											{operatorPlanDetail && (
												<>
													<div className={styles["row"]}>
														<b>Plan:&nbsp;</b> {operatorPlanDetail?.tradename}
													</div>
													<div className={styles["row"]}>
														<b>Valor:&nbsp;</b> {operatorPlanDetail?.price}
													</div>
												</>
											)}
											{baseDiscountType &&
												baseDiscount > 0 &&
												operatorPlanDetail && (
													<div className={styles["row"]}>
														<div
															className={styles["column"]}
															style={{ width: "60%" }}
														>
															<div className={styles["row"]}>
																<b>Descuento:&nbsp;</b>
																{baseDiscountType &&
																	baseDiscountType === 1 &&
																	`${baseDiscount} al costo base del plan`}
																{baseDiscountType &&
																	baseDiscountType === 2 &&
																	`${baseDiscount}% al costo base del plan`}
															</div>
														</div>

														<div
															className={`${styles["column"]} ${styles["flex"]}`}
														>
															<div className={styles["row"]}>
																<b>Promoción:&nbsp;</b>
																{baseDiscountType &&
																	baseDiscountType === 1 &&
																	+operatorPlanDetail.price - +baseDiscount}
																{baseDiscountType &&
																	baseDiscountType === 2 &&
																	((100 - +baseDiscount) *
																		+operatorPlanDetail.price) /
																		100}
															</div>
														</div>
													</div>
												)}
											{installationDiscountType &&
												installationDiscount > 0 &&
												operatorPlanDetail && (
													<div className={styles["row"]}>
														<div
															className={styles["column"]}
															style={{ width: "60%" }}
														>
															<div className={styles["row"]}>
																<b>Descuento:&nbsp;</b>
																{installationDiscountType &&
																	installationDiscountType === 1 &&
																	`${installationDiscount} al costo de instalación del plan`}
																{installationDiscountType &&
																	installationDiscountType === 2 &&
																	`${installationDiscount}% al costo de instalación del plan`}
															</div>
														</div>

														<div
															className={`${styles["column"]} ${styles["flex"]}`}
														>
															<div className={styles["row"]}>
																<b>Promoción:&nbsp;</b>
																{installationDiscountType &&
																	installationDiscountType === 1 &&
																	+operatorPlanDetail.installation_price -
																		+installationDiscount}
																{installationDiscountType &&
																	installationDiscountType === 2 &&
																	((100 - +installationDiscount) *
																		+operatorPlanDetail.installation_price) /
																		100}
															</div>
														</div>
													</div>
												)}
											<div className={styles["row"]}>
												<b>Periodo:&nbsp;</b>
												{indefiniteCountPeriod ? (
													<span>Indefinido</span>
												) : (
													<>
														{countPeriod !== undefined && periodType === 1
															? countPeriod + " " + "Día(s)"
															: null}
														{countPeriod !== undefined && periodType === 2
															? countPeriod + " " + "Mes(es)"
															: null}
													</>
												)}
											</div>
											<div className={styles["row"]}>
												<b>Descripción:&nbsp;</b> {description}
											</div>
										</div>
									</div>
								) : null}

								<br />
								<br />

								<div className={`${styles["row"]} ${styles["justify-end"]}`}>
									<Space>
										<Button
											className={`${styles["form-action-button"]} ${styles["form-action-button-cancel"]}`}
											onClick={() => {
												setVisible(false);
												cancel();
											}}
										>
											Cancelar
										</Button>
										{modalHeader === "Modificar" ? (
											<Button
												className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
												onClick={() => verifyStatus()}
											>
												Confirmar
											</Button>
										) : (
											<Button
												className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
												onClick={() => {
													onSubmit({
														period_type: periodType,
														count_period: countPeriod,
														name: name,
														start_date: startDate,
														end_date: endDate,
														description: description,
														status: status,
														discount_plan_type: baseDiscountType,
														discount_plan_cost: baseDiscount,
														discount_installation_type:
															installationDiscountType,
														discount_installation_cost: installationDiscount,
														plan: planID,
														operator: operatorID,
														indefinite_count_period: indefiniteCountPeriod,
														indefinite_promotion: indefinitePromotion,
													});
												}}
											>
												Guardar
											</Button>
										)}
									</Space>
								</div>
							</>
						)}
					</div>
				)}

				{/*  form Security */}
				{visibleVerify && (
					<div className={styles["authentication-modal-container"]}>
						<AuthenticationGoggle
							visible={visibleVerify}
							setVisible={setVisibleVerify}
							confirmResponse={confirmResponse}
							cancel={() => {
								cancel();
								setVisible(false);
							}}
						/>
					</div>
				)}
			</Container>
		);
	} else {
		return null;
	}
};

export default FormOperatorPromotions;

const Container = styled.div`
	background: hsla(0, 0%, 0%, 0.5);
	width: 42%;

	.ant-select-selector {
		font-size: 12px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}

	.ant-select-multiple .ant-select-selection-item-remove {
		display: flex;
	}

	.ant-picker .ant-picker-input >input {
		font-size: 12px;
	}

	.ant-input-number-group-wrapper {
		margin-top: 0.5em;
	}

	.ant-input-number-group-addon {
		height: 40px;
		border-radius: 5px 0 0 5px;
	}

	.ant-input-number {
		height: 40px;
		padding: 4px 11px 4px;
		border-radius 0 5px 5px 0;
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector, 
		.ant-picker .ant-picker-input >input, 
		.ant-input-number {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector, 
		.ant-picker .ant-picker-input >input, 
		.ant-input-number {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector, 
		.ant-picker .ant-picker-input >input, 
		.ant-input-number {
			font-size: 15.18px;
		}
	}
`;
