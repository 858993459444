import React from "react";

import styled from "styled-components";
import { Divider, Progress } from "antd";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import devices from "../../helpers/sizes";
import { largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import { useClassDashboard } from "../../hooks/useClassDashboard";
import { validationNumber } from "../../helpers/validationNumber";
import CustomTooltip from "../customTooltip/CustomTooltip";
import { ResponsiveBar } from "@nivo/bar";
import { Link } from "react-router-dom";

const MidCards = ({
	titleVentas,
	totalVentas,
	porcentajeVentas,
	porcentajeApplication,
	porcentajeDirectas,
	porcentajeTotalComprado,
	porcentajeRespectoComprado,
	porcentajeTotalCulminadas,
	dataGraphRelation,
	pieChartData,
}) => {
	const percentSignoSales = Math.abs(porcentajeVentas);
	const percentSignoApplication = Math.abs(porcentajeApplication);
	const percentSignoDirect = Math.abs(porcentajeDirectas);
	const percentSignoShopping = Math.abs(porcentajeRespectoComprado);
	const percentSignoCompletedSales = Math.abs(porcentajeTotalCulminadas);

	const ventas = useClassDashboard(porcentajeVentas);
	const application = useClassDashboard(porcentajeApplication);
	const directas = useClassDashboard(porcentajeDirectas);
	const compras = useClassDashboard(porcentajeRespectoComprado);
	const ventasCulminadas = useClassDashboard(porcentajeTotalCulminadas);

	const percentSignoSalesFixed = percentSignoSales.toFixed(2);
	const percentSignoApplicationFixed = percentSignoApplication.toFixed(2);
	const percentSignoDirectFixed = percentSignoDirect.toFixed(2);
	const porcentajeTotalCompradoFixed = porcentajeTotalComprado.toFixed(2);
	const percentSignoShoppingFixed = percentSignoShopping.toFixed(2);
	const porcentajeTotalCulminadasFixed = porcentajeTotalCulminadas.toFixed(2);
	const percentSignoCompletedSalesFixed = percentSignoCompletedSales.toFixed(2);

	const yellow = "#F2A900";
	const blue = "#0E3175";

	const COLORS = [blue, yellow];

	const newDataSalesRelationGraph = dataGraphRelation.map((item) => {
		if (item.name === "App") {
			return {
				...item,
				styleClasses: application,
				percentSign: percentSignoApplication,
				percentSignFixed: percentSignoApplicationFixed,
			};
		} else {
			return {
				...item,
				styleClasses: directas,
				percentSign: percentSignoDirect,
				percentSignFixed: percentSignoDirectFixed,
			};
		}
	});

	return (
		<Container>
			<div className="mid_section">
				<div className="recents">
					<div className="space-between description">
						<div className="midCardsRow align-center">
							<p className="title-card title-card-defaultColor">
								{titleVentas}
							</p>
							<CustomTooltip
								subtitle={
									"Resumen de venta de servicios por los distintos canales."
								}
								iconColor="hardBlue"
								position={"bottomLeft"}
								arrow={false}
							/>
						</div>
					</div>
					<div className="sales">
						<p className="number-sales">{totalVentas} ventas</p>
						<div className="midCardsRow align-center">
							<p className={ventas?.textSales}>
								(
								{validationNumber(percentSignoSales)
									? percentSignoSalesFixed
									: percentSignoSales}{" "}
								%)
							</p>
							<span className="text-respect" style={{ marginLeft: "10px" }}>
								Respecto al día anterior
							</span>
						</div>
					</div>

					<div style={{ height: "400px" }}>
						<ResponsiveBar
							data={newDataSalesRelationGraph}
							indexBy="name"
							keys={["quantity"]}
							margin={{ top: 20, bottom: 30, left: 40, right: 40 }}
							colors={{ scheme: "category10" }}
							padding={0.8}
							borderRadius={15}
							groupMode="grouped"
							enableLabel={false}
							tooltip={(bar) => {
								return (
									<div className="bar-graph-modal">
										<div className="midCardsRow align-center">
											<p className="bar-graph-sales">{bar.data.quantity}</p>
											<p className="bar-graph-text">&nbsp;Ventas</p>
										</div>
										<div className="midCardsRow align-center">
											<p className={bar.data.styleClasses?.textCard}>
												{validationNumber(bar.data.percentSign)
													? bar.data.percentSignFixed
													: bar.data.percentSign}{" "}
												%
											</p>
											<i className={bar.data.styleClasses?.classCard}></i>
										</div>
										<span className="text-respect">
											Respecto al día anterior
										</span>
									</div>
								);
							}}
						/>
					</div>
				</div>

				<div className="container_cards">
					<div className="graph_2">
						<div className="tittle_cards">
							<p className="title-card title-card-defaultColor">
								Ventas culminadas
							</p>
							<CustomTooltip
								subtitle={"Total de ventas culminadas asociadas al operador."}
								iconColor="hardBlue"
								position={"bottom"}
								arrow={false}
							/>
						</div>
						<div
							className="description_cards"
							style={{ margin: "25px 0 10px" }}
						>
							<p className="title-card title-card-progressColor">
								Finalización del proceso de venta
							</p>
						</div>
						<Progress
							percent={
								validationNumber(porcentajeTotalCulminadas)
									? porcentajeTotalCulminadasFixed
									: porcentajeTotalCulminadas
							}
							strokeColor="#1D428A"
							size={["85%", 16]}
						/>
						<Divider />
						<div className="description_cards">
							<span className={ventasCulminadas?.textAverage}>
								{validationNumber(percentSignoCompletedSales)
									? percentSignoCompletedSalesFixed
									: percentSignoCompletedSales}{" "}
								%
							</span>
							<i className={ventasCulminadas?.classCard}></i>
							<span className="text-description-card">
								Respecto al día anterior
							</span>
						</div>
					</div>

					<div className="graph_1">
						<div className="tittle_cards">
							<p className="title-card title-card-defaultColor">
								Usuarios que han comprado
							</p>
							<CustomTooltip
								subtitle={
									"Total de usuarios que han finalizado el proceso de venta."
								}
								iconColor="hardBlue"
								position={"bottom"}
								arrow={false}
							/>
						</div>
						<div
							className="description_cards"
							style={{ margin: "25px 0 10px" }}
						>
							<p className="title-card title-card-progressColor">
								Finalización del proceso de venta
							</p>
						</div>
						<Progress
							percent={
								validationNumber(porcentajeTotalComprado)
									? porcentajeTotalCompradoFixed
									: porcentajeTotalComprado
							}
							strokeColor="#F2A900"
							size={["85%", 16]}
						/>
						<Divider />
						<div className="description_cards">
							<span className={compras?.textAverage}>
								{validationNumber(percentSignoShopping)
									? percentSignoShoppingFixed
									: percentSignoShopping}{" "}
								%
							</span>
							<i className={compras?.classCard}></i>
							<span className="text-description-card">
								Respecto al día anterior
							</span>
						</div>
					</div>
				</div>

				<div className="donut-graph">
					<div className="midCardsRow align-center">
						<span className="title-card title-card-defaultColor">
							Relación de método de agendamiento
						</span>
						<CustomTooltip
							subtitle={
								"Desglose del porcentaje del método de agendamiento de las ventas."
							}
							iconColor="hardBlue"
							position={"bottomRight"}
							arrow={false}
						/>
					</div>
					<div className="graph">
						<ResponsiveContainer width="100%" height="100%">
							<PieChart>
								<Pie
									data={pieChartData}
									cx="50%"
									cy="50%"
									labelLine={false}
									innerRadius={60}
									outerRadius={80}
									fill="#8884d8"
									dataKey="porcentaje"
								>
									{pieChartData?.map((entry, index) => (
										<Cell
											key={`cell-${index}`}
											fill={COLORS[index % COLORS.length]}
										/>
									))}
								</Pie>
							</PieChart>
						</ResponsiveContainer>
					</div>
					<div className="row space-between align-width">
						<span className="square" style={{ background: blue }}></span>
						<span className="text-items-graph">Brújula</span>
						<span style={{ fontWeight: "700" }}>
							{pieChartData?.map((item) =>
								item.nombre === "Brujula" ? item.porcentaje : null
							)}{" "}
							%
						</span>
					</div>
					<span className="row space-between align-width">
						<span className="square" style={{ background: yellow }}></span>
						<span className="text-items-graph">Manuales</span>
						<span style={{ fontWeight: "700" }}>
							{pieChartData?.map((item) =>
								item.nombre === "Manuales" ? item.porcentaje : null
							)}{" "}
							%
						</span>
					</span>
					<Divider />
					<Link to="/listadoDeVentas" className="list_sales">
						Ir a listado de ventas
					</Link>
				</div>
			</div>
		</Container>
	);
};

export default MidCards;

const Container = styled.div`
	.space-between {
		justify-content: space-between;
	}

	.align-center {
		align-items: center;
	}

	.midCardsRow {
		display: flex;
	}

	.midCardsColumn {
		display: flex;
		flex-direction: column;
	}

	.mid_section {
		width: 100%;
		min-height: 400px;
		display: flex;
		justify-content: space-between;
		margin: 1.3em 0;
	}

	.recents {
		width: 27%;
		min-height: 400px;
		background: white;
		border-radius: 3px;
		border: 1px solid #dde4eb;
		padding: 33px 25px;
	}

	.title-card {
		font-size: ${smallSizes.dashboardMid.titleCards};
		font-weight: 700;
	}

	.title-card-defaultColor {
		color: #5a607f;
	}

	.title-card-progressColor {
		color: #172b4d;
	}

	.description {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.sales {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin: 0px 0px 10px 0;
	}

	.number-sales {
		font-size: ${smallSizes.dashboardMid.numberSales};
		color: #131523;
		font-weight: 700;
	}

	.bar-graph-modal {
		display: flex;
		flex-direction: column;
		padding: 6px 12px;
		background: white;
		box-shadow: 0px 1px 8px #53559b2d;
		border-radius: 4px;
	}

	.bar-graph-sales {
		font-size: 14px;
		color: #000000;
		font-weight: bold;
	}

	.bar-graph-text {
		font-size: 10px;
		color: #000000;
	}

	.percent_sales_up {
		font-family: roboto;
		font-weight: 700;
		color: #06d186;
		font-size: ${smallSizes.dashboardMid.percentSales};
	}

	.percent_sales_equal {
		font-family: roboto;
		font-weight: 700;
		color: #2b80ff;
		font-size: ${smallSizes.dashboardMid.percentSales};
	}

	.percent_sales_down {
		font-family: roboto;
		font-weight: 700;
		color: #f0142f;
		font-size: ${smallSizes.dashboardMid.percentSales};
	}

	.text-respect {
		font-size: ${smallSizes.dashboardMid.textRespect};
		color: #7e84a3;
	}

	.percent_up {
		color: #3dd598;
		font-size: ${smallSizes.dashboardMid.percent};
	}

	.arrow_icon_up {
		color: #3dd598;
		font-size: ${smallSizes.dashboardMid.arrowIcon};
		margin: 0 14px;
	}

	.percent_equal {
		color: #2b80ff;
		font-size: ${smallSizes.dashboardMid.percent};
	}

	.arrow_icon_equal {
		color: #2b80ff;
		font-size: ${smallSizes.dashboardMid.arrowIcon};
		margin: 0 10px;
	}

	.percent_down {
		color: #f0142f;
		font-size: ${smallSizes.dashboardMid.percent};
	}

	.arrow_icon_down {
		color: #f0142f;
		font-size: ${smallSizes.dashboardMid.arrowIcon};
		margin: 0 10px;
	}

	//--------------------------------------
	.ant-progress .ant-progress-text {
		font-size: 22px;
		font-weight: bold;
	}

	.ant-progress-line {
		display: flex;
		align-items: center;
	}

	.container_cards {
		display: flex;
		flex-flow: column;
		width: 34%;
		justify-content: space-between;
	}

	.graph_1 {
		width: 100%;
		height: 48%;
		background: white;
		border-radius: 3px;
		border: 1px solid #dde4eb;
		padding: 25px 20px 35px;
	}

	.tittle_cards {
		display: flex;
		align-items: center;
	}

	.description_cards {
		display: flex;
		align-content: center;
		align-items: center;
	}

	.description_cards .percent-average-up {
		margin-right: 2%;
		font-size: ${smallSizes.dashboardMid.percentAverage};
		color: #3dd598;
	}

	.description_cards .percent-average-equal {
		margin-right: 2%;
		font-size: ${smallSizes.dashboardMid.percentAverage};
		color: #2b80ff;
	}

	.description_cards .percent-average-down {
		margin-right: 2%;
		font-size: ${smallSizes.dashboardMid.percentAverage};
		color: #f0142f;
	}

	.description_cards .text-description-card {
		font-size: ${smallSizes.dashboardMid.textDescriptionCard};
		color: #868e96;
		margin-left: 10px;
	}

	.graph_2 {
		width: 100%;
		height: 48%;
		background: white;
		border-radius: 3px;
		border: 1px solid #dde4eb;
		padding: 25px 20px 35px;
	}

	// ----------------------------------------------

	.donut-graph {
		width: 37%;
		min-height: 400px;
		background: white;
		padding: 33px 23px 25px;
		border-radius: 3px;
		border: 1px solid #dde4eb;
	}

	.graph {
		width: 100%;
		height: 69%;
	}

	.square {
		width: ${smallSizes.dashboardBottom.squareWidth};
		height: ${smallSizes.dashboardBottom.squareHeight};
	}

	.align-width {
		align-items: center;
		width: 100%;
	}

	.text-items-graph {
		font-size: ${smallSizes.dashboardBottom.textItemsGraph};
		margin-left: 20px;
		flex: 1;
		color: #131523;
	}

	.list_sales {
		color: #1458e8;
		font-size: ${smallSizes.dashboardBottom.listSales};
		width: 100%;
		cursor: pointer;
	}

	// ----------------------------------------

	@media screen and (min-width: ${devices.smallDesk}) {
		.title-card {
			font-size: ${smallSizes.dashboardMid.titleCards};
		}

		.number-sales {
			font-size: ${smallSizes.dashboardMid.numberSales};
		}

		.bar-graph-sales {
			font-size: 14px;
		}

		.bar-graph-text {
			font-size: 10px;
		}

		.percent_sales_up {
			font-size: ${smallSizes.dashboardMid.percentSales};
		}

		.percent_sales_equal {
			font-size: ${smallSizes.dashboardMid.percentSales};
		}

		.percent_sales_dow {
			font-size: ${smallSizes.dashboardMid.percentSales};
		}

		.text-respect {
			font-size: ${smallSizes.dashboardMid.textRespect};
		}

		.percent_up {
			font-size: ${smallSizes.dashboardMid.percent};
		}

		.arrow_icon_up {
			font-size: ${smallSizes.dashboardMid.arrowIcon};
		}

		.percent_equal {
			font-size: ${smallSizes.dashboardMid.percent};
		}

		.arrow_icon_equal {
			font-size: ${smallSizes.dashboardMid.arrowIcon};
		}

		.percent_down {
			font-size: ${smallSizes.dashboardMid.percent};
		}

		.arrow_icon_down {
			font-size: ${smallSizes.dashboardMid.arrowIcon};
		}

		.description_cards .percent-average-up {
			font-size: ${smallSizes.dashboardMid.percentAverage};
		}

		.description_cards .percent-average-equal {
			font-size: ${smallSizes.dashboardMid.percentAverage};
		}

		.description_cards .percent-average-down {
			font-size: ${smallSizes.dashboardMid.percentAverage};
		}

		.description_cards .text-description-card {
			font-size: ${smallSizes.dashboardMid.textDescriptionCard};
		}

		.ant-progress .ant-progress-text {
			font-size: 22px;
		}

		// -----------------------------------------

		.square {
			width: ${smallSizes.dashboardBottom.squareWidth};
			height: ${smallSizes.dashboardBottom.squareHeight};
		}

		.text-items-graph {
			font-size: ${smallSizes.dashboardBottom.textItemsGraph};
		}

		.list_sales {
			font-size: ${smallSizes.dashboardBottom.listSales};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.title-card {
			font-size: ${mediumSizes.dashboardMid.titleCards};
		}

		.number-sales {
			font-size: ${mediumSizes.dashboardMid.numberSales};
		}

		.bar-graph-sales {
			font-size: 15.75px;
		}

		.bar-graph-text {
			font-size: 11.25px;
		}

		.percent_sales_up {
			font-size: ${mediumSizes.dashboardMid.percentSales};
		}

		.percent_sales_equal {
			font-size: ${mediumSizes.dashboardMid.percentSales};
		}

		.percent_sales_dow {
			font-size: ${mediumSizes.dashboardMid.percentSales};
		}
		.text-respect {
			font-size: ${mediumSizes.dashboardMid.textRespect};
		}

		.percent_up {
			font-size: ${mediumSizes.dashboardMid.percent};
		}

		.arrow_icon_up {
			font-size: ${mediumSizes.dashboardMid.arrowIcon};
		}

		.percent_equal {
			font-size: ${mediumSizes.dashboardMid.percent};
		}

		.arrow_icon_equal {
			font-size: ${mediumSizes.dashboardMid.arrowIcon};
		}

		.percent_down {
			font-size: ${mediumSizes.dashboardMid.percent};
		}

		.arrow_icon_down {
			font-size: ${mediumSizes.dashboardMid.arrowIcon};
		}

		.description_cards .percent-average-up {
			font-size: ${mediumSizes.dashboardMid.percentAverage};
		}

		.description_cards .percent-average-equal {
			font-size: ${mediumSizes.dashboardMid.percentAverage};
		}

		.description_cards .percent-average-down {
			font-size: ${mediumSizes.dashboardMid.percentAverage};
		}

		.description_cards .text-description-card {
			font-size: ${mediumSizes.dashboardMid.textDescriptionCard};
		}

		.ant-progress .ant-progress-text {
			font-size: 24.75px;
		}

		// -----------------------------------------

		.square {
			width: ${mediumSizes.dashboardBottom.squareWidth};
			height: ${mediumSizes.dashboardBottom.squareHeight};
		}

		.text-items-graph {
			font-size: ${mediumSizes.dashboardBottom.textItemsGraph};
		}

		.list_sales {
			font-size: ${mediumSizes.dashboardBottom.listSales};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.title-card {
			font-size: ${largeSizes.dashboardMid.titleCards};
		}

		.number-sales {
			font-size: ${largeSizes.dashboardMid.numberSales};
		}

		.bar-graph-sales {
			font-size: 17.71px;
		}

		.bar-graph-text {
			font-size: 12.65px;
		}

		.percent_sales_up {
			font-size: ${largeSizes.dashboardMid.percentSales};
		}

		.percent_sales_equal {
			font-size: ${largeSizes.dashboardMid.percentSales};
		}

		.percent_sales_dow {
			font-size: ${largeSizes.dashboardMid.percentSales};
		}
		.text-respect {
			font-size: ${largeSizes.dashboardMid.textRespect};
		}

		.percent_up {
			font-size: ${largeSizes.dashboardMid.percent};
		}

		.arrow_icon_up {
			font-size: ${largeSizes.dashboardMid.arrowIcon};
		}

		.percent_equal {
			font-size: ${largeSizes.dashboardMid.percent};
		}

		.arrow_icon_equal {
			font-size: ${largeSizes.dashboardMid.arrowIcon};
		}

		.percent_down {
			font-size: ${largeSizes.dashboardMid.percent};
		}

		.arrow_icon_down {
			font-size: ${largeSizes.dashboardMid.arrowIcon};
		}

		.description_cards .percent-average-up {
			font-size: ${largeSizes.dashboardMid.percentAverage};
		}

		.description_cards .percent-average-equal {
			font-size: ${largeSizes.dashboardMid.percentAverage};
		}

		.description_cards .percent-average-down {
			font-size: ${largeSizes.dashboardMid.percentAverage};
		}

		.description_cards .text-description-card {
			font-size: ${largeSizes.dashboardMid.textDescriptionCard};
		}

		.ant-progress .ant-progress-text {
			font-size: 27.84px;
		}

		// -----------------------------------------

		.square {
			width: ${largeSizes.dashboardBottom.squareWidth};
			height: ${largeSizes.dashboardBottom.squareHeight};
		}

		.text-items-graph {
			font-size: ${largeSizes.dashboardBottom.textItemsGraph};
		}

		.list_sales {
			font-size: ${largeSizes.dashboardBottom.listSales};
		}
	}
`;
