import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Select, Space, notification, Switch } from "antd";
import styled from "styled-components";
import { myFetch, myFetchGet } from "../../../services/services";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { regex } from "../../../helpers/regex";
import { useSelector } from "react-redux";
import { FormTabsPanel } from "../../../components/tabs/FormTabs";
import { Tab, TabList, TabPanel } from "react-tabs";
import { LoadingDetail } from "../../../components/loading/Loading";
import CustomPopConfirm from "../../../components/customPopConfirm/CustomPopConfirm";
import styles from "./GeneralSalePanel.module.css";

const GeneralSalePanel = ({ data }) => {
	const dataState = useSelector((state) => state.authState);
	const token = useReduxToken();
	const [loading, setLoading] = useState(true);

	dayjs.extend(localizedFormat);

	const changeCustomerUser = dataState.permissions.includes(
		"change_customeruser"
	);

	const { id_unique, ID } = data;

	const history = useHistory();

	/*Lógica de la vista */
	const [modify, setModify] = useState(false);

	/*Customer user */
	const [originalData, setOriginalData] = useState({});
	const [customerUserID, setCustomerUserID] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [documentList, setDocumentList] = useState([]);
	const [documentType, setDocumentType] = useState();
	const [documentNumber, setDocumentNumber] = useState();
	const [email, setEmail] = useState();
	const [phoneCountry, setPhoneCountry] = useState();
	const [phone, setPhone] = useState();
	const [extraPhoneNumber, setExtraPhoneNumber] = useState();
	const [extraPhoneNumberCountry, setExtraPhoneNumberCountry] = useState();
	const [extraEmail, setExtraEmail] = useState();
	const [showExtraPhoneNumber, setShowExtraPhoneNumber] = useState(false);
	const [showExtraEmail, setShowExtraEmail] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [base64Img, setBase64Img] = useState(undefined);
	const [tempPhoto, setTempPhoto] = useState(null);
	const [salePlan, setSalePlan] = useState();
	const [operatorName, setOperatorName] = useState();
	const [saleAddress, setSaleAddress] = useState();
	const [saleAddressComplement, setSaleAddressComplement] = useState();
	const [saleStatus, setSaleStatus] = useState();
	const [schedulingDate, setSchedulingDate] = useState();

	const newPhone = phoneCountry + " " + phone;

	/*Validaciones */
	const [errors, setErrors] = useState({
		firstName: "",
		lastName: "",
		documentType: "",
		documentNumber: "",
		email: "",
		phone: "",
		extraEmail: "",
		extraPhoneNumber: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		firstName: false,
		lastName: false,
		documentType: false,
		documentNumber: false,
		email: false,
		phone: false,
		extraEmail: false,
		extraPhoneNumber: false,
	});

	const initial = async () => {
		setLoading(true);
		try {
			const data = await myFetchGet(`api/v1/sales/${id_unique}`, token);
			const address = await myFetchGet(
				`api/v1/addresses/${data.address}`,
				token
			);
			const customerUser = await myFetchGet(
				`api/v1/customer_user/${data?.customer_user}`,
				token
			);
			const customerUserFiles = await myFetchGet(
				`api/v1/customer_user_files/?customer_user=${customerUser?.ID}`,
				token
			);
			const filesFiltered = customerUserFiles.filter(
				(obj) => obj.document_method === 1 && !obj.deleted
			);
			const plan = await myFetchGet(`api/v1/plans/${data?.plan}/`, token);
			const operator = await myFetchGet(
				`api/v1/operador/${data?.operator}/`,
				token
			);
			setOriginalData(customerUser);
			setCustomerUserID(customerUser?.ID);
			setFirstName(customerUser?.first_name);
			setLastName(customerUser?.last_name);
			setDocumentType(customerUser?.document);
			setDocumentNumber(customerUser?.document_number);
			setEmail(customerUser?.email);
			if (customerUser?.email2) {
				setShowExtraEmail(true);
			}
			setExtraEmail(customerUser?.email2);
			phoneHandler(customerUser?.phone);
			if (customerUser?.phone2) {
				extraPhoneHandler(customerUser?.phone2);
				setShowExtraPhoneNumber(true);
			}
			setFileList(filesFiltered);
			setSalePlan(plan);
			setOperatorName(operator?.name);
			setSaleAddress(data?.address_name);
			setSaleAddressComplement(address?.direction_type_text);
			setSaleStatus(data?.status);
			setSchedulingDate(data?.scheduling_date);

			getDocumentTypes();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const getDocumentTypes = async () => {
		try {
			const documents = await myFetchGet("api/v1/documents/", token);
			setDocumentList(documents);
			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		initial();
	}, []);

	useEffect(() => {
		let firstNameError = "";
		let lastNameError = "";
		let documentTypeError = "";
		let documentNumberError = "";
		let emailError = "";
		let phoneError = "";
		let extraEmailError = "";
		let extraPhoneNumberError = "";

		if (!firstName) {
			firstNameError = "Ingrese su nombre";
		} else if (regex.regexNames.test(firstName) === false) {
			firstNameError = "Debe ingresar solo letras";
		}

		if (!lastName) {
			lastNameError = "Ingrese su apellido";
		} else if (regex.regexNames.test(lastName) === false) {
			lastNameError = "Debe ingresar solo letras";
		}

		if (!documentType) {
			documentTypeError = "Seleccione el tipo de documento";
		}

		if (!documentNumber) {
			documentNumberError = "Ingrese el número de documento";
		}

		if (!email) {
			emailError = "Ingrese el correo";
		} else if (regex.regexSimpleEmail.test(email) === false) {
			emailError = "Ingrese una dirección de correo valida";
		}

		if (!phone) {
			phoneError = "Ingrese el número de teléfono";
		} else if (regex.regexNumbers.test(phone) === false) {
			phoneError = "Debe ingresar solo números";
		}

		if (extraEmail && regex.regexSimpleEmail.test(extraEmail) === false) {
			extraEmailError = "Ingrese una dirección de correo valida";
		}

		if (
			extraPhoneNumber &&
			regex.regexNumbers.test(extraPhoneNumber) === false
		) {
			extraPhoneNumberError = "Debe ingresar solo números";
		}

		setErrors({
			firstName: firstNameError,
			lastName: lastNameError,
			documentType: documentTypeError,
			documentNumber: documentNumberError,
			email: emailError,
			phone: phoneError,
			extraEmail: extraEmailError,
			extraPhoneNumber: extraPhoneNumberError,
		});
	}, [
		firstName,
		lastName,
		documentType,
		documentNumber,
		email,
		phone,
		extraEmail,
		extraPhoneNumber,
		errorStatus,
	]);

	const validateInput = () => {
		if (errors.firstName) {
			setErrorStatus((prevState) => ({ ...prevState, firstName: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, firstName: false }));
		}

		if (errors.lastName) {
			setErrorStatus((prevState) => ({ ...prevState, lastName: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, lastName: false }));
		}

		if (errors.documentType) {
			setErrorStatus((prevState) => ({ ...prevState, documentType: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, documentType: false }));
		}

		if (errors.documentNumber) {
			setErrorStatus((prevState) => ({ ...prevState, documentNumber: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, documentNumber: false }));
		}

		if (errors.email) {
			setErrorStatus((prevState) => ({ ...prevState, email: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, email: false }));
		}

		if (errors.phone) {
			setErrorStatus((prevState) => ({ ...prevState, phone: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, phone: false }));
		}

		if (errors.extraEmail) {
			setErrorStatus((prevState) => ({ ...prevState, extraEmail: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, extraEmail: false }));
		}

		if (errors.extraPhoneNumber) {
			setErrorStatus((prevState) => ({ ...prevState, extraPhoneNumber: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				extraPhoneNumber: false,
			}));
		}

		setErrorStatus({
			firstName: false,
			lastName: false,
			documentType: false,
			documentNumber: false,
			email: false,
			phone: false,
			extraEmail: false,
			extraPhoneNumber: false,
		});
	};

	const onSubmit = async (data, dataCustomerUser) => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			firstName: false,
			lastName: false,
			documentType: false,
			documentNumber: false,
			email: false,
			phone: false,
			extraEmail: false,
			extraPhoneNumber: false,
		});

		if (
			extraPhoneNumberCountry === undefined &&
			extraPhoneNumber === undefined
		) {
			data.phone2 = undefined;
		} else if (
			extraPhoneNumberCountry !== undefined &&
			extraPhoneNumber !== undefined
		) {
			data.phone2 = extraPhoneNumberCountry + " " + extraPhoneNumber;
		} else if (
			extraPhoneNumberCountry !== undefined &&
			extraPhoneNumber === undefined
		) {
			data.phone2 = undefined;
		} else if (
			extraPhoneNumberCountry === undefined &&
			extraPhoneNumber !== undefined
		) {
			data.phone2 = undefined;
		}

		try {
			await myFetch(
				`api/v1/customer_user/${customerUserID}/`,
				token,
				data,
				"PATCH"
			);
			await myFetch("api/v1/customer_user_files/", token, dataCustomerUser);
			notification.success({
				className: "notification",
				message: "Información modificada exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			// history.push("/listadoDeVentas", { replace: true });
			initial();
			onModify();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const onModify = () => {
		setFirstName(originalData.first_name);
		setLastName(originalData.last_name);
		setDocumentType(originalData.document);
		setDocumentNumber(originalData.document_number);
		phoneHandler(originalData.phone);
		setEmail(originalData.email);
		if (originalData?.email2) {
			setShowExtraEmail(true);
		} else {
			setShowExtraEmail(false);
		}
		setExtraEmail(originalData.email2);
		if (originalData?.phone2) {
			extraPhoneHandler(originalData.phone2);
			setShowExtraPhoneNumber(true);
		} else {
			setShowExtraPhoneNumber(false);
			setExtraPhoneNumberCountry();
			setExtraPhoneNumber();
		}

		setModify(!modify);
		setBase64Img(undefined);
		setTempPhoto(null);
	};

	const modifyButtonDynamicStyle = {
		background: modify ? "#1D428A" : "#DDE4EB",
		color: modify ? "white" : "#131523",
		border: modify ? "none" : "1px solid #426DA9",
	};

	const dynamicColorIcon = {
		color: modify ? "white" : "#426DA9",
	};

	const selectDocumentTypeHandler = (value) => {
		setDocumentType(value);
	};

	const phoneHandler = (e) => {
		const phone = e.split(" ");
		setPhoneCountry(phone[0]);
		setPhone(phone[1]);
	};

	const extraPhoneHandler = (e) => {
		const extraPhone = e.split(" ");
		setExtraPhoneNumberCountry(extraPhone[0]);
		setExtraPhoneNumber(extraPhone[1]);
	};

	const phoneCountryHandler = (value) => {
		setPhoneCountry(value);
	};

	const extraPhoneSwitchHandler = (checked) => {
		setShowExtraPhoneNumber(checked);
		if (checked === false) {
			setExtraPhoneNumberCountry(undefined);
			setExtraPhoneNumber(undefined);
		}
	};

	const extraEmailSwitchHandler = (checked) => {
		setShowExtraEmail(checked);
		if (checked === false) {
			setExtraEmail(undefined);
		}
	};

	const extraPhoneCountryHandler = (value) => {
		setExtraPhoneNumberCountry(value);
	};

	const inputFile = useRef();

	const clearTemporalImageHandler = () => {
		setTempPhoto(null);
		setBase64Img(undefined);
	};

	const transformToBase64Handler = (archivos) => {
		setTempPhoto(archivos[0]);

		Array.from(archivos).forEach((archivo) => {
			const reader = new FileReader();
			reader.readAsDataURL(archivo);

			reader.onload = function () {
				let arraySplitted = [];
				const base64 = reader.result;

				arraySplitted = base64.split(",");
				setBase64Img(arraySplitted[1]);
			};
		});
	};

	return (
		<Container>
			<div className={styles["section-container"]}>
				{loading ? (
					<LoadingDetail buttons={1} direction="right" />
				) : (
					<>
						<div className={`${styles["row"]} ${styles["justify-end"]}`}>
							{changeCustomerUser && (
								<Button
									style={modifyButtonDynamicStyle}
									className={styles["button-modify"]}
									onClick={onModify}
									icon={<FormOutlined style={dynamicColorIcon} />}
								>
									Modificar
								</Button>
							)}
						</div>

						<div className={styles["row"]}>
							<div
								className={`${styles["column"]} ${styles["flex"]} ${styles["column-left"]}`}
							>
								<div className={styles["row"]}>
									<span className={styles["form-section-title"]}>
										Información de la venta
									</span>
								</div>

								<div className={styles["row"]}>
									<span className={styles["installation-date"]}>
										Fecha de instalación:{" "}
										<b>
											{dayjs(schedulingDate, "DD-MM-YYYY")
												.locale("es")
												.format("LL")}
										</b>
									</span>
								</div>

								<br />

								<div className={styles["row"]} style={{ columnGap: "2%" }}>
									<div
										className={`${styles["column"]} ${styles["align-center"]} ${styles["justify-center"]} ${styles["sale-status"]}`}
										style={{ width: "40%" }}
									>
										{saleStatus === 1 ? (
											<span className={styles["sale-status-text"]}>
												Sin atender
											</span>
										) : saleStatus === 2 ? (
											<span className={styles["sale-status-text"]}>
												Agendada
											</span>
										) : saleStatus === 3 ? (
											<span className={styles["sale-status-text"]}>
												Instalada
											</span>
										) : saleStatus === 4 ? (
											<span className={styles["sale-status-text"]}>
												Cancelada
											</span>
										) : (
											saleStatus === 5 && (
												<span className={styles["sale-status-text"]}>
													En instalación
												</span>
											)
										)}
									</div>
									<div
										className={`${styles["column"]} ${styles["justify-center"]}`}
									>
										<span className={styles["sale-info-title"]}>Operador</span>
										<span className={styles["sale-info-text"]}>
											{operatorName}
										</span>
									</div>
									<div
										className={`${styles["column"]} ${styles["justify-center"]}`}
									>
										<span className={styles["sale-info-title"]}>
											ID de la venta
										</span>
										<span className={styles["sale-info-text"]}>{ID}</span>
									</div>
								</div>

								<br />

								<div className={styles["row"]}>
									<div className={styles["column"]}>
										<span className={styles["sale-address-title"]}>
											Dirección
										</span>
										<span className={styles["sale-address"]}>
											{saleAddress}
										</span>
									</div>
								</div>

								{saleAddressComplement && (
									<>
										<br />
										<div className={styles["row"]}>
											<div className={styles["column"]}>
												<span className={styles["sale-address-title"]}>
													Complemento de dirección
												</span>
												<span className={styles["sale-address"]}>
													{saleAddressComplement}
												</span>
											</div>
										</div>
									</>
								)}

								<br />

								<div className={styles["row"]}>
									<span className={styles["form-section-title"]}>
										Detalle del plan
									</span>
								</div>

								<div className={styles["row"]}>
									{salePlan !== undefined &&
									salePlan.category.toString() === "1" ? (
										<FormTabsPanel selectedTabClassName="is-selected">
											<TabList
												className="TabList"
												style={{
													padding: "0",
													marginBottom: "0.5em",
												}}
											>
												<Tab className="FormTab">Internet</Tab>
											</TabList>

											<TabPanel>
												<i
													className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
												></i>
												<span className={styles["internet-characteristics"]}>
													{salePlan.megas_upload} mb
												</span>
												<i
													className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
												></i>
												<span className={styles["internet-characteristics"]}>
													{salePlan.megas_download} mb
												</span>
											</TabPanel>
										</FormTabsPanel>
									) : salePlan !== undefined &&
									  salePlan.category.toString() === "2" ? (
										<FormTabsPanel selectedTabClassName="is-selected">
											<TabList className="FormTabsList">
												<Tab className="FormTab">Televisión</Tab>
											</TabList>

											<TabPanel>
												{salePlan.options_channels === 2 ? (
													<span className={styles["sd-channels"]}>
														Canales {salePlan.number_channels} <b>SD</b>
													</span>
												) : salePlan.options_channels === 1 ? (
													<div>
														<span className={styles["sd-channels"]}>
															Canales {salePlan.number_channels} <b>SD</b>
														</span>
														<span className={styles["hd-channels"]}>
															{salePlan.extra_channels_hd} <b>HD</b>
														</span>
													</div>
												) : null}
											</TabPanel>
										</FormTabsPanel>
									) : salePlan !== undefined &&
									  salePlan.category.toString() === "3" ? (
										<FormTabsPanel selectedTabClassName="is-selected">
											<TabList className="FormTabsList">
												<Tab className="FormTab">Telefonía</Tab>
											</TabList>

											<TabPanel>
												{salePlan.unlimited_minutes === true ? (
													<span className={styles["telephone"]}>
														Minutos ilimitados
													</span>
												) : (
													<span className={styles["telephone"]}>
														{salePlan.phone_minutes} min llamadas locales
													</span>
												)}
											</TabPanel>
										</FormTabsPanel>
									) : salePlan !== undefined &&
									  salePlan.category.toString() === "1,2" ? (
										<FormTabsPanel selectedTabClassName="is-selected">
											<TabList className="FormTabsList">
												<Tab className="FormTab">Internet</Tab>
												<Tab className="FormTab">Televisión</Tab>
											</TabList>

											<TabPanel>
												<i
													className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
												></i>
												<span className={styles["internet-characteristics"]}>
													{salePlan.megas_upload} mb
												</span>
												<i
													className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
												></i>
												<span className={styles["internet-characteristics"]}>
													{salePlan.megas_download} mb
												</span>
											</TabPanel>
											<TabPanel>
												{salePlan.options_channels === 2 ? (
													<span className={styles["sd-channels"]}>
														Canales {salePlan.number_channels} <b>SD</b>
													</span>
												) : salePlan.options_channels === 1 ? (
													<div>
														<span className={styles["sd-channels"]}>
															Canales {salePlan.number_channels} <b>SD</b>
														</span>
														<span className={styles["hd-channels"]}>
															{salePlan.extra_channels_hd} <b>HD</b>
														</span>
													</div>
												) : null}
											</TabPanel>
										</FormTabsPanel>
									) : salePlan !== undefined &&
									  salePlan.category.toString() === "1,3" ? (
										<FormTabsPanel selectedTabClassName="is-selected">
											<TabList className="FormTabsList">
												<Tab className="FormTab">Internet</Tab>
												<Tab className="FormTab">Telefonía</Tab>
											</TabList>

											<TabPanel>
												<i
													className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
												></i>
												<span className={styles["internet-characteristics"]}>
													{salePlan.megas_upload} mb
												</span>
												<i
													className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
												></i>
												<span className={styles["internet-characteristics"]}>
													{salePlan.megas_download} mb
												</span>
											</TabPanel>
											<TabPanel>
												{salePlan.unlimited_minutes === true ? (
													<span className={styles["telephone"]}>
														Minutos ilimitados
													</span>
												) : (
													<span className={styles["telephone"]}>
														{salePlan.phone_minutes} min llamadas locales
													</span>
												)}
											</TabPanel>
										</FormTabsPanel>
									) : salePlan !== undefined &&
									  salePlan.category.toString() === "2,3" ? (
										<FormTabsPanel selectedTabClassName="is-selected">
											<TabList className="FormTabsList">
												<Tab className="FormTab">Televisión</Tab>
												<Tab className="FormTab">Telefonía</Tab>
											</TabList>

											<TabPanel>
												{salePlan.options_channels === 2 ? (
													<span className={styles["sd-channels"]}>
														Canales {salePlan.number_channels} <b>SD</b>
													</span>
												) : salePlan.options_channels === 1 ? (
													<div>
														<span className={styles["sd-channels"]}>
															Canales {salePlan.number_channels} <b>SD</b>
														</span>
														<span className={styles["hd-channels"]}>
															{salePlan.extra_channels_hd} <b>HD</b>
														</span>
													</div>
												) : null}
											</TabPanel>
											<TabPanel>
												{salePlan.unlimited_minutes === true ? (
													<span className={styles["telephone"]}>
														Minutos ilimitados
													</span>
												) : (
													<span className={styles["telephone"]}>
														{salePlan.phone_minutes} min llamadas locales
													</span>
												)}
											</TabPanel>
										</FormTabsPanel>
									) : salePlan !== undefined &&
									  salePlan.category.toString() === "1,2,3" ? (
										<FormTabsPanel selectedTabClassName="is-selected">
											<TabList className="FormTabsList">
												<Tab className="FormTab">Internet</Tab>
												<Tab className="FormTab">Televisión</Tab>
												<Tab className="FormTab">Telefonía</Tab>
											</TabList>

											<TabPanel>
												<i
													className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
												></i>
												<span className={styles["internet-characteristics"]}>
													{salePlan.megas_upload} mb
												</span>
												<i
													className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
												></i>
												<span className={styles["internet-characteristics"]}>
													{salePlan.megas_download} mb
												</span>
											</TabPanel>
											<TabPanel>
												{salePlan.options_channels === 2 ? (
													<span className={styles["sd-channels"]}>
														Canales {salePlan.number_channels} <b>SD</b>
													</span>
												) : salePlan.options_channels === 1 ? (
													<div>
														<span className={styles["sd-channels"]}>
															Canales {salePlan.number_channels} <b>SD</b>
														</span>
														<span className={styles["hd-channels"]}>
															{salePlan.extra_channels_hd} <b>HD</b>
														</span>
													</div>
												) : null}
											</TabPanel>
											<TabPanel>
												{salePlan.unlimited_minutes === true ? (
													<span className={styles["telephone"]}>
														Minutos ilimitados
													</span>
												) : (
													<span className={styles["telephone"]}>
														{salePlan.phone_minutes} min llamadas locales
													</span>
												)}
											</TabPanel>
										</FormTabsPanel>
									) : null}
								</div>
							</div>
							<div className={`${styles["column"]} ${styles["column-right"]}`}>
								<div className={styles["row"]}>
									<span className={styles["form-section-title"]}>
										Datos personales
									</span>
								</div>

								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Nombre <span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											style={{
												width: "95%",
											}}
											disabled={modify ? false : true}
											value={firstName}
											onChange={(e) => setFirstName(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.firstName ? "error" : ""}
										/>
										{errorStatus.firstName && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.firstName}
												</span>
											</div>
										)}
									</div>

									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Apellido <span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											disabled={modify ? false : true}
											value={lastName}
											onChange={(e) => setLastName(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.lastName ? "error" : ""}
										/>
										{errorStatus.lastName && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.lastName}
												</span>
											</div>
										)}
									</div>
								</div>

								<br />
								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Documento de identidad{" "}
											<span className={styles["blue"]}>*</span>
										</span>
										<div className={styles["row"]}>
											<div
												className={styles["column"]}
												style={{ width: "40%", marginRight: "5%" }}
											>
												<Select
													className={styles["form-input"]}
													disabled={modify ? false : true}
													style={{
														backgroundColor: modify ? "white" : "#ececec",
													}}
													dropdownStyle={{ borderRadius: 7 }}
													bordered={false}
													value={documentType}
													onChange={selectDocumentTypeHandler}
													onBlur={validateInput}
													status={errorStatus.documentType ? "error" : ""}
												>
													{documentList.map((document) => (
														<Select.Option
															key={document.ID}
															value={document.ID}
														>
															{document.name}
														</Select.Option>
													))}
												</Select>
												{errorStatus.documentType && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.documentType}
														</span>
													</div>
												)}
											</div>
											<div
												className={styles["column"]}
												style={{ width: "50%" }}
											>
												<Input
													className={styles["form-input"]}
													disabled={modify ? false : true}
													value={documentNumber}
													onChange={(e) => setDocumentNumber(e?.target?.value)}
													onBlur={validateInput}
													status={errorStatus.documentNumber ? "error" : ""}
												/>
												{errorStatus.documentNumber && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.documentNumber}
														</span>
													</div>
												)}
											</div>
										</div>
									</div>

									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Número de teléfono{" "}
											<span className={styles["blue"]}>*</span>
										</span>
										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											<Select
												className={styles["form-input"]}
												disabled={modify ? false : true}
												style={{
													backgroundColor: modify ? "white" : "#ececec",
													width: "30%",
												}}
												bordered={false}
												value={phoneCountry}
												onChange={phoneCountryHandler}
											>
												<Select.Option key={1} value={"+56"}>
													<img
														src={`https://flagcdn.com/28x21/cl.png`}
														width="20"
														height="15"
														alt="Chile"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+56
												</Select.Option>
												<Select.Option key={2} value={"+58"}>
													<img
														src={`https://flagcdn.com/28x21/ve.png`}
														width="20"
														height="15"
														alt="Venezuela"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+58
												</Select.Option>
												<Select.Option key={3} value={"+55"}>
													<img
														src={`https://flagcdn.com/28x21/br.png`}
														width="20"
														height="15"
														alt="Brasil"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+55
												</Select.Option>
												<Select.Option key={4} value={"+51"}>
													<img
														src={`https://flagcdn.com/28x21/pe.png`}
														width="20"
														height="15"
														alt="Perú"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+51
												</Select.Option>
											</Select>
											<div
												className={styles["column"]}
												style={{ width: "65%" }}
											>
												<Input
													className={styles["form-input"]}
													disabled={modify ? false : true}
													value={phone}
													onChange={(e) => setPhone(e?.target?.value)}
													onBlur={validateInput}
													status={errorStatus.phone ? "error" : ""}
												/>
												{errorStatus.phone && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.phone}
														</span>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>

								<br />
								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Correo electrónico{" "}
											<span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											disabled={modify ? false : true}
											value={email}
											onChange={(e) => setEmail(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.email ? "error" : ""}
										/>
										{errorStatus.email && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.email}
												</span>
											</div>
										)}
									</div>
								</div>

								<br />
								<br />

								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["switch-text"]}>
											<b>Número de teléfono secundario</b>
										</span>
									</div>
									<div
										className={`${styles["column"]} ${styles["flex"]} ${styles["justify-center"]}`}
									>
										<div
											className={`${styles["row"]} ${styles["align-center"]} ${styles["justify-center"]}`}
										>
											<span className={styles["switch-text"]}>No</span>
											<Switch
												className={styles["switch-margin"]}
												checked={showExtraPhoneNumber}
												disabled={modify ? false : true}
												onChange={extraPhoneSwitchHandler}
											/>
											<span className={styles["switch-text"]}>Sí</span>
										</div>
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["switch-text"]}>
											<b>Correo electrónico secundario</b>
										</span>
									</div>
									<div
										className={`${styles["column"]} ${styles["flex"]} ${styles["justify-center"]}`}
									>
										<div
											className={`${styles["row"]} ${styles["align-center"]} ${styles["justify-center"]}`}
										>
											<span className={styles["switch-text"]}>No</span>
											<Switch
												className={styles["switch-margin"]}
												checked={showExtraEmail}
												disabled={modify ? false : true}
												onChange={extraEmailSwitchHandler}
											/>
											<span className={styles["switch-text"]}>Sí</span>
										</div>
									</div>
								</div>

								{showExtraPhoneNumber === true || showExtraEmail === true ? (
									<div>
										<br />
										<div className={styles["row"]}>
											<div className={`${styles["column"]} ${styles["flex"]}`}>
												{showExtraPhoneNumber && (
													<div className={styles["row"]}>
														<Select
															className={styles["form-input"]}
															disabled={modify ? false : true}
															style={{
																width: "35%",
																marginRight: "5%",
																backgroundColor: modify ? "white" : "#ececec",
															}}
															value={extraPhoneNumberCountry}
															onChange={extraPhoneCountryHandler}
															bordered={false}
															placeholder="Seleccione"
														>
															<Select.Option value={"+56"}>
																<img
																	src={`https://flagcdn.com/28x21/cl.png`}
																	width="20"
																	height="15"
																	alt="Chile"
																	style={{ margin: "0 5px 5px 0" }}
																/>
																+56
															</Select.Option>
															<Select.Option value={"+58"}>
																<img
																	src={`https://flagcdn.com/28x21/ve.png`}
																	width="20"
																	height="15"
																	alt="Venezuela"
																	style={{ margin: "0 5px 5px 0" }}
																/>
																+58
															</Select.Option>
															<Select.Option value={"+55"}>
																<img
																	src={`https://flagcdn.com/28x21/br.png`}
																	width="20"
																	height="15"
																	alt="Brasil"
																	style={{ margin: "0 5px 5px 0" }}
																/>
																+55
															</Select.Option>
															<Select.Option value={"+51"}>
																<img
																	src={`https://flagcdn.com/28x21/pe.png`}
																	width="20"
																	height="15"
																	alt="Perú"
																	style={{ margin: "0 5px 5px 0" }}
																/>
																+51
															</Select.Option>
														</Select>
														<div
															className={styles["column"]}
															style={{ width: "55%" }}
														>
															<Input
																className={styles["form-input"]}
																disabled={modify ? false : true}
																placeholder="505-XXXXXX"
																value={extraPhoneNumber}
																onChange={(e) =>
																	setExtraPhoneNumber(e.target.value)
																}
																onBlur={validateInput}
																status={
																	errorStatus.extraPhoneNumber ? "error" : ""
																}
															/>
															{errorStatus.extraPhoneNumber && (
																<div>
																	<span
																		className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
																	>
																		{errors.extraPhoneNumber}
																	</span>
																</div>
															)}
														</div>
													</div>
												)}
											</div>
											<div className={`${styles["column"]} ${styles["flex"]}`}>
												{showExtraEmail && (
													<>
														<Input
															className={styles["form-input"]}
															disabled={modify ? false : true}
															placeholder="Exaample@gmail.com"
															onChange={(e) => setExtraEmail(e.target.value)}
															onBlur={validateInput}
															status={errorStatus.extraEmail ? "error" : ""}
															value={extraEmail}
														/>
														{errorStatus.extraEmail && (
															<div>
																<span
																	className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
																>
																	{errors.extraEmail}
																</span>
															</div>
														)}
													</>
												)}
											</div>
										</div>
									</div>
								) : null}

								<br />
								<br />

								{base64Img === undefined && modify ? (
									<button
										className={styles["upload-image-box"]}
										onClick={() => inputFile.current.click()}
									>
										<i
											className={`fa-solid fa-upload ${styles["upload-image-icon"]}`}
										></i>
										<span className={styles["image-title"]}>
											<b>Documento de identidad</b>
										</span>
										<span className={styles["image-text"]}>
											Soporte para una carga única. Prohibir estrictamente
											cargar datos de la empresa u otros archivos de bandas
										</span>
										<span className={styles["image-required-text"]}>
											Imagen requerida (Max: 2)
										</span>
										<input
											type="file"
											ref={inputFile}
											accept="image/png, image/jpeg"
											onChange={(e) => transformToBase64Handler(e.target.files)}
											style={{ display: "none" }}
										/>
									</button>
								) : base64Img !== undefined && modify ? (
									<div className={styles["column"]}>
										<img
											className={`${styles["temporal-image"]} animate__animated animate__fadeIn`}
											src={`data:image/png;base64,${base64Img}`}
											alt="Imagen seleccionada"
										/>
										<br />

										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											<p
												style={{ color: "grey" }}
												className="animate__animated animate__fadeIn"
											>
												{tempPhoto?.name}
											</p>
											<DeleteOutlined
												className={styles["clear-temporal-image-icon"]}
												onClick={clearTemporalImageHandler}
											/>
										</div>
									</div>
								) : null}

								<br />

								{fileList.map((file) => (
									<div key={file.ID}>
										<div className={styles["file-container"]}>
											<div
												className={`${styles["row"]} ${styles["space-between"]}`}
											>
												<div
													className={`${styles["row"]} ${styles["align-center"]}`}
												>
													<img
														src={file.img}
														className={styles["file-image"]}
													/>
													<span
														className={styles["file-name"]}
														style={{ flex: 1 }}
													>
														{file.img.slice(-16)}
													</span>
												</div>
												<div
													className={`${styles["row"]} ${styles["align-center"]}`}
												>
													<div
														className={`${styles["row"]} ${styles["justify-center"]} ${styles["align-center"]} ${styles["tag-container"]}`}
													>
														<span className={styles["tag-text"]}>
															Documento de identidad
														</span>
													</div>
												</div>
											</div>
										</div>

										<br />
									</div>
								))}
							</div>
						</div>

						<br />

						<div
							className={`${styles["row"]} ${styles["justify-end"]} ${styles["action-buttons-margin"]}`}
						>
							<Space>
								{modify && (
									<Space>
										<Button
											type="default"
											className={`${styles["action-button"]} ${styles["action-button-cancel"]}`}
											onClick={onModify}
										>
											Cancelar
										</Button>
										<CustomPopConfirm
											placement="topRight"
											title="¿Modificar información?"
											description="Los cambios realizados se sincronizaran automáticamente con las demás aplicaciones."
											onConfirm={() => {
												onSubmit(
													{
														first_name: firstName,
														last_name: lastName,
														document_type: documentType,
														document_number: documentNumber,
														email: email,
														email2: extraEmail,
														phone: newPhone,
													},
													{
														description: "Documento de identidad",
														img_base64: base64Img,
														customer_user: customerUserID,
														document_method: 1,
													}
												);
											}}
											okText="Guardar"
											cancelText="Cancelar"
										>
											<Button
												type="default"
												className={`${styles["action-button"]} ${styles["action-button-save"]}`}
											>
												Guardar
											</Button>
										</CustomPopConfirm>
									</Space>
								)}
							</Space>
						</div>
					</>
				)}
			</div>
		</Container>
	);
};

export default GeneralSalePanel;

const Container = styled.div`
	.ant-select-selector {
		font-size: 12px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector {
			font-size: 15.18px;
		}
	}
`;
