import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DatePicker, Select, Button, Space, notification } from "antd";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { myFetch, myFetchGet } from "../../../services/services";
import { useHistory } from "react-router-dom";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { useSelector } from "react-redux";
import { LoadingForm } from "../../../components/loading/Loading";
import styles from "./ManualScheduling.module.css";

const ManualScheduling = ({ data }) => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);
	const [loading, setLoading] = useState(true);

	const changeSale = dataState.permissions.includes("change_sales");

	const { id_unique } = data;

	const history = useHistory();

	const today = dayjs().format("DD/MM/YYYY");

	const [dateToShow, setDateToShow] = useState();
	const [date, setDate] = useState(today);
	const [time, setTime] = useState();
	const [technicianName, setTechnicianName] = useState();
	const [prevSaleStatus, setPrevSaleStatus] = useState();
	const [saleStatus, setSaleStatus] = useState();

	const [saleHistory, setSaleHistory] = useState();

	const schedulingDate = dayjs(date + " " + time, "DD/MM/YYYY HH");

	/*Validaciones */
	const [errors, setErrors] = useState({
		dateToShow: "",
		time: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		dateToShow: false,
		time: false,
	});

	const initial = async () => {
		setLoading(true);
		try {
			const data = await myFetchGet(`api/v1/sales/${id_unique}`, token);
			schedulingDateHandler(data?.scheduling_date);
			setTechnicianName(data?.technician_name);
			setSaleStatus(data?.status);
			setPrevSaleStatus(data?.status);

			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const getSaleHistory = async () => {
		try {
			const history = await myFetchGet(
				`api/v1/sales-history/${id_unique}`,
				token
			);
			setSaleHistory(history?.changes.map((e) => e[0]));
		} catch (e) {
			console.log("error", e);
		}
	};

	useEffect(() => {
		initial();
		getSaleHistory();
	}, []);

	useEffect(() => {
		let dateToShowError = "";
		let timeError = "";

		if (!dateToShow) {
			dateToShowError = "Seleccione una fecha del servicio";
		}

		if (!time) {
			timeError = "Seleccione la hora del servicio";
		}

		setErrors({
			dateToShow: dateToShowError,
			time: timeError,
		});
	}, [dateToShow, time, errorStatus]);

	const validateInput = () => {
		if (errors.dateToShow) {
			setErrorStatus((prevState) => ({ ...prevState, dateToShow: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, dateToShow: false }));
		}

		if (errors.time) {
			setErrorStatus((prevState) => ({ ...prevState, time: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, time: false }));
		}

		setErrorStatus({
			dateToShow: false,
			time: false,
		});
	};

	const dateFormat = "DD/MM/YYYY";
	dayjs.extend(localizedFormat);
	dayjs.extend(utc);

	const schedulingDateHandler = (e) => {
		if (e === null) {
			setDateToShow(dayjs(today, "DD/MM/YYYY"));
		} else {
			setDateToShow(dayjs(e, "DD/MM/YYYY"));
			const array = e.split(" ");
			setTime(array[1]);
		}
	};

	const dateSelectHandler = (date, dateString) => {
		setDate(dateString);
		setDateToShow(date);
	};

	const scheduleTimeHandler = (value) => {
		setTime(value);
	};

	const statusChangeHandler = (value) => {
		setSaleStatus(value);
	};

	const onSubmitScheduling = async (data) => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			dateToShow: false,
			time: false,
		});

		if (data.scheduling_date) {
			data.scheduling_date = dayjs(schedulingDate).utc(true).format();
		}

		try {
			await myFetch(`api/v1/sales/${id_unique}/`, token, data, "PATCH");
			notification.success({
				className: "notification",
				message: "Agendamiento creado exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			// history.push("/listadoDeVentas", { replace: true });
			initial();
			getSaleHistory();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const onSubmitStatusChange = async (data) => {
		try {
			await myFetch(`api/v1/sales/${id_unique}/`, token, data, "PATCH");
			notification.success({
				className: "notification",
				message: "Estatus modificado exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			// history.push("/listadoDeVentas", { replace: true });
			initial();
			getSaleHistory();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	return (
		<Container>
			<div className={`${styles["row"]} ${styles["space-between"]}`}>
				<div
					className={`${styles["column"]} ${styles["justify-center"]}`}
					style={{ width: "70%" }}
				>
					<span className={styles["section-title"]}>
						Información del agendamiento
					</span>
					<br />
					<span className={styles["section-text"]}>
						Sección donde se realiza el agendamiento de la orden de servicio y
						se muestra la línea de tiempo de los cambios de estatus realizados
						en la misma.
					</span>
				</div>

				<div
					className={`${styles["column"]} ${styles["align-center"]}`}
					style={{ width: "28%" }}
				>
					{prevSaleStatus !== 1 && (
						<>
							<span className={styles["section-text"]}>
								<b>Instalación:</b>{" "}
								{dayjs(dateToShow, "DD/MM/YYYY").locale("es").format("LL")}
							</span>
							<br />
							<span className={styles["section-text"]}>
								<b>Técnico asignado:</b> {technicianName}
							</span>
						</>
					)}
				</div>
			</div>

			<br />

			{loading ? (
				<LoadingForm showButtons={false} count={1} />
			) : (
				<>
					<div className={`${styles["row"]} ${styles["space-between"]}`}>
						<span className={styles["section-title"]}>Agendamiento</span>
					</div>
					<div className={`${styles["row"]} ${styles["space-between"]}`}>
						<div className={styles["column"]} style={{ width: "49.5%" }}>
							<span className={styles["input-name"]}>
								Fecha del servicio <span className={styles["blue"]}>*</span>
							</span>
							<DatePicker
								className={styles["input"]}
								style={{
									width: "100%",
									backgroundColor:
										prevSaleStatus === 1 && changeSale === true
											? "white"
											: "#f5f5f5",
								}}
								onChange={dateSelectHandler}
								onBlur={validateInput}
								status={errorStatus.dateToShow ? "error" : ""}
								format={dateFormat}
								value={dateToShow}
								disabled={
									prevSaleStatus === 1 && changeSale === true ? false : true
								}
							/>
							{errorStatus.dateToShow && (
								<div>
									<span
										className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
									>
										{errors.dateToShow}
									</span>
								</div>
							)}
						</div>

						<div className={styles["column"]} style={{ width: "49.5%" }}>
							<span className={styles["input-name"]}>
								Horario <span className={styles["blue"]}>*</span>
							</span>
							<Select
								className={styles["input"]}
								disabled={
									prevSaleStatus === 1 && changeSale === true ? false : true
								}
								style={{
									width: "100%",
									backgroundColor:
										prevSaleStatus === 1 && changeSale === true
											? "white"
											: "#f5f5f5",
								}}
								dropdownStyle={{ borderRadius: 7 }}
								bordered={false}
								placeholder="Seleccione"
								options={[
									{ value: "00:00:00", label: "00:00 am" },
									{ value: "01:00:00", label: "01:00 am" },
									{ value: "02:00:00", label: "02:00 am" },
									{ value: "03:00:00", label: "03:00 am" },
									{ value: "04:00:00", label: "04:00 am" },
									{ value: "05:00:00", label: "05:00 am" },
									{ value: "06:00:00", label: "06:00 am" },
									{ value: "07:00:00", label: "07:00 am" },
									{ value: "08:00:00", label: "08:00 am" },
									{ value: "09:00:00", label: "09:00 am" },
									{ value: "10:00:00", label: "10:00 am" },
									{ value: "11:00:00", label: "11:00 am" },
									{ value: "12:00:00", label: "12:00 pm" },
									{ value: "13:00:00", label: "13:00 pm" },
									{ value: "14:00:00", label: "14:00 pm" },
									{ value: "15:00:00", label: "15:00 pm" },
									{ value: "16:00:00", label: "16:00 pm" },
									{ value: "17:00:00", label: "17:00 pm" },
									{ value: "18:00:00", label: "18:00 pm" },
									{ value: "19:00:00", label: "19:00 pm" },
									{ value: "20:00:00", label: "20:00 pm" },
									{ value: "21:00:00", label: "21:00 pm" },
									{ value: "22:00:00", label: "22:00 pm" },
									{ value: "23:00:00", label: "23:00 pm" },
								]}
								value={time}
								onChange={scheduleTimeHandler}
								onBlur={validateInput}
								status={errorStatus.time ? "error" : ""}
							/>
							{errorStatus.time && (
								<div>
									<span
										className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
									>
										{errors.time}
									</span>
								</div>
							)}
						</div>
					</div>

					<br />

					{changeSale && (
						<div className={`${styles["row"]} ${styles["space-between"]}`}>
							<div style={{ width: "100%" }}>
								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									{prevSaleStatus === 1 || prevSaleStatus === 2 ? (
										<span className={styles["input-name"]}>Estatus</span>
									) : null}
									{prevSaleStatus === 2 ? (
										<Button
											type="link"
											className={styles["change-status-button"]}
											onClick={() => {
												onSubmitStatusChange({
													status: saleStatus,
												});
											}}
										>
											Cambiar estatus
										</Button>
									) : null}
								</div>
								{prevSaleStatus === 1 ? (
									<div
										className={`${styles["row"]} ${styles["space-between"]}`}
									>
										<Select
											className={styles["input"]}
											style={{
												width: "100%",
											}}
											dropdownStyle={{ borderRadius: 7 }}
											bordered={false}
											options={[
												{ value: 1, label: "Sin atender" },
												{ value: 2, label: "Agendada" },
												{ value: 4, label: "Cancelada" },
											]}
											value={saleStatus}
											onChange={statusChangeHandler}
										/>
									</div>
								) : null}
								{prevSaleStatus === 2 ? (
									<div
										className={`${styles["row"]} ${styles["space-between"]}`}
									>
										<Select
											className={styles["input"]}
											style={{
												width: "100%",
											}}
											dropdownStyle={{ borderRadius: 7 }}
											bordered={false}
											options={[
												{ value: 2, label: "Agendada" },
												{ value: 3, label: "Instalada" },
												{ value: 4, label: "Cancelada" },
											]}
											value={saleStatus}
											onChange={statusChangeHandler}
										/>
									</div>
								) : null}
							</div>
						</div>
					)}

					<br />

					{prevSaleStatus === 1 && changeSale === true ? (
						<div
							className={`${styles["row"]} ${styles["justify-end"]} ${styles["action-buttons-margin"]}`}
						>
							<Space>
								<Space>
									<Button
										type="default"
										className={`${styles["action-button"]} ${styles["action-button-cancel"]}`}
									>
										Cancelar
									</Button>
									<Button
										type="default"
										className={`${styles["action-button"]} ${styles["action-button-save"]}`}
										onClick={() => {
											onSubmitScheduling({
												scheduling_date: schedulingDate,
												status: saleStatus,
											});
										}}
									>
										Guardar
									</Button>
								</Space>
							</Space>
						</div>
					) : null}

					<br />

					<div className={`${styles["column"]} ${styles["space-between"]}`}>
						{saleHistory?.map((e) => (
							<div>
								<div>
									<span
										className={`${styles["sale-history-text"]} ${styles["status-date-history"]}`}
									>
										<b>
											{dayjs(e?.date, "DD-MM-YYYY HH:mm")
												.locale("es")
												.format("DD/MM/YYYY HH:mm")}
										</b>
									</span>
									<span
										className={`${styles["sale-history-text"]} ${styles["sale-user-history"]}`}
									>
										<b>{e?.user}</b>
									</span>
									<span className={styles["status-description-history"]}>
										Modificó el estatus de la venta a{" "}
									</span>
									{e?.status === "Sin Atender" ? (
										<span
											className={`${styles["dot"]} ${styles["unattended"]}`}
										></span>
									) : null}
									{e?.status === "Agendada" ? (
										<span
											className={`${styles["dot"]} ${styles["scheduled"]}`}
										></span>
									) : null}
									{e?.status === "Instalada" ? (
										<span
											className={`${styles["dot"]} ${styles["installed"]}`}
										></span>
									) : null}
									{e?.status === "Cancelada" ? (
										<span
											className={`${styles["dot"]} ${styles["canceled"]}`}
										></span>
									) : null}
									<span className={styles["sale-history-text"]}>
										{e?.status}
									</span>
								</div>
								<br />
							</div>
						))}
					</div>
				</>
			)}
		</Container>
	);
};

export default ManualScheduling;

const Container = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;

	.ant-select-selector {
		font-size: 12px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}

	.ant-picker .ant-picker-input > input {
		font-size: 12px;
	}

	.ant-picker-clear {
		display: none !important;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 15.18px;
		}
	}
`;
