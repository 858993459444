import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { myFetchGet } from "../../../services/services";
import { Timeline, notification } from "antd";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { LoadingHistory } from "../../../components/loading/Loading";
import styles from "./Compass.module.css";

const Compass = ({ data }) => {
	const token = useReduxToken();
	const [loading, setLoading] = useState(true);

	dayjs.extend(localizedFormat);

	const { id_unique } = data;

	const [changes, setChanges] = useState([]);
	const [saleStatus, setSaleStatus] = useState();
	const [schedulingDate, setSchedulingDate] = useState();
	const [technicianName, setTechnicianName] = useState();

	const initial = async () => {
		try {
			const sale = await myFetchGet(`api/v1/sales/${id_unique}/`, token);
			const bruj_sale = await myFetchGet(
				`api/v1/brujula-history/${sale?.brujula_id}/`,
				token
			);
			setChanges(bruj_sale?.changes.map((e) => e[0]));
			setSaleStatus(sale?.status);
			setSchedulingDate(sale?.scheduling_date);
			setTechnicianName(sale?.technician_name);

			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		initial();
	}, []);

	return (
		<div className={styles["compass-container"]}>
			<div className={`${styles["row"]} ${styles["space-between"]}`}>
				<div
					className={`${styles["column"]} ${styles["justify-center"]}`}
					style={{ width: "70%" }}
				>
					<span className={styles["section-title"]}>
						Información del agendamiento
					</span>
					<br />
					<span className={styles["title"]}>
						Linea de tiempo de los cambios de status de la orden de servicio.
					</span>
				</div>

				<div
					className={`${styles["column"]} ${styles["align-center"]}`}
					style={{ width: "28%" }}
				>
					{saleStatus !== 1 ? (
						<span className={styles["title"]}>
							<b>Instalación:</b>{" "}
							{dayjs(schedulingDate, "DD-MM-YYYY").locale("es").format("LL")}
						</span>
					) : null}
					<br />
					{saleStatus !== 1 ? (
						<span className={styles["title"]}>
							<b>Técnico asignado:</b> {technicianName}
						</span>
					) : null}
				</div>
			</div>

			<br />
			<br />

			{loading ? (
				<LoadingHistory />
			) : (
				<Timeline mode="left">
					{changes.map((e) => (
						<Timeline.Item label={e?.date}>{e?.field}</Timeline.Item>
					))}
				</Timeline>
			)}
		</div>
	);
};

export default Compass;
