import React from "react";
import { Popconfirm } from "antd";
import styled from "styled-components";

const CustomPopConfirm = ({
	placement,
	icon = "",
	title,
	description,
	onConfirm,
	okText,
	cancelText,
	children,
}) => {
	return (
		<PopConfirmContainer>
			<Popconfirm
				placement={placement}
				icon={icon}
				title={title}
				description={description}
				onConfirm={onConfirm}
				okText={okText}
				cancelText={cancelText}
			>
				{children}
			</Popconfirm>
		</PopConfirmContainer>
	);
};

export default CustomPopConfirm;

const PopConfirmContainer = styled.div`
	.ant-btn-primary {
		color: #000 !important;
		background-color: #dde4eb !important;
		box-shadow: 0 2px 0 rgb(5 145 255 / 10%) !important;
	}

	.ant-popover .ant-popover-inner {
		background-color: #172b4d;
		width: 220px;
	}

	.ant-popover .ant-popover-arrow:before {
		background-color: #172b4d;
	}

	.ant-popconfirm-message {
		color: white;
	}

	.ant-popconfirm .ant-popconfirm-message-title {
		color: white;
		font-size: 12px;
		margin-inline-start: 9px;
		font-weight: bold;
		font-family: lato;
	}

	.ant-popconfirm .ant-popconfirm-description {
		font-size: 11px;
		margin: 0 12px 12px 12px;
		color: white;
		font-family: lato;
	}

	.ant-popconfirm .ant-popconfirm-buttons button {
		background: none;
		color: white;
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-popover .ant-popover-inner {
			width: 220px;
		}

		.ant-popconfirm .ant-popconfirm-message-title {
			font-size: 12px;
		}

		.ant-popconfirm .ant-popconfirm-description {
			font-size: 11px;
		}

		.ant-popconfirm .ant-popconfirm-buttons button {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-popover .ant-popover-inner {
			width: 247.5px;
		}

		.ant-popconfirm .ant-popconfirm-message-title {
			font-size: 13.5px;
		}

		.ant-popconfirm .ant-popconfirm-description {
			font-size: 12.37px;
		}

		.ant-popconfirm .ant-popconfirm-buttons button {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-popover .ant-popover-inner {
			width: 278.43px;
		}

		.ant-popconfirm .ant-popconfirm-message-title {
			font-size: 15.18px;
		}

		.ant-popconfirm .ant-popconfirm-description {
			font-size: 13.9px;
		}

		.ant-popconfirm .ant-popconfirm-buttons button {
			font-size: 15.18px;
		}
	}
`;
