import React, { useState, useEffect, useRef } from "react";
import {
	Button,
	Input,
	Select,
	Switch,
	Space,
	notification,
	Divider,
	Tooltip,
} from "antd";
import styled from "styled-components";
import { myFetchGet, myFetch } from "../../../services/services";
import {
	FormOutlined,
	DeleteFilled,
	DeleteOutlined,
	CheckCircleFilled,
} from "@ant-design/icons";
import CustomModal from "../../../components/modal/CustomModal";
import AuthenticationGoggle from "../../../components/authenticationGoggle/AuthenticationGoggle";
import { useSelector } from "react-redux";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { regex } from "../../../helpers/regex";
import { LoadingDetail } from "../../../components/loading/Loading";
import styles from "./GeneralOperatorPanel.module.css";
import CustomPopConfirm from "../../../components/customPopConfirm/CustomPopConfirm";

const GeneralOperatorPanel = ({ data }) => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);
	const [loading, setLoading] = useState(true);

	const changeOperator = dataState.permissions.includes("change_operator");

	const { ID } = data;

	const { TextArea } = Input;

	/*Lógica de la vista */
	const [modify, setModify] = useState(false);
	const [hasLogo, setHasLogo] = useState(false);
	const [countryList, setCountryList] = useState([]);
	const [currencyList, setCurrencyList] = useState([]);
	const [technologyList, setTechnologyList] = useState([]);

	/*Datos del operador */
	const [originalData, setOriginalData] = useState({});
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [country, setCountry] = useState();
	const [currency, setCurrency] = useState([]);
	const [technology, setTechnology] = useState([]);
	const [webSite, setWebSite] = useState();
	const [facebook, setFacebook] = useState();
	const [instagram, setInstagram] = useState();
	const [twitter, setTwitter] = useState();
	const [additionalInformation, setAdditionalInformation] = useState();
	const [coverage, setCoverage] = useState();
	const [status, setStatus] = useState();
	const [logo, setLogo] = useState();
	const [logoBase64, setLogoBase64] = useState(undefined);
	const [logoName, setLogoName] = useState();
	const [temporalImage, setTemporalImage] = useState(null);
	const [isActive, setIsActive] = useState(false);

	const newSocialNetworks = [];

	newSocialNetworks.push(facebook, instagram, twitter);

	/*Datos de contacto */
	const [showAddContactForm, setShowAddContactForm] = useState(false);
	const [contactName, setContactName] = useState();
	const [contactEmail, setContactEmail] = useState();
	const [contactPhoneCountry, setContactPhoneCountry] = useState("+56");
	const [contactPhone, setContactPhone] = useState();
	const [contactCharge, setContactCharge] = useState();
	const [contacts, setContacts] = useState([]);

	const newContactPhone = contactPhoneCountry + " " + contactPhone;

	/*Datos de facturación */
	const [billingBusinessName, setBillingBusinessName] = useState();
	const [billingDocumentList, setBillingDocumentList] = useState([]);
	const [billingDocumentType, setBillingDocumentType] = useState();
	const [billingDocumentNumber, setBillingDocumentNumber] = useState();
	const [billingPhoneCountry, setBillingPhoneCountry] = useState();
	const [billingPhone, setBillingPhone] = useState();
	const [billingAddress, setBillingAddress] = useState();
	const [isVisibleForm, setIsVisibleForm] = useState(false);

	const newBillingPhone = billingPhoneCountry + " " + billingPhone;

	/*Validaciones */
	const [errors, setErrors] = useState({
		name: "",
		email: "",
		country: "",
		currency: "",
		technology: "",
		webSite: "",
		billingBusinessName: "",
		billingDocumentType: "",
		billingDocumentNumber: "",
		billingPhone: "",
		billingAddress: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		name: false,
		email: false,
		country: false,
		currency: false,
		technology: false,
		webSite: false,
		billingBusinessName: false,
		billingDocumentType: false,
		billingDocumentNumber: false,
		billingPhone: false,
		billingAddress: false,
	});
	const [addContactErrors, setAddContactErrors] = useState({
		contactName: "",
		contactEmail: "",
		contactPhone: "",
		contactCharge: "",
	});
	const [addContactErrorStatus, setAddContactErrorStatus] = useState({
		contactName: false,
		contactEmail: false,
		contactPhone: false,
		contactCharge: false,
	});

	const initial = async () => {
		setLoading(true);
		try {
			const operatorData = await myFetchGet(`api/v1/operador/${ID}`, token);
			const operatorBillingData = await myFetchGet(
				`api/v1/operator-billing-data/?operator=${ID}`,
				token
			);
			setOriginalData({
				operatorData,
				operatorBillingData: operatorBillingData[0],
			});
			/*Datos del operador */
			setName(operatorData?.name);
			setEmail(operatorData?.email);
			setCurrency([operatorData?.currency[0]]);
			setCountry(operatorData?.country);
			setTechnology(operatorData?.technology);
			setWebSite(operatorData?.web_site);
			operatorSocialNetworksHandler(operatorData?.social_networks);
			setAdditionalInformation(operatorData?.additional_information);
			setCoverage(operatorData?.coverage);
			setStatus(operatorData?.status);
			if (operatorData.logo !== undefined || null) {
				setLogo(operatorData?.logo);
				setLogoName(operatorData?.logo.slice(-16));
				setHasLogo(true);
			} else {
				setHasLogo(false);
			}
			setIsActive(operatorData?.is_active);
			/*Datos de facturación */
			setBillingBusinessName(operatorBillingData[0]?.business_name);
			setBillingDocumentType(operatorBillingData[0]?.document_type);
			setBillingDocumentNumber(operatorBillingData[0]?.document_number);
			billingPhoneHandler(operatorBillingData[0]?.phone);
			setBillingAddress(operatorBillingData[0]?.address);

			getDetailData();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const getDetailData = async () => {
		try {
			const countries = await myFetchGet("api/v1/country/", token);
			setCountryList(countries);

			const currency = await myFetchGet("api/v1/currency/", token);
			setCurrencyList(currency);

			const technologies = await myFetchGet("api/v1/technology/", token);
			setTechnologyList(technologies);

			const documents = await myFetchGet("api/v1/documents/", token);
			setBillingDocumentList(documents);

			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const getOperatorContacts = async () => {
		try {
			const operatorContactData = await myFetchGet(
				`api/v1/operator-contact/?operator=${ID}`,
				token
			);
			const responseFilter = operatorContactData.filter((obj) => !obj.deleted);
			setContacts(responseFilter);
		} catch (e) {
			console.log("error", e);
		}
	};

	useEffect(() => {
		initial();
		getOperatorContacts();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let nameError = "";
		let emailError = "";
		let countryError = "";
		let currencyError = "";
		let technologyError = "";
		let webSiteError = "";
		let billingBusinessNameError = "";
		let billingDocumentTypeError = "";
		let billingDocumentNumberError = "";
		let billingPhoneError = "";
		let billingAddressError = "";

		if (!name) {
			nameError = "Ingrese el nombre del operador";
		} else if (regex.regexNames.test(name) === false) {
			nameError = "Debe ingresar solo letras";
		}

		if (!email) {
			emailError = "Ingrese una dirección de correo";
		} else if (regex.regexSimpleEmail.test(email) === false) {
			emailError = "Ingrese una dirección de correo valida";
		}

		if (!country) {
			countryError = "Debe seleccionar un país";
		}

		if (currency.length === 0) {
			currencyError = "Debe seleccionar una moneda";
		}

		if (technology.length === 0) {
			technologyError = "Debe seleccionar una tecnología";
		}

		if (!webSite) {
			webSiteError = "Ingrese un sitio web";
		} else if (regex.regexWebsite.test(webSite) === false) {
			webSiteError = "Debe ingresar una URL valida";
		}

		if (!billingBusinessName) {
			billingBusinessNameError = "Ingrese la razón social del operador";
		} else if (regex.regexNames.test(billingBusinessName) === false) {
			billingBusinessNameError = "Debe ingresar solo letras";
		}

		if (!billingDocumentType) {
			billingDocumentTypeError = "Seleccione el tipo de documento";
		}

		if (!billingDocumentNumber) {
			billingDocumentNumberError = "Ingrese el número de documento";
		}

		if (!billingPhone) {
			billingPhoneError = "Ingrese un número de teléfono";
		} else if (regex.regexNumbers.test(billingPhone) === false) {
			billingPhoneError = "Debe ingresar solo números";
		}

		if (!billingAddress) {
			billingAddressError = "Ingrese una dirección";
		}

		setErrors({
			name: nameError,
			email: emailError,
			country: countryError,
			currency: currencyError,
			technology: technologyError,
			webSite: webSiteError,
			billingBusinessName: billingBusinessNameError,
			billingDocumentType: billingDocumentTypeError,
			billingDocumentNumber: billingDocumentNumberError,
			billingPhone: billingPhoneError,
			billingAddress: billingAddressError,
		});
	}, [
		name,
		email,
		country,
		currency,
		technology,
		webSite,
		billingBusinessName,
		billingDocumentType,
		billingDocumentNumber,
		billingPhone,
		billingAddress,
		errorStatus,
	]);

	const validateInput = () => {
		if (errors.name) {
			setErrorStatus((prevState) => ({ ...prevState, name: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, name: false }));
		}

		if (errors.email) {
			setErrorStatus((prevState) => ({ ...prevState, email: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, email: false }));
		}

		if (errors.country) {
			setErrorStatus((prevState) => ({ ...prevState, country: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, country: false }));
		}

		if (errors.currency) {
			setErrorStatus((prevState) => ({ ...prevState, currency: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, currency: false }));
		}

		if (errors.technology) {
			setErrorStatus((prevState) => ({ ...prevState, technology: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, technology: false }));
		}

		if (errors.webSite) {
			setErrorStatus((prevState) => ({ ...prevState, webSite: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, webSite: false }));
		}

		if (errors.billingBusinessName) {
			setErrorStatus((prevState) => ({
				...prevState,
				billingBusinessName: true,
			}));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				billingBusinessName: false,
			}));
		}

		if (errors.billingDocumentType) {
			setErrorStatus((prevState) => ({
				...prevState,
				billingDocumentType: true,
			}));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				billingDocumentType: false,
			}));
		}

		if (errors.billingDocumentNumber) {
			setErrorStatus((prevState) => ({
				...prevState,
				billingDocumentNumber: true,
			}));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				billingDocumentNumber: false,
			}));
		}

		if (errors.billingPhone) {
			setErrorStatus((prevState) => ({ ...prevState, billingPhone: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, billingPhone: false }));
		}

		if (errors.billingAddress) {
			setErrorStatus((prevState) => ({ ...prevState, billingAddress: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, billingAddress: false }));
		}

		setErrorStatus({
			name: false,
			email: false,
			country: false,
			currency: false,
			technology: false,
			webSite: false,
			billingBusinessName: false,
			billingDocumentType: false,
			billingDocumentNumber: false,
			billingPhone: false,
			billingAddress: false,
		});
	};

	useEffect(() => {
		let contactNameError = "";
		let contactEmailError = "";
		let contactPhoneError = "";
		let contactChargeError = "";

		if (!contactName) {
			contactNameError = "Ingrese el nombre del contacto";
		} else if (regex.regexNames.test(contactName) === false) {
			contactNameError = "Debe ingresar solo letras";
		}

		if (!contactEmail) {
			contactEmailError = "Ingrese una dirección de correo";
		} else if (regex.regexSimpleEmail.test(contactEmail) === false) {
			contactEmailError = "Ingrese una dirección de correo valida";
		}

		if (!contactPhone) {
			contactPhoneError = "Ingrese un número de teléfono";
		} else if (regex.regexNumbers.test(contactPhone) === false) {
			contactPhoneError = "Debe ingresar solo números";
		}

		if (!contactCharge) {
			contactChargeError = "Ingrese el cargo del contacto";
		}

		setAddContactErrors({
			contactName: contactNameError,
			contactEmail: contactEmailError,
			contactPhone: contactPhoneError,
			contactCharge: contactChargeError,
		});
	}, [contactName, contactEmail, contactPhone, contactCharge]);

	const validateAddContactForm = () => {
		if (addContactErrors.contactName) {
			setAddContactErrorStatus((prevState) => ({
				...prevState,
				contactName: true,
			}));
			return;
		} else {
			setAddContactErrorStatus((prevState) => ({
				...prevState,
				contactName: false,
			}));
		}

		if (addContactErrors.contactEmail) {
			setAddContactErrorStatus((prevState) => ({
				...prevState,
				contactEmail: true,
			}));
			return;
		} else {
			setAddContactErrorStatus((prevState) => ({
				...prevState,
				contactEmail: false,
			}));
		}

		if (addContactErrors.contactPhone) {
			setAddContactErrorStatus((prevState) => ({
				...prevState,
				contactPhone: true,
			}));
			return;
		} else {
			setAddContactErrorStatus((prevState) => ({
				...prevState,
				contactPhone: false,
			}));
		}

		if (addContactErrors.contactCharge) {
			setAddContactErrorStatus((prevState) => ({
				...prevState,
				contactCharge: true,
			}));
			return;
		} else {
			setAddContactErrorStatus((prevState) => ({
				...prevState,
				contactCharge: false,
			}));
		}

		setAddContactErrorStatus({
			contactName: false,
			contactEmail: false,
			contactPhone: false,
			contactCharge: false,
		});
	};

	const modifyButtonHandler = () => {
		/*Operador */
		setName(originalData.operatorData.name);
		setEmail(originalData.operatorData.email);
		setCurrency([originalData.operatorData.currency[0]]);
		setCountry(originalData.operatorData.country);
		setTechnology(originalData.operatorData.technology);
		setWebSite(originalData.operatorData.web_site);
		operatorSocialNetworksHandler(originalData.operatorData.social_networks);
		setAdditionalInformation(originalData.operatorData.additional_information);
		setCoverage(originalData.operatorData.coverage);
		setStatus(originalData.operatorData.status);
		if (originalData.operatorData.logo !== undefined || null) {
			setLogo(originalData.operatorData.logo);
			setLogoName(originalData.operatorData.logo.slice(-16));
			setHasLogo(true);
		} else {
			setHasLogo(false);
		}
		setIsActive(originalData.operatorData.is_active);
		/*Facturación */
		setBillingBusinessName(originalData.operatorBillingData.business_name);
		setBillingDocumentType(originalData.operatorBillingData.document_type);
		setBillingDocumentNumber(originalData.operatorBillingData.document_number);
		billingPhoneHandler(originalData.operatorBillingData.phone);
		setBillingAddress(originalData.operatorBillingData.address);
		/*Contactos */
		setShowAddContactForm(false);
		setContactName();
		setContactEmail();
		setContactPhoneCountry("+58");
		setContactPhone();
		setContactCharge();

		setModify(!modify);
		setHasLogo(true);
	};

	const showContactFormHandler = () => {
		setShowAddContactForm(!showAddContactForm);
	};

	const modifyButtonDynamicStyle = {
		background: modify ? "#1D428A" : "#DDE4EB",
		color: modify ? "white" : "#131523",
		border: modify ? "none" : "1px solid #426DA9",
	};

	const dynamicColorIcon = {
		color: modify ? "white" : "#426DA9",
	};

	const countrySelectHandler = (value) => {
		setCountry(value);
	};

	const currencySelectHandler = (value) => {
		setCurrency([value]);
	};

	const technologySelectHandler = (value) => {
		setTechnology(value);
	};

	const statusSwitchHandler = (checked) => {
		if (checked === true) {
			setStatus(1);
		} else {
			setStatus(2);
		}
	};

	const operatorSocialNetworksHandler = (e) => {
		if (e[0] !== null) {
			setFacebook(e[0]);
		} else {
			setFacebook("N/A");
		}

		if (e[1] !== null) {
			setInstagram(e[1]);
		} else {
			setInstagram("N/A");
		}

		if (e[2] !== null) {
			setTwitter(e[2]);
		} else {
			setTwitter("N/A");
		}
	};

	const contactPhoneCountryHandler = (value) => {
		setContactPhoneCountry(value);
	};

	const documentTypeHandler = (value) => {
		setBillingDocumentType(value);
	};

	const billingPhoneHandler = (e) => {
		const phone = e.split(" ");
		setBillingPhoneCountry(phone[0]);
		setBillingPhone(phone[1]);
	};

	const billingPhoneCountryHandler = (value) => {
		setBillingPhoneCountry(value);
	};

	const operatorLogoHandler = () => {
		setHasLogo(false);
	};

	const clearTemporalImageHandler = () => {
		setTemporalImage(null);
		setLogoBase64(undefined);
	};

	const inputFile = useRef();

	const transformToBase64Handler = (archivos) => {
		setTemporalImage(archivos[0]);

		Array.from(archivos).forEach((archivo) => {
			const reader = new FileReader();
			reader.readAsDataURL(archivo);

			reader.onload = function () {
				let arraySplitted = [];
				const base64 = reader.result;

				arraySplitted = base64.split(",");
				setLogoBase64(arraySplitted[1]);
			};
		});
	};

	const onSubmit = async (data, dataBilling) => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			name: false,
			email: false,
			country: false,
			currency: false,
			webSite: false,
			billingBusinessName: false,
			billingDocumentType: false,
			billingDocumentNumber: false,
			billingPhone: false,
			billingAddress: false,
		});

		try {
			await myFetch(`api/v1/operador/${ID}/`, token, data, "PATCH");
			await myFetch(
				`api/v1/operator-billing-data/${ID}/`,
				token,
				dataBilling,
				"PATCH"
			);
			notification.success({
				className: "notification",
				message: "Información modificada exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			// history.push("/operadoresList", { replace: true });
			initial();
			setIsVisibleForm(false);
			modifyButtonHandler();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const onVerify = async () => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			name: false,
			email: false,
			country: false,
			currency: false,
			webSite: false,
			billingBusinessName: false,
			billingDocumentType: false,
			billingDocumentNumber: false,
			billingPhone: false,
			billingAddress: false,
		});

		setIsActive(true);
		const verify = { is_active: true };
		try {
			await myFetch(`api/v1/operador/${ID}/`, token, verify, "PATCH");
			notification.success({
				className: "notification",
				message: "Información verificada exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const onAddContact = async (data) => {
		if (Object.values(addContactErrors).some((value) => value !== "")) {
			validateAddContactForm();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setAddContactErrorStatus({
			contactName: false,
			contactEmail: false,
			contactPhone: false,
			contactCharge: false,
		});

		try {
			await myFetch("api/v1/operator-contact/", token, data);
			notification.success({
				className: "notification",
				message: "Contacto agregado exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			getOperatorContacts();
			setContactName(undefined);
			setContactEmail(undefined);
			setContactPhone(undefined);
			setContactCharge(undefined);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const confirmResponse = () => {
		onSubmit(
			{
				name: name,
				email: email,
				status: status,
				additional_information: additionalInformation,
				social_networks: newSocialNetworks,
				coverage: coverage,
				web_site: webSite,
				country: country,
				currency: currency,
				technology: technology,
				logo_base64: logoBase64,
			},
			{
				business_name: billingBusinessName,
				document_number: billingDocumentNumber,
				document_type: billingDocumentType,
				address: billingAddress,
				phone: newBillingPhone,
			}
		);
	};

	const verifyStatus = () => {
		const verifyAutenticationLogin = dataState.authentication;
		if (verifyAutenticationLogin === true) {
			setIsVisibleForm(true);
		} else {
			setIsVisibleForm(false);
			notification.error({
				className: "notification",
				message: "Activar verificación en dos pasos",
				description: (
					<>
						Para realizar alguna modificación en dicha sección debe activar{" "}
						<b>Google Authenticator</b>
					</>
				),
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const onDeleteContact = async (data) => {
		try {
			await myFetch(
				`api/v1/operator-contact-byid/${data.ID}/`,
				token,
				"",
				"DELETE"
			);
			notification.success({
				className: "notification",
				message: "Contacto eliminado exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			getOperatorContacts();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	return (
		<Container>
			<div className={styles["section-container"]}>
				{loading ? (
					<LoadingDetail />
				) : (
					<>
						{/*-----------------------OPERADOR-----------------------*/}
						<div className={`${styles["row"]} ${styles["justify-end"]}`}>
							{!isActive ? (
								<div className={styles["column"]}>
									{changeOperator && (
										<div
											className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
										>
											<CustomPopConfirm
												placement="leftTop"
												title="¿Verificar información?"
												description="Al verificar la información del operador, el mismo sera publicado en el Marketplace."
												onConfirm={onVerify}
												okText="Guardar"
												cancelText="Cancelar"
											>
												<Button
													type="default"
													className={styles["button-verify"]}
													style={{ marginRight: "15px" }}
												>
													Verificar información
												</Button>
											</CustomPopConfirm>

											<Button
												style={modifyButtonDynamicStyle}
												className={styles["button-modify"]}
												onClick={modifyButtonHandler}
												icon={<FormOutlined style={dynamicColorIcon} />}
											>
												Modificar
											</Button>
										</div>
									)}
								</div>
							) : (
								<div className={styles["column"]}>
									<div
										className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
									>
										<Space size={"middle"}>
											<div
												className={`${styles["row"]} ${styles["align-center"]}`}
												style={{ marginRight: "15px" }}
											>
												<CheckCircleFilled
													className={styles["verified-icon"]}
												/>
												<span className={styles["verified-text"]}>
													Información verificada
												</span>
											</div>

											{changeOperator && (
												<Button
													style={modifyButtonDynamicStyle}
													className={styles["button-modify"]}
													onClick={modifyButtonHandler}
													icon={<FormOutlined style={dynamicColorIcon} />}
												>
													Modificar
												</Button>
											)}
										</Space>
									</div>
								</div>
							)}
						</div>

						<div className={styles["row"]}>
							<div className={`${styles["column"]} ${styles["column-left"]}`}>
								<div className={styles["row"]}>
									<span className={styles["form-section-title"]}>
										Datos del operador
									</span>
								</div>

								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Nombre <span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											style={{
												width: "95%",
											}}
											disabled={modify ? false : true}
											value={name}
											onChange={(e) => setName(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.name ? "error" : ""}
										/>
										{errorStatus.name && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.name}
												</span>
											</div>
										)}
									</div>

									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Correo electrónico{" "}
											<span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											disabled={modify ? false : true}
											value={email}
											onChange={(e) => setEmail(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.email ? "error" : ""}
										/>
										{errorStatus.email && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.email}
												</span>
											</div>
										)}
									</div>
								</div>

								<br />
								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											País <span className={styles["blue"]}>*</span>
										</span>
										<Select
											className={styles["form-input"]}
											style={{
												width: "95%",
												backgroundColor: modify ? "white" : "#ececec",
											}}
											disabled={modify ? false : true}
											value={country}
											onChange={countrySelectHandler}
											onBlur={validateInput}
											bordered={false}
											status={errorStatus.country ? "error" : ""}
										>
											{countryList.map((country) => (
												<Select.Option value={country.ID} key={country.ID}>
													<img
														src={`https://flagcdn.com/28x21/${country.iso}.png`}
														width="20"
														height="15"
														alt={country.name}
														style={{ margin: "0 5px 5px 0" }}
													/>
													{country.name}
												</Select.Option>
											))}
										</Select>
										{errorStatus.country && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.country}
												</span>
											</div>
										)}
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Página web <span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											disabled={modify ? false : true}
											value={webSite}
											onChange={(e) => setWebSite(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.webSite ? "error" : ""}
										/>
										{errorStatus.webSite && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.webSite}
												</span>
											</div>
										)}
									</div>
								</div>

								<br />
								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>Facebook</span>
										<Input
											className={styles["form-input"]}
											style={{ width: "95%" }}
											disabled={modify ? false : true}
											value={facebook}
											onChange={(e) => setFacebook(e?.target?.value)}
										/>
									</div>

									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>Instagram</span>
										<Input
											className={styles["form-input"]}
											style={{ width: "95%" }}
											disabled={modify ? false : true}
											value={instagram}
											onChange={(e) => setInstagram(e?.target?.value)}
										/>
									</div>

									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>Twitter</span>
										<Input
											className={styles["form-input"]}
											disabled={modify ? false : true}
											value={twitter}
											onChange={(e) => setTwitter(e?.target?.value)}
										/>
									</div>
								</div>

								<br />
								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Información adicional
										</span>
										<TextArea
											rows={3}
											className={styles["form-textarea"]}
											disabled={modify ? false : true}
											value={additionalInformation}
											onChange={(e) =>
												setAdditionalInformation(e?.target?.value)
											}
										/>
									</div>
								</div>

								<br />
								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>Cobertura</span>
										<TextArea
											rows={3}
											className={styles["form-textarea"]}
											disabled={modify ? false : true}
											value={coverage}
											onChange={(e) => setCoverage(e?.target?.value)}
										/>
									</div>
								</div>

								<br />
								<br />

								{hasLogo ? (
									<div className={styles["logo-container"]}>
										<div
											className={`${styles["row"]} ${styles["align-center"]}`}
										>
											<img src={logo} className={styles["logo-image"]} />
											<span className={styles["logo-name"]}>{logoName}</span>
											{modify && (
												<CustomPopConfirm
													placement="topRight"
													title="¿Eliminar fotografía?"
													description="Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imagenes"
													onConfirm={operatorLogoHandler}
													okText="Eliminar"
													cancelText="Cancelar"
												>
													<button className={styles["hidden-button"]}>
														<DeleteFilled
															className={styles["delete-logo-icon"]}
														/>
													</button>
												</CustomPopConfirm>
											)}
										</div>
									</div>
								) : logoBase64 === null || logoBase64 === undefined ? (
									<button
										className={styles["upload-image-box"]}
										onClick={() => inputFile.current.click()}
									>
										<i
											className={`fa-solid fa-upload ${styles["upload-image-icon"]}`}
										></i>
										<span className={styles["logo-title"]}>
											<b>Logo del operador</b>
										</span>
										<span className={styles["logo-text"]}>
											Soporte para una carga única. Prohibir estrictamente
											cargar datos de la empresa u otros archivos de bandas
										</span>
										<span className={styles["logo-required-text"]}>
											Imagen requerida
										</span>
										<input
											type="file"
											ref={inputFile}
											accept="image/png, image/jpeg"
											onChange={(e) => transformToBase64Handler(e.target.files)}
											style={{ display: "none" }}
										/>
									</button>
								) : (
									<div className={styles["column"]}>
										<img
											className={`${styles["temporal-image"]} animate__animated animate__fadeIn`}
											src={`data:image/png;base64,${logoBase64}`}
											alt="Imagen seleccionada"
										/>
										<br />

										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											<p
												style={{ color: "grey" }}
												className="animate__animated animate__fadeIn"
											>
												{temporalImage?.name}
											</p>
											<DeleteOutlined
												className={styles["clear-temporal-image-icon"]}
												onClick={clearTemporalImageHandler}
											/>
										</div>
									</div>
								)}

								<br />
								<br />

								{/*---------------------FACTURACIÓN---------------------*/}
								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<span className={styles["form-section-title"]}>
										Datos de facturación
									</span>
								</div>

								<br />

								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Razón social <span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											disabled={modify ? false : true}
											value={billingBusinessName}
											onChange={(e) => setBillingBusinessName(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.billingBusinessName ? "error" : ""}
										/>
										{errorStatus.billingBusinessName && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.billingBusinessName}
												</span>
											</div>
										)}
									</div>
								</div>

								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Documento de identidad{" "}
											<span className={styles["blue"]}>*</span>
										</span>
										<div className={styles["row"]}>
											<div
												className={styles["column"]}
												style={{ width: "35%", marginRight: "5%" }}
											>
												<Select
													className={styles["form-input"]}
													disabled={modify ? false : true}
													style={{
														backgroundColor: modify ? "white" : "#ececec",
													}}
													dropdownStyle={{ borderRadius: 7 }}
													bordered={false}
													value={billingDocumentType}
													onChange={documentTypeHandler}
													onBlur={validateInput}
													status={
														errorStatus.billingDocumentType ? "error" : ""
													}
												>
													{billingDocumentList.map((document) => (
														<Select.Option
															key={document.ID}
															value={document.ID}
														>
															{document.name}
														</Select.Option>
													))}
												</Select>
												{errorStatus.billingDocumentType && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.billingDocumentType}
														</span>
													</div>
												)}
											</div>
											<div
												className={styles["column"]}
												style={{ width: "55%" }}
											>
												<Input
													className={styles["form-input"]}
													value={billingDocumentNumber}
													disabled={modify ? false : true}
													onChange={(e) =>
														setBillingDocumentNumber(e?.target?.value)
													}
													onBlur={validateInput}
													status={
														errorStatus.billingDocumentNumber ? "error" : ""
													}
												/>
												{errorStatus.billingDocumentNumber && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.billingDocumentNumber}
														</span>
													</div>
												)}
											</div>
										</div>
									</div>

									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Número de teléfono{" "}
											<span className={styles["blue"]}>*</span>
										</span>
										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											<Select
												className={styles["form-input"]}
												disabled={modify ? false : true}
												style={{
													width: "30%",
													backgroundColor: modify ? "white" : "#ececec",
												}}
												dropdownStyle={{ borderRadius: 7 }}
												bordered={false}
												value={billingPhoneCountry}
												onChange={billingPhoneCountryHandler}
											>
												<Select.Option value={"+56"}>
													<img
														src={`https://flagcdn.com/28x21/cl.png`}
														width="20"
														height="15"
														alt="Chile"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+56
												</Select.Option>
												<Select.Option value={"+58"}>
													<img
														src={`https://flagcdn.com/28x21/ve.png`}
														width="20"
														height="15"
														alt="Venezuela"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+58
												</Select.Option>
												<Select.Option value={"+55"}>
													<img
														src={`https://flagcdn.com/28x21/br.png`}
														width="20"
														height="15"
														alt="Brasil"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+55
												</Select.Option>
												<Select.Option value={"+51"}>
													<img
														src={`https://flagcdn.com/28x21/pe.png`}
														width="20"
														height="15"
														alt="Perú"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+51
												</Select.Option>
											</Select>
											<div
												className={styles["column"]}
												style={{ width: "65%" }}
											>
												<Input
													className={styles["form-input"]}
													value={billingPhone}
													disabled={modify ? false : true}
													onChange={(e) => setBillingPhone(e?.target?.value)}
													onBlur={validateInput}
													status={errorStatus.billingPhone ? "error" : ""}
												/>
												{errorStatus.billingPhone && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.billingPhone}
														</span>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>

								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div style={{ width: "100%" }}>
										<span className={styles["form-input-name"]}>
											Dirección <span className={styles["blue"]}>*</span>
										</span>
										<Input
											type="text"
											className={styles["form-input"]}
											disabled={modify ? false : true}
											value={billingAddress}
											onChange={(e) => setBillingAddress(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.billingAddress ? "error" : ""}
										/>
										{errorStatus.billingAddress && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.billingAddress}
												</span>
											</div>
										)}
									</div>
								</div>
							</div>

							<div
								className={`${styles["column"]} ${styles["flex"]} ${styles["column-right"]}`}
							>
								<div className={styles["row"]}>
									<span className={styles["form-section-title"]}>
										Información configurable
									</span>
								</div>

								<br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Moneda <span className={styles["blue"]}>*</span>
										</span>
										<Select
											className={styles["form-input"]}
											style={{
												width: "95%",
												backgroundColor: modify ? "white" : "#f5f5f5",
											}}
											disabled={modify ? false : true}
											value={currency}
											onChange={currencySelectHandler}
											onBlur={validateInput}
											bordered={false}
											status={errorStatus.currency ? "error" : ""}
										>
											{currencyList.map((currency) => (
												<Select.Option value={currency.ID} key={currency.ID}>
													{currency.name}
												</Select.Option>
											))}
										</Select>
										{errorStatus.currency && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.currency}
												</span>
											</div>
										)}
									</div>

									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Tecnologías <span className={styles["blue"]}>*</span>
										</span>
										<Select
											mode="multiple"
											maxTagCount="responsive"
											className={styles["form-input"]}
											style={{
												backgroundColor: modify ? "white" : "#f5f5f5",
											}}
											disabled={modify ? false : true}
											value={technology}
											onChange={technologySelectHandler}
											onBlur={validateInput}
											bordered={false}
											status={errorStatus.technology === true ? "error" : ""}
										>
											{technologyList.map((technology) => (
												<Select.Option
													value={technology.ID}
													key={technology.ID}
												>
													{technology.name}
												</Select.Option>
											))}
										</Select>
										{errorStatus.technology && (
											<div>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.technology}
												</span>
											</div>
										)}
									</div>
								</div>

								<br />
								<br />

								<div className={styles["row"]}>
									<div
										className={`${styles["column"]} ${styles["justify-center"]}`}
									>
										<span className={styles["form-input-name"]}>
											<b>Estatus del operador</b>
										</span>
									</div>
									<div
										className={styles["column"]}
										style={{ width: "35%", marginLeft: "10%" }}
									>
										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											<span className={styles["switch-text"]}>Inactivo</span>
											<Switch
												onChange={statusSwitchHandler}
												checked={status === 1 ? true : false}
												disabled={
													dataState.rol === "vendedor" ||
													dataState.rol === "administrador_isp" ||
													dataState.rol === "supervisor_comercial"
														? true
														: modify
														? false
														: true
												}
											/>
											<span className={styles["switch-text"]}>Activo</span>
										</div>
									</div>
								</div>

								<br />

								<span className={styles["important-info-title"]}>
									Información importante
								</span>
								<span className={styles["important-info-description"]}>
									Al realizar algún cambio en el estatus del operador se verá
									reflejado de forma inmediata en el Marketplace.
								</span>

								<Divider style={{ margin: "8% 0" }} />

								{/*-----------------------CONTACTO-----------------------*/}
								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<span className={styles["form-section-title"]}>
										Datos de contacto del operador
									</span>
								</div>

								<div className={styles["row"]}>
									{showAddContactForm === false ? (
										<Button
											type="link"
											className={styles["contact-form-button"]}
											onClick={showContactFormHandler}
										>
											Agregar información de contacto
										</Button>
									) : (
										<Button
											type="link"
											className={styles["contact-form-button"]}
											onClick={showContactFormHandler}
										>
											Ocultar formulario
										</Button>
									)}
								</div>

								<br />

								{showAddContactForm ? (
									<>
										<div className={styles["row"]}>
											<div className={`${styles["column"]} ${styles["flex"]}`}>
												<span className={styles["form-input-name"]}>
													Nombre y apellido{" "}
													<span className={styles["blue"]}>*</span>{" "}
												</span>
												<Input
													className={styles["form-input"]}
													disabled={modify ? false : true}
													value={contactName}
													onChange={(e) => setContactName(e?.target?.value)}
													onBlur={validateAddContactForm}
													status={
														addContactErrorStatus.contactName ? "error" : ""
													}
												/>
												{addContactErrorStatus.contactName && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{addContactErrors.contactName}
														</span>
													</div>
												)}
											</div>
										</div>

										<br />
										<br />

										<div className={styles["row"]}>
											<div className={`${styles["column"]} ${styles["flex"]}`}>
												<span className={styles["form-input-name"]}>
													Correo electrónico{" "}
													<span className={styles["blue"]}>*</span>
												</span>
												<Input
													className={styles["form-input"]}
													disabled={modify ? false : true}
													value={contactEmail}
													onChange={(e) => setContactEmail(e?.target?.value)}
													onBlur={validateAddContactForm}
													status={
														addContactErrorStatus.contactEmail ? "error" : ""
													}
												/>
												{addContactErrorStatus.contactEmail && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{addContactErrors.contactEmail}
														</span>
													</div>
												)}
											</div>
										</div>

										<br />
										<br />

										<div className={styles["row"]}>
											<div className={`${styles["column"]} ${styles["flex"]}`}>
												<span className={styles["form-input-name"]}>
													Número de teléfono{" "}
													<span className={styles["blue"]}>*</span>
												</span>
												<div
													className={`${styles["row"]} ${styles["space-between"]}`}
												>
													<Select
														className={styles["form-input"]}
														disabled={modify ? false : true}
														style={{
															width: "30%",
															backgroundColor: modify ? "white" : "#f5f5f5",
														}}
														dropdownStyle={{ borderRadius: 7 }}
														bordered={false}
														value={contactPhoneCountry}
														onChange={contactPhoneCountryHandler}
													>
														<Select.Option value={"+56"}>
															<img
																src={`https://flagcdn.com/28x21/cl.png`}
																width="20"
																height="15"
																alt="Chile"
																style={{ margin: "0 5px 5px 0" }}
															/>
															+56
														</Select.Option>
														<Select.Option value={"+58"}>
															<img
																src={`https://flagcdn.com/28x21/ve.png`}
																width="20"
																height="15"
																alt="Venezuela"
																style={{ margin: "0 5px 5px 0" }}
															/>
															+58
														</Select.Option>
														<Select.Option value={"+55"}>
															<img
																src={`https://flagcdn.com/28x21/br.png`}
																width="20"
																height="15"
																alt="Brasil"
																style={{ margin: "0 5px 5px 0" }}
															/>
															+55
														</Select.Option>
														<Select.Option value={"+51"}>
															<img
																src={`https://flagcdn.com/28x21/pe.png`}
																width="20"
																height="15"
																alt="Perú"
																style={{ margin: "0 5px 5px 0" }}
															/>
															+51
														</Select.Option>
													</Select>
													<div
														className={styles["column"]}
														style={{ width: "65%" }}
													>
														<Input
															className={styles["form-input"]}
															value={contactPhone}
															disabled={modify ? false : true}
															onChange={(e) =>
																setContactPhone(e?.target?.value)
															}
															onBlur={validateAddContactForm}
															status={
																addContactErrorStatus.contactPhone
																	? "error"
																	: ""
															}
														/>
														{addContactErrorStatus.contactPhone && (
															<div>
																<span
																	className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
																>
																	{addContactErrors.contactPhone}
																</span>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>

										<br />
										<br />

										<div className={styles["row"]}>
											<div className={`${styles["column"]} ${styles["flex"]}`}>
												<span className={styles["form-input-name"]}>
													Cargo <span className={styles["blue"]}>*</span>
												</span>
												<Input
													className={styles["form-input"]}
													value={contactCharge}
													disabled={modify ? false : true}
													onChange={(e) => setContactCharge(e?.target?.value)}
													onBlur={validateAddContactForm}
													status={
														addContactErrorStatus.contactCharge ? "error" : ""
													}
												/>
												{addContactErrorStatus.contactCharge && (
													<div>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{addContactErrors.contactCharge}
														</span>
													</div>
												)}
											</div>
										</div>

										<br />
										<br />

										<div className={styles["row"]}>
											<Button
												type="default"
												className={styles["add-contact-button"]}
												onClick={() =>
													onAddContact({
														name: contactName,
														email: contactEmail,
														phone: newContactPhone,
														position: contactCharge,
														operator: ID,
													})
												}
											>
												Agregar
											</Button>
										</div>
									</>
								) : null}

								<br />

								{contacts.map((contact) => (
									<div key={contact.ID}>
										<div className={styles["contacts-container"]}>
											<div className={styles["row"]}>
												<div
													className={`${styles["column"]} ${styles["flex"]}`}
													style={{ marginBottom: "auto" }}
												>
													<span className={styles["contact-name"]}>
														{contact.name}
													</span>
													<span className={styles["contact-details"]}>
														{contact.email}
													</span>
													<span className={styles["contact-details"]}>
														{contact.phone}
													</span>
												</div>
												<div
													className={`${styles["column"]} ${styles["flex"]} ${styles["align-end"]}`}
												>
													<Tooltip
														title={
															<span style={{ color: "black" }}>
																{contact.position}
															</span>
														}
														color="white"
													>
														<div className={styles["charge-tag"]}>
															<span className={styles["charge-details"]}>
																{contact.position}
															</span>
														</div>
													</Tooltip>
													<CustomPopConfirm
														placement="leftTop"
														title="¿Eliminar contacto?"
														description="Los cambios realizados se sincronizaran automáticamente con las demás aplicaciones."
														onConfirm={() => onDeleteContact(contact)}
														okText="Eliminar"
														cancelText="Cancelar"
													>
														<Button
															type="default"
															className={styles["delete-contact-container"]}
														>
															<i
																className={`fa-solid fa-trash ${styles["delete-contact-icon"]}`}
															></i>
														</Button>
													</CustomPopConfirm>
												</div>
											</div>
										</div>

										<br />
									</div>
								))}
							</div>
						</div>

						<br />

						<div className={`${styles["row"]} ${styles["justify-end"]}`}>
							<Space>
								{modify && (
									<Space>
										<Button
											type="default"
											className={`${styles["action-button"]} ${styles["action-button-cancel"]}`}
											onClick={modifyButtonHandler}
										>
											Cancelar
										</Button>
										<Button
											type="default"
											className={`${styles["action-button"]} ${styles["action-button-save"]}`}
											onClick={() => verifyStatus()}
										>
											Guardar
										</Button>
									</Space>
								)}
							</Space>
							{isVisibleForm && (
								<CustomModal onClick={() => setIsVisibleForm(false)}>
									<div className={styles["authentication-backdrop"]}>
										<AuthenticationGoggle
											visible={isVisibleForm}
											setVisible={setIsVisibleForm}
											confirmResponse={confirmResponse}
											animation={"general"}
										/>
									</div>
								</CustomModal>
							)}
						</div>
					</>
				)}
			</div>
		</Container>
	);
};

export default GeneralOperatorPanel;

const Container = styled.div`
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}

	.ant-select-selector {
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector {
			font-size: 15.18px;
		}
	}
`;
