import React, { useState, useEffect } from "react";
import { Panel, PanelSubHeader } from "../../../components/panel/panel";
import TableData from "../../../components/table/TableData";
import FormOperatorPlans from "./FormOperatorPlans";
import { myFetchGet, myFetch, myExportData } from "../../../services/services";
import CustomModal from "../../../components/modal/CustomModal";
import { Button, notification } from "antd";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { useSelector } from "react-redux";
import ColorCircle from "../../../components/colorCircle/ColorCircle";
import TextColored from "../../../components/textColored/TextColored";
import TableText from "../../../components/tableText/TableText";
import fileDownload from "js-file-download";
import { Loading, LoadingButton } from "../../../components/loading/Loading";
import styles from "./OperatorPlans.module.css";
import TablePagination from "../../../components/tablePagination/TablePagination";

const OperatorPlans = ({ data }) => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);

	const addOperatorPlan = dataState.permissions.includes("add_operatorplans");
	const changeOperatorPlan = dataState.permissions.includes(
		"change_operatorplans"
	);

	const [loadingTable, setLoadingTable] = useState(false);
	const [totalTableSize, setTotalTableSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);
	const [selectItem, setSelectItem] = useState();
	const [isVisibleForm, setIsVisibleForm] = useState(false);
	const [tableColumns, setTableColumns] = useState([]);
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 });
	const [columnFilterValue, setColumnFilterValue] = useState([]);
	const [exportFilters, setExportFilters] = useState({});

	const { ID } = data;

	const initial = async () => {
		let filters = {};

		filters = {
			filters: `[["operator", "equal", "` + ID + `"]]`,
			offset: rowsParams.offset,
			start: rowsParams.start,
		};

		if (columnFilterValue.length === 0) {
			filters = filters;
		} else if (columnFilterValue.length === 1) {
			filters.filters = filters.filters.replace(
				"]",
				`], ` + `["status","equal","` + columnFilterValue[0] + `"]`
			);
		}

		setExportFilters(filters);

		setLoadingTable(true);

		try {
			const response = await myFetch(
				`api/v1/plans/datatables/`,
				token,
				filters
			);

			if (response) {
				if (response.size) {
					setTotalTableSize(response.size);
				}
				if (response.data) {
					const rows = response.data.map((row) => {
						let planCategory = row?.category;
						let planType = row?.type_plan;
						return {
							...row,
							key: row.ID,
							type_plan: planCategory,
							type_plan_row: planType,
						};
					});
					setTableRows(rows);
				}
			}
			setLoadingTable(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const editItem = (item) => {
		setSelectItem(item);
		setIsVisibleForm(true);
	};

	const getColumns = async () => {
		try {
			const data = await myFetchGet("api/v1/plans/datatables_struct", token);
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					onCell:
						columnsTittle === "Nombre" && changeOperatorPlan
							? (record, rowIndex) => {
									return {
										onClick: (event) => {
											event.stopPropagation();
											editItem(record);
										},
										style: { cursor: "pointer" },
										className: "hover-class",
									};
							  }
							: null,
					filters:
						columnsTittle === "Estatus"
							? [
									{ text: "Activo", value: 1 },
									{ text: "Inactivo", value: 2 },
							  ]
							: null,
					filterMultiple: false,
					title: columnsTittle,
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					render:
						columnsTittle === "Nombre" && changeOperatorPlan
							? (value) => <TextColored value={value} />
							: columnsTittle === "Tipo"
							? (value) => (
									<span
										style={{
											display: "flex",
											alignItems: "center",

											width: "100%",
										}}
									>
										{value.toString() === "1" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<TableText value={"Simple"} />
												<i
													className="fa-solid fa-at"
													style={{ color: "#7F7F7F", margin: "0 0 0 10px" }}
												></i>
											</div>
										) : value.toString() === "2" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<TableText value={"Simple"} />
												<i
													className="fa-solid fa-tv"
													style={{ color: "#7F7F7F", margin: "0 0 0 10px" }}
												></i>
											</div>
										) : value.toString() === "3" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<TableText value={"Simple"} />
												<i
													className="fa-solid fa-mobile"
													style={{ color: "#7F7F7F", margin: "0 0 0 10px" }}
												></i>
											</div>
										) : value.toString() === "1,2" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<TableText value={"Duo"} />
												<div
													style={{
														marginLeft: "5px",
														display: "flex",
														alignItems: "center",
													}}
												>
													<i
														className="fa-solid fa-at"
														style={{ color: "#7F7F7F", margin: "0 10px 0 0" }}
													></i>
													<TableText value={"+"} />
													<i
														className="fa-solid fa-tv"
														style={{ color: "#7F7F7F", margin: "0 0 0 10px" }}
													></i>
												</div>
											</div>
										) : value.toString() === "1,3" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<TableText value={"Duo"} />
												<div
													style={{
														marginLeft: "5px",
														display: "flex",
														alignItems: "center",
													}}
												>
													<i
														className="fa-solid fa-at"
														style={{ color: "#7F7F7F", margin: "0 10px 0 0" }}
													></i>
													<TableText value={"+"} />
													<i
														className="fa-solid fa-mobile"
														style={{ color: "#7F7F7F", margin: "0 0 0 10px" }}
													></i>
												</div>
											</div>
										) : value.toString() === "2,3" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<TableText value={"Duo"} />
												<div
													style={{
														marginLeft: "5px",
														display: "flex",
														alignItems: "center",
													}}
												>
													<i
														className="fa-solid fa-tv"
														style={{ color: "#7F7F7F", margin: "0 10px 0 0" }}
													></i>
													<TableText value={"+"} />
													<i
														className="fa-solid fa-mobile"
														style={{ color: "#7F7F7F", margin: "0 0 0 10px" }}
													></i>
												</div>
											</div>
										) : value.toString() === "1,2,3" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<TableText value={"Trio"} />
												<div
													style={{
														marginLeft: "5px",
														display: "flex",
														alignItems: "center",
													}}
												>
													<i
														className="fa-solid fa-at"
														style={{ color: "#7F7F7F", margin: "0 10px 0 0" }}
													></i>
													<TableText value={"+"} />
													<i
														className="fa-solid fa-tv"
														style={{
															color: "#7F7F7F",
															margin: "0 10px 0 10px",
														}}
													></i>
													<TableText value={"+"} />
													<i
														className="fa-solid fa-mobile"
														style={{ color: "#7F7F7F", margin: "0 0 0 10px" }}
													></i>
												</div>
											</div>
										) : null}
									</span>
							  )
							: columnsTittle === "Estatus"
							? (value) => (
									<span
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{value === 1 ? (
											<div style={{ display: "flex", alignItems: "center" }}>
												<ColorCircle color={"#26A805"} />
												<TableText value={"Activo"} />
											</div>
										) : (
											<div style={{ display: "flex", alignItems: "center" }}>
												<ColorCircle color={"#F53C56"} />
												<TableText value={"Inactivo"} />
											</div>
										)}
									</span>
							  )
							: (value) => <TableText value={value} />,
				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	};

	const tableChangeHandler = (pagination, filter, sorter, extra) => {
		if (filter.status) {
			setColumnFilterValue(filter.status);
		} else {
			setColumnFilterValue([]);
		}
	};

	useEffect(() => {
		initial();
		getColumns();
	}, [rowsParams, columnFilterValue]);

	const onCloseModal = () => {
		setIsVisibleForm(false);
		setSelectItem();
	};

	const onExportData = async () => {
		try {
			const file = await myExportData(
				"api/v1/plans/datatables_download/",
				token,
				exportFilters
			);
			fileDownload(file, "Listado de Planes.xlsx");
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<div className="col-md-12">
			<Panel>
				<PanelSubHeader>
					<div className={styles["subheader-container"]}>
						<span className={styles["subheader-title"]}>
							Listado consolidado de planes registrados por el operador.
						</span>

						{addOperatorPlan && (
							<>
								{loadingTable ? (
									<div style={{ width: "10%" }}>
										<LoadingButton />
									</div>
								) : (
									<Button
										className={styles["form-button"]}
										onClick={setIsVisibleForm}
										icon={
											<i
												className={`fa-solid fa-plus ${styles["form-button-icon"]}`}
											></i>
										}
									>
										Agregar plan
									</Button>
								)}
							</>
						)}
					</div>
				</PanelSubHeader>
				{isVisibleForm && (
					<CustomModal onClick={onCloseModal}>
						<FormOperatorPlans
							data={selectItem ? selectItem : {}}
							cancel={() => setSelectItem()}
							setVisible={setIsVisibleForm}
							visible={isVisibleForm}
							modalHeader={!selectItem ? "Agregar" : "Modificar"}
							operatorID={ID}
							initial={initial}
						/>
					</CustomModal>
				)}

				{loadingTable ? (
					<Loading />
				) : (
					<>
						<TableData
							rows={tableRows}
							columns={tableColumns}
							onChange={tableChangeHandler}
						/>

						<div
							className={`${styles["row"]} ${styles["pagination-container"]}`}
						>
							<TablePagination
								paginationSettings={paginationSettings}
								totalTableSize={totalTableSize}
								onChange={modifyPagination}
								showTotal={(total, range) => (
									<div className={styles["column"]}>
										<span className={styles["pagination"]}>
											Mostrando del {range[0]} al {range[1]} de un total de{" "}
											{total} registros
										</span>
										<span
											className={styles["export-button"]}
											onClick={onExportData}
										>
											<i
												className={`fa-solid fa-file-excel ${styles["export-button-icon"]}`}
											></i>
											Exportar data
										</span>
									</div>
								)}
							/>
						</div>
					</>
				)}
			</Panel>
		</div>
	);
};

export default OperatorPlans;
