import React, { useState, useEffect } from "react";
import {
	Input,
	Space,
	Button,
	Divider,
	Checkbox,
	notification,
	ColorPicker,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { myFetch, myFetchGet } from "../../../services/services";
import { Tab, TabList, TabPanel } from "react-tabs";
import { FormTabsPanel } from "../../../components/tabs/FormTabs";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { LoadingForm } from "../../../components/loading/Loading";
import { useSelector } from "react-redux";
import AuthenticationGoggle from "../../../components/authenticationGoggle/AuthenticationGoggle";
import styles from "./FormOperatorCoverage.module.css";

const FormOperatorCoverage = ({
	modalHeader,
	visible,
	setVisible,
	invertedCoordinates,
	coordinatesCenter,
	operatorID,
	initial,
	refreshMap,
	getRows,
	data,
}) => {
	const dataState = useSelector((state) => state.authState);
	const token = useReduxToken();
	const [loading, setLoading] = useState(true);

	const [polygonName, setPolygonName] = useState(data?.name || undefined);
	const [colorPicked, setColorPicked] = useState(data?.color || "#1677FF");

	const [showFilters, setShowFilters] = useState(false);
	const [filterCount, setFilterCount] = useState(0);
	const [planCategory, setPlanCategory] = useState([]);
	const [operatorTechnologies, setOperatorTechnologies] = useState([]);
	const [showAll, setShowAll] = useState(false);
	const [planTechnology, setPlanTechnology] = useState([]);

	const [plansList, setPlansList] = useState([]);
	const [filteredPlansList, setFilteredPlansList] = useState([]);

	const [checkedPlanList, setCheckedPlanList] = useState([]);

	const [modifyCoverage, setModifyCoverage] = useState(true);
	const [visibleVerify, setVisibleVerify] = useState(false);

	/*Validaciones */
	const [errors, setErrors] = useState({
		polygonName: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		polygonName: false,
	});

	const getPolygonData = async () => {
		try {
			const response = await myFetchGet(
				`api/v1/coordinates/${data.id_unique}`,
				token
			);

			setCheckedPlanList([
				...response?.plans_ids_update?.simplePlans,
				...response?.plans_ids_update?.duoPlans,
				...response?.plans_ids_update?.trioPlans,
			]);

			getFormData();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const getFormData = async () => {
		try {
			const response = await myFetchGet(
				`api/v1/plans/?operator=${operatorID}`,
				token
			);
			const technology = await myFetchGet(
				`api/v1/technology/${operatorID}/for_operator/`,
				token
			);

			const filterDeleted = response.filter((obj) => !obj.deleted);
			setPlansList(filterDeleted);
			setFilteredPlansList(filterDeleted);

			setOperatorTechnologies(technology);

			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		if (data) {
			getPolygonData();
		} else {
			getFormData();
		}
	}, []);

	useEffect(() => {
		let polygonNameError = "";

		if (!polygonName) {
			polygonNameError = "Ingrese el nombre del polígono";
		}

		setErrors({
			polygonName: polygonNameError,
		});
	}, [polygonName, colorPicked, errorStatus]);

	const validateInput = () => {
		if (errors.polygonName) {
			setErrorStatus((prevState) => ({ ...prevState, polygonName: true }));
			return;
		}

		setErrorStatus({
			polygonName: false,
		});
	};

	const onSubmit = async (data, ID) => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		if (checkedPlanList.length < 1) {
			notification.warning({
				className: "notification",
				message: "Por favor, selecciona al menos un plan",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			polygonName: false,
		});

		if (ID) {
			try {
				await myFetch(`api/v1/coordinates/${ID}/`, token, data, "PATCH");
				notification.success({
					className: "notification",
					message: "Cobertura modificada exitosamente",
					placement: "bottomLeft",
					duration: 2.5,
					onClose: () => {
						onCloseModal();
						setVisibleVerify(false);
					},
				});
			} catch (e) {
				console.log("error", e);
				notification.error({
					className: "notification",
					message: "Se ha producido un error inesperado",
					placement: "bottomLeft",
					duration: 2.5,
					onClose: () => {
						onCloseModal();
						setVisibleVerify(false);
					},
				});
			}
		} else {
			try {
				await myFetch("api/v1/coordinates/", token, data);
				notification.success({
					className: "notification",
					message: "Cobertura guardada exitosamente",
					placement: "bottomLeft",
					duration: 2.5,
				});
				onCloseModal();
			} catch (e) {
				console.log("error", e);
				notification.error({
					className: "notification",
					message: "Se ha producido un error inesperado",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		}
	};

	const onCloseModal = () => {
		setVisible(false);
		if (data) {
			getRows();
		} else {
			initial();
			refreshMap();
		}
	};

	const showFiltersHandler = () => {
		setShowFilters(!showFilters);
	};

	const cleanFiltersHandler = () => {
		setPlanCategory([]);
		setPlanTechnology([]);
		setFilterCount(0);
		setFilteredPlansList([...plansList]);
	};

	const planCategoryHandler = (categoryValue) => {
		let tempCategoryArray = [...planCategory];

		if (tempCategoryArray.indexOf(categoryValue) > -1) {
			tempCategoryArray.splice(tempCategoryArray.indexOf(categoryValue), 1);
			setFilterCount((prevState) => prevState - 1);
		} else {
			tempCategoryArray.push(categoryValue);
			setFilterCount((prevState) => prevState + 1);
		}

		tempCategoryArray.sort(function (a, b) {
			return a - b;
		});

		setPlanCategory(tempCategoryArray);

		if (tempCategoryArray.length > 0) {
			if (planTechnology.length > 0) {
				setFilteredPlansList(
					plansList.filter(
						(obj) =>
							planTechnology.includes(obj.technology) &&
							obj.category.toString() === tempCategoryArray.toString()
					)
				);
			} else {
				setFilteredPlansList(
					plansList.filter(
						(obj) => obj.category.toString() === tempCategoryArray.toString()
					)
				);
			}
		} else {
			if (planTechnology.length > 0) {
				setFilteredPlansList(
					plansList.filter((obj) => planTechnology.includes(obj.technology))
				);
			} else {
				setFilteredPlansList([...plansList]);
			}
		}
	};

	const showAllHandler = (e) => {
		setShowAll(e.target.checked);
	};

	const planTechnologyHandler = (list) => {
		let tempTechnologyArray = [...planTechnology];

		if (list.length > tempTechnologyArray.length) {
			setFilterCount((prevState) => prevState + 1);
		} else if (list.length < tempTechnologyArray.length) {
			setFilterCount((prevState) => prevState - 1);
		}

		setPlanTechnology(list);

		if (list.length > 0) {
			if (planCategory.length > 0) {
				setFilteredPlansList(
					plansList.filter((obj) => {
						return (
							list.includes(obj.technology) &&
							obj.category.toString() === planCategory.toString()
						);
					})
				);
			} else {
				setFilteredPlansList(
					plansList.filter((obj) => {
						return list.includes(obj.technology);
					})
				);
			}
		} else {
			if (planCategory.length > 0) {
				setFilteredPlansList(
					plansList.filter(
						(obj) => obj.category.toString() === planCategory.toString()
					)
				);
			} else {
				setFilteredPlansList([...plansList]);
			}
		}
	};

	const plansCheckHandler = (list) => {
		setCheckedPlanList(list);
	};

	const onColorChange = (value, hex) => {
		setColorPicked(hex);
	};

	const verifyStatus = () => {
		const verifyAuthenticationLogin = dataState.authentication;
		if (verifyAuthenticationLogin === true) {
			setTimeout(() => {
				setModifyCoverage(false);
				setVisibleVerify(true);
			}, 200);
		} else {
			setVisibleVerify(false);
			notification.error({
				className: "notification",
				message: "Activar verificación en dos pasos",
				description: (
					<>
						Para realizar alguna modificación en dicha sección debe activar{" "}
						<b>Google Authenticator</b>
					</>
				),
				placement: "bottomLeft",
				duration: 2.5,
				onClose: () => setVisible(false),
			});
		}
	};

	const confirmResponse = () => {
		onSubmit(
			{
				name: polygonName,
				color: colorPicked,
				plans: checkedPlanList,
			},
			data.id_unique
		);
	};

	if (visible) {
		return (
			<div className={styles["container"]}>
				{modifyCoverage && (
					<>
						<div
							className={`${styles["form-container"]} animate__animated animate__fadeInRight ${styles["custom-scroll"]}`}
						>
							<div
								className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
							>
								<span className={styles["form-title"]}>
									{modalHeader} de zona de cobertura
								</span>
								<CloseOutlined
									className={styles["close-form-icon"]}
									onClick={onCloseModal}
								/>
							</div>

							<Divider />

							{loading ? (
								<LoadingForm />
							) : (
								<>
									<span className={styles["form-section-title"]}>
										Configuración de zona
									</span>

									<div
										className={styles["row"]}
										style={{ paddingBottom: "30px" }}
									>
										<div className={`${styles["column"]} ${styles["flex"]}`}>
											<span className={styles["form-input-name"]}>
												Identificador del polígono{" "}
												<span className={styles["blue"]}>*</span>
											</span>
											<Input
												className={styles["form-input"]}
												style={{
													width: "95%",
												}}
												placeholder="Ingrese un nombre"
												value={polygonName}
												onChange={(e) => setPolygonName(e.target.value)}
												onBlur={validateInput}
												status={errorStatus.polygonName ? "error" : ""}
											/>
											{errorStatus.polygonName && (
												<div>
													<span
														className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
													>
														{errors.polygonName}
													</span>
												</div>
											)}
										</div>
										<div className={`${styles["column"]} ${styles["flex"]}`}>
											<span className={styles["form-input-name"]}>
												Color <span className={styles["blue"]}>*</span>
											</span>
											<ColorPicker
												showText
												onChange={onColorChange}
												value={colorPicked}
											/>
										</div>
									</div>

									<div className={`${styles["column"]} ${styles["align-end"]}`}>
										<div
											className={`${styles["row"]} ${styles["justify-end"]}`}
											style={{ zIndex: "21" }}
										>
											<div
												className={styles["filter-container"]}
												onClick={showFiltersHandler}
											>
												<span className={styles["filter-title"]}>Filtros</span>
												{filterCount > 0 && (
													<span
														className={`${styles["filter-dot"]} animate__animated animate__fadeIn`}
													>
														{filterCount}
													</span>
												)}
												<i
													className={`fa-solid fa-filter ${styles["filter-icon"]}`}
												></i>
											</div>
										</div>

										{showFilters && (
											<>
												<div
													className={`${styles["filter-box"]} animate__animated animate__fadeIn`}
												>
													<div
														className={`${styles["row"]} ${styles["space-between"]}`}
													>
														<span className={styles["filter-options"]}>
															Opciones de filtrado
														</span>
														<span
															className={styles["clean-filter-options"]}
															onClick={cleanFiltersHandler}
														>
															Limpiar filtros
														</span>
													</div>

													<br />

													<div className={styles["row"]}>
														<span className={styles["filter-box-text"]}>
															Tipos de plan
														</span>
													</div>

													<br />

													<div className={styles["row"]}>
														<div
															className={`${styles["category-box"]} ${
																planCategory.includes(1)
																	? styles["selected-category-box"]
																	: styles["normal-category-box"]
															}`}
															onClick={() => planCategoryHandler(1)}
														>
															<i
																className={`fa-solid fa-tower-broadcast ${styles["category-icon"]}`}
															></i>
															<span className={styles["category-title"]}>
																Internet
															</span>
														</div>
														<div
															className={`${styles["category-box"]} ${
																planCategory.includes(2)
																	? styles["selected-category-box"]
																	: styles["normal-category-box"]
															}`}
															style={{ margin: "0 10px" }}
															onClick={() => planCategoryHandler(2)}
														>
															<i
																className={`fa-solid fa-tv ${styles["category-icon"]}`}
															></i>
															<span className={styles["category-title"]}>
																Televisión
															</span>
														</div>
														<div
															className={`${styles["category-box"]} ${
																planCategory.includes(3)
																	? styles["selected-category-box"]
																	: styles["normal-category-box"]
															}`}
															onClick={() => planCategoryHandler(3)}
														>
															<i
																className={`fa-solid fa-phone-volume ${styles["category-icon"]}`}
															></i>
															<span className={styles["category-title"]}>
																Telefonía
															</span>
														</div>
													</div>

													<br />

													<div
														className={`${styles["row"]} ${styles["space-between"]}`}
													>
														<span className={styles["filter-box-text"]}>
															Tecnología
														</span>
														{operatorTechnologies.length > 3 && (
															<Checkbox
																className={styles["filter-box-text"]}
																style={{ fontWeight: "bold" }}
																onChange={showAllHandler}
																checked={showAll}
															>
																Ver todos
															</Checkbox>
														)}
													</div>

													<br />

													<Checkbox.Group
														value={planTechnology}
														onChange={planTechnologyHandler}
														style={{
															display: "flex",
															width: "100%",
															flexDirection: "column",
														}}
													>
														{operatorTechnologies.length > 3 && !showAll
															? operatorTechnologies
																	.slice(0, 3)
																	.map((technology) => (
																		<Checkbox
																			className={styles["filter-box-text"]}
																			value={technology.ID}
																			key={technology.ID}
																		>
																			{technology.name}
																		</Checkbox>
																	))
															: operatorTechnologies.length > 3 && showAll
															? operatorTechnologies.map((technology) => (
																	<Checkbox
																		className={styles["filter-box-text"]}
																		key={technology.ID}
																		value={technology.ID}
																	>
																		{technology.name}
																	</Checkbox>
															  ))
															: operatorTechnologies.length <= 3 &&
															  operatorTechnologies.map((technology) => (
																	<Checkbox
																		className={styles["filter-box-text"]}
																		key={technology.ID}
																		value={technology.ID}
																	>
																		{technology.name}
																	</Checkbox>
															  ))}
													</Checkbox.Group>
												</div>

												<div
													className={styles["filter-box-backdrop"]}
													onClick={showFiltersHandler}
												></div>
											</>
										)}
									</div>

									<div className={styles["row"]}>
										<Checkbox
											className={styles["select-all"]}
											indeterminate={
												checkedPlanList.length > 0 &&
												checkedPlanList.length < plansList.length
											}
											onChange={(e) =>
												setCheckedPlanList(
													e.target.checked
														? filteredPlansList.map((item) => item.ID)
														: []
												)
											}
											checked={checkedPlanList.length === plansList.length}
										>
											Seleccionar todos
										</Checkbox>
									</div>

									<br />

									<div className={styles["row"]}>
										<Checkbox.Group
											value={checkedPlanList}
											onChange={plansCheckHandler}
											style={{
												display: "flex",
												width: "100%",
												flexDirection: "column",
											}}
										>
											{filteredPlansList.length > 0 &&
												filteredPlansList.map((plan, index) => (
													<>
														<div
															className={`${styles["row"]} ${styles["space-between"]}`}
															key={plan.ID}
															style={{
																background:
																	index % 2 === 0 ? "#F7FAFC" : "none",
																border:
																	index % 2 === 0
																		? "none"
																		: "0.5px solid #ECECEC",
																padding: "15px",
																height: "150px",
																borderRadius: "5px",
															}}
														>
															<div className={styles["column"]}>
																<Checkbox value={plan.ID}>
																	<div className={styles["column"]}>
																		<span className={styles["plan-name"]}>
																			{plan.tradename}
																		</span>
																		<span className={styles["plan-tech"]}>
																			Tecnología {plan.technology_name}
																		</span>
																	</div>
																</Checkbox>
															</div>
															<div
																className={styles["column"]}
																style={{ width: "65%" }}
															>
																<span
																	className={
																		styles["plan-characteristics-title"]
																	}
																>
																	Especificaciones técnicas del plan
																</span>

																<span className={styles["plan-description"]}>
																	{plan.description}
																</span>

																{plan.category
																	.sort(function (a, b) {
																		return a - b;
																	})
																	.toString() === "1,2,3" && (
																	<FormTabsPanel
																		key={plan.ID}
																		selectedTabClassName="is-selected"
																	>
																		<TabList className="FormTabsList">
																			<Tab className="FormTab">Internet</Tab>
																			<Tab className="FormTab">Televisión</Tab>
																			<Tab className="FormTab">Telefonía</Tab>
																		</TabList>

																		<TabPanel>
																			<i
																				className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
																			></i>
																			<span
																				className={
																					styles["internet-characteristics"]
																				}
																			>
																				{plan.megas_upload} mb
																			</span>
																			<i
																				className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
																			></i>
																			<span
																				className={
																					styles["internet-characteristics"]
																				}
																			>
																				{plan.megas_download} mb
																			</span>
																		</TabPanel>
																		<TabPanel>
																			{plan.options_channels === 2 ? (
																				<span className={styles["sd-channels"]}>
																					Canales {plan.number_channels}{" "}
																					<b>SD</b>
																				</span>
																			) : plan.options_channels === 1 ? (
																				<div>
																					<span
																						className={styles["sd-channels"]}
																					>
																						Canales {plan.number_channels}{" "}
																						<b>SD</b>
																					</span>
																					<span
																						className={styles["hd-channels"]}
																					>
																						{plan.extra_channels_hd} <b>HD</b>
																					</span>
																				</div>
																			) : null}
																		</TabPanel>
																		<TabPanel>
																			{plan.unlimited_minutes === true ? (
																				<span className={styles["telephone"]}>
																					Minutos ilimitados
																				</span>
																			) : (
																				<span className={styles["telephone"]}>
																					{plan.phone_minutes} min llamadas
																					locales
																				</span>
																			)}
																		</TabPanel>
																	</FormTabsPanel>
																)}

																{plan.category
																	.sort(function (a, b) {
																		return a - b;
																	})
																	.toString() === "1,2" ? (
																	<FormTabsPanel
																		key={plan.ID}
																		selectedTabClassName="is-selected"
																	>
																		<TabList className="FormTabsList">
																			<Tab className="FormTab">Internet</Tab>
																			<Tab className="FormTab">Televisión</Tab>
																		</TabList>

																		<TabPanel>
																			<i
																				className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
																			></i>
																			<span
																				className={
																					styles["internet-characteristics"]
																				}
																			>
																				{plan.megas_upload} mb
																			</span>
																			<i
																				className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
																			></i>
																			<span
																				className={
																					styles["internet-characteristics"]
																				}
																			>
																				{plan.megas_download} mb
																			</span>
																		</TabPanel>
																		<TabPanel>
																			{plan.options_channels === 2 ? (
																				<span className={styles["sd-channels"]}>
																					Canales {plan.number_channels}{" "}
																					<b>SD</b>
																				</span>
																			) : plan.options_channels === 1 ? (
																				<div>
																					<span
																						className={styles["sd-channels"]}
																					>
																						Canales {plan.number_channels}{" "}
																						<b>SD</b>
																					</span>
																					<span
																						className={styles["hd-channels"]}
																					>
																						{plan.extra_channels_hd} <b>HD</b>
																					</span>
																				</div>
																			) : null}
																		</TabPanel>
																	</FormTabsPanel>
																) : plan.category
																		.sort(function (a, b) {
																			return a - b;
																		})
																		.toString() === "1,3" ? (
																	<FormTabsPanel selectedTabClassName="is-selected">
																		<TabList className="FormTabsList">
																			<Tab className="FormTab">Internet</Tab>
																			<Tab className="FormTab">Telefonía</Tab>
																		</TabList>

																		<TabPanel>
																			<i
																				className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
																			></i>
																			<span
																				className={
																					styles["internet-characteristics"]
																				}
																			>
																				{plan.megas_upload} mb
																			</span>
																			<i
																				className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
																			></i>
																			<span
																				className={
																					styles["internet-characteristics"]
																				}
																			>
																				{plan.megas_download} mb
																			</span>
																		</TabPanel>
																		<TabPanel>
																			{plan.unlimited_minutes === true ? (
																				<span className={styles["telephone"]}>
																					Minutos ilimitados
																				</span>
																			) : (
																				<span className={styles["telephone"]}>
																					{plan.phone_minutes} min llamadas
																					locales
																				</span>
																			)}
																		</TabPanel>
																	</FormTabsPanel>
																) : (
																	plan.category
																		.sort(function (a, b) {
																			return a - b;
																		})
																		.toString() === "2,3" && (
																		<FormTabsPanel selectedTabClassName="is-selected">
																			<TabList className="FormTabsList">
																				<Tab className="FormTab">
																					Televisión
																				</Tab>
																				<Tab className="FormTab">Telefonía</Tab>
																			</TabList>

																			<TabPanel>
																				{plan.options_channels === 2 ? (
																					<span
																						className={styles["sd-channels"]}
																					>
																						Canales {plan.number_channels}{" "}
																						<b>SD</b>
																					</span>
																				) : plan.options_channels === 1 ? (
																					<div>
																						<span
																							className={styles["sd-channels"]}
																						>
																							Canales {plan.number_channels}{" "}
																							<b>SD</b>
																						</span>
																						<span
																							className={styles["hd-channels"]}
																						>
																							{plan.extra_channels_hd} <b>HD</b>
																						</span>
																					</div>
																				) : null}
																			</TabPanel>
																			<TabPanel>
																				{plan.unlimited_minutes === true ? (
																					<span className={styles["telephone"]}>
																						Minutos ilimitados
																					</span>
																				) : (
																					<span className={styles["telephone"]}>
																						{plan.phone_minutes} min llamadas
																						locales
																					</span>
																				)}
																			</TabPanel>
																		</FormTabsPanel>
																	)
																)}

																{plan.category.toString() === "1" ? (
																	<FormTabsPanel selectedTabClassName="is-selected">
																		<TabList className="FormTabsList">
																			<Tab className="FormTab">Internet</Tab>
																		</TabList>

																		<TabPanel>
																			<i
																				className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
																			></i>
																			<span
																				className={
																					styles["internet-characteristics"]
																				}
																			>
																				{plan.megas_upload} mb
																			</span>
																			<i
																				className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
																			></i>
																			<span
																				className={
																					styles["internet-characteristics"]
																				}
																			>
																				{plan.megas_download} mb
																			</span>
																		</TabPanel>
																	</FormTabsPanel>
																) : plan.category.toString() === "2" ? (
																	<FormTabsPanel selectedTabClassName="is-selected">
																		<TabList className="FormTabsList">
																			<Tab className="FormTab">Televisión</Tab>
																		</TabList>

																		<TabPanel>
																			{plan.options_channels === 2 ? (
																				<span className={styles["sd-channels"]}>
																					Canales {plan.number_channels}{" "}
																					<b>SD</b>
																				</span>
																			) : plan.options_channels === 1 ? (
																				<div>
																					<span
																						className={styles["sd-channels"]}
																					>
																						Canales {plan.number_channels}{" "}
																						<b>SD</b>
																					</span>
																					<span
																						className={styles["hd-channels"]}
																					>
																						{plan.extra_channels_hd} <b>HD</b>
																					</span>
																				</div>
																			) : null}
																		</TabPanel>
																	</FormTabsPanel>
																) : (
																	plan.category.toString() === "3" && (
																		<FormTabsPanel selectedTabClassName="is-selected">
																			<TabList className="FormTabsList">
																				<Tab className="FormTab">Telefonía</Tab>
																			</TabList>

																			<TabPanel>
																				{plan.unlimited_minutes === true ? (
																					<span className={styles["telephone"]}>
																						Minutos ilimitados
																					</span>
																				) : (
																					<span className={styles["telephone"]}>
																						{plan.phone_minutes} min llamadas
																						locales
																					</span>
																				)}
																			</TabPanel>
																		</FormTabsPanel>
																	)
																)}
															</div>
														</div>

														<div style={{ margin: "7.5px" }}></div>
													</>
												))}
										</Checkbox.Group>
									</div>

									<div
										className={`${styles["row"]} ${styles["justify-end"]} ${styles["action-buttons-margin"]}`}
									>
										<Space>
											<Button
												className={`${styles["form-action-button"]} ${styles["form-action-button-cancel"]}`}
												type="delete"
												onClick={() => onCloseModal()}
											>
												Cancelar
											</Button>
											{modalHeader === "Detalles" ? (
												<Button
													className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
													onClick={() => {
														onSubmit({
															name: polygonName,
															color: colorPicked,
															location: {
																type: "Polygon",
																coordinates: [invertedCoordinates],
															},
															coverage_center: {
																latitude: coordinatesCenter[0],
																longitude: coordinatesCenter[1],
															},
															operator: operatorID,
															plans: checkedPlanList,
														});
													}}
												>
													Guardar
												</Button>
											) : (
												<Button
													className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
													onClick={() => verifyStatus()}
												>
													Confirmar
												</Button>
											)}
										</Space>
									</div>
								</>
							)}
						</div>
					</>
				)}

				{/*  form Security */}
				{visibleVerify && (
					<div className={styles["authentication-modal-container"]}>
						<AuthenticationGoggle
							visible={visibleVerify}
							setVisible={setVisibleVerify}
							confirmResponse={confirmResponse}
							cancel={() => {
								onCloseModal();
							}}
						/>
					</div>
				)}
			</div>
		);
	} else {
		return null;
	}
};

export default FormOperatorCoverage;
