import React, { useState, useEffect } from "react";
import { Panel, PanelSubHeader } from "../../../components/panel/panel";
import TableData from "../../../components/table/TableData";
import { Select, DatePicker, Input, notification } from "antd";
import styled from "styled-components";
import { myFetch, myFetchGet, myExportData } from "../../../services/services";
import { Loading } from "../../../components/loading/Loading";
import { useReduxToken } from "../../../hooks/useReduxToken";
import ColorCircle from "../../../components/colorCircle/ColorCircle";
import TableText from "../../../components/tableText/TableText";
import fileDownload from "js-file-download";
import styles from "./PurchasesPanel.module.css";
import TablePagination from "../../../components/tablePagination/TablePagination";

const PurchasesPanel = ({ data }) => {
	const token = useReduxToken();

	const [loadingTable, setLoadingTable] = useState(true);
	const [tableColumns, setTableColumns] = useState([]);
	const [totalTableSize, setTotalTableSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);
	const [operatorsList, setOperatorsList] = useState();
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 });

	const [operatorID, setOperatorID] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [searchData, setSearchData] = useState();
	const [sorterOrder, setSorterOrder] = useState("desc");
	const [exportFilters, setExportFilters] = useState({});

	const { id_unique } = data;

	const clearData = undefined;

	const { RangePicker } = DatePicker;
	const { Search } = Input;

	const initial = async () => {
		const operator = `["operator","equal","` + operatorID + `"]`;
		const period =
			`["created","gt","` +
			startDate +
			` 00:00"], ["created","lte","` +
			endDate +
			` 23:59"]`;
		const head = `[`;
		const tail = `]`;
		let filters = {};

		if (
			operatorID !== undefined &&
			startDate === undefined &&
			endDate === undefined &&
			searchData === undefined
		) {
			filters = {
				filters: head + operator + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			startDate !== undefined &&
			endDate !== undefined &&
			operatorID === undefined &&
			searchData === undefined
		) {
			filters = {
				filters: head + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			searchData !== undefined &&
			operatorID === undefined &&
			startDate === undefined &&
			endDate === undefined
		) {
			filters = {
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			operatorID !== undefined &&
			startDate !== undefined &&
			endDate !== undefined &&
			searchData === undefined
		) {
			filters = {
				filters: head + operator + `, ` + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			operatorID !== undefined &&
			searchData !== undefined &&
			startDate === undefined &&
			endDate === undefined
		) {
			filters = {
				filters: head + operator + tail,
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			startDate !== undefined &&
			endDate !== undefined &&
			searchData !== undefined &&
			operatorID === undefined
		) {
			filters = {
				filters: head + period + tail,
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else {
			filters = {
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		}

		const newFilters = {
			...filters,
			order_field: "ID",
			order_type: sorterOrder,
		};

		setExportFilters(newFilters);

		try {
			const response = await myFetch(
				`api/v1/shopping/datatables/?user=${id_unique}`,
				token,
				newFilters
			);
			if (response) {
				if (response.size) {
					setTotalTableSize(response.size);
				}
				if (response.data) {
					const rows = response.data.map((row) => {
						return {
							...row,
							key: row.ID,
						};
					});
					setTableRows(rows);
				}
			}
			setLoadingTable(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const getColumns = async () => {
		try {
			const data = await myFetchGet("api/v1/shopping/datatables_struct", token);
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					sorter: columnsTittle === "ID" ? true : null,
					title: columnsTittle,
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					render:
						columnsTittle === "Estatus"
							? (value) => (
									<span
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{value === "Sin Atender" ? (
											<ColorCircle color={"#1659e7"} />
										) : value === "Agendada" ? (
											<ColorCircle color={"#F2A900"} />
										) : value === "Instalada" ? (
											<ColorCircle color={"#58E933"} />
										) : value === "Cancelada" ? (
											<ColorCircle color={"#F00A0A"} />
										) : value === "En Instalación" ? (
											<ColorCircle color={"#C424F5"} />
										) : (
											<ColorCircle color={"grey"} />
										)}
										<TableText value={value} />
									</span>
							  )
							: (value) => <TableText value={value} />,
				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	};

	const tableChangeHandler = (pagination, filter, sorter, extra) => {
		if (sorter.order === "ascend") {
			setSorterOrder("asc");
		} else if (sorter.order === "descend" || sorter.order === undefined) {
			setSorterOrder("desc");
		}
	};

	const getOperators = async () => {
		try {
			const operators = await myFetchGet("api/v1/operador/", token);
			setOperatorsList(operators);
		} catch (e) {
			console.log("error", e);
		}
	};

	useEffect(() => {
		initial();
		getColumns();
	}, [operatorID, startDate, endDate, searchData, rowsParams, sorterOrder]);

	useEffect(() => {
		getOperators();
	}, []);

	const handleOperatorSelect = (value) => {
		setOperatorID(value);
	};

	const dateFormat = "DD/MM/YYYY";

	const dateSelectHandler = (dates, dateStrings) => {
		if (dateStrings[0] === "" && dateStrings[1] === "") {
			setStartDate(clearData);
			setEndDate(clearData);
		} else {
			setStartDate(dateStrings[0]);
			setEndDate(dateStrings[1]);
		}
	};

	const searchHandler = (value) => {
		if (value === "") {
			setSearchData(clearData);
		} else {
			setSearchData(value);
		}
	};

	const onExportData = async () => {
		try {
			const file = await myExportData(
				`api/v1/shopping/datatables_download/?user=${id_unique}`,
				token,
				exportFilters
			);
			fileDownload(file, "Listado de Compras.xlsx");
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<div className="col-md-12">
			<Panel>
				<PanelSubHeader>
					<FilterContainer>
						<span className={styles["filters-title"]}>
							Opciones de filtrado
						</span>
						<div className={styles["row"]}>
							<div className={styles["column"]}>
								<Select
									className={styles["select"]}
									placeholder="Operador"
									onChange={handleOperatorSelect}
									allowClear={true}
									onClear={handleOperatorSelect}
								>
									{operatorsList?.map((item) => (
										<Select.Option key={item?.ID} value={item?.ID}>
											{item?.name}
										</Select.Option>
									))}
								</Select>
							</div>
							<div className={styles["column"]}>
								<RangePicker
									className={styles["date-picker"]}
									placeholder={["Fecha Inicio", "Fecha Fin"]}
									onChange={dateSelectHandler}
									format={dateFormat}
								/>
							</div>
							<div className={`${styles["column"]} ${styles["position-end"]}`}>
								<Search
									className={styles["search-input"]}
									placeholder="Realizar búsqueda"
									allowClear
									onSearch={searchHandler}
								/>
							</div>
						</div>
					</FilterContainer>
				</PanelSubHeader>
				{loadingTable ? (
					<Loading />
				) : (
					<>
						<TableData
							rows={tableRows}
							columns={tableColumns}
							onChange={tableChangeHandler}
						/>

						<div
							className={`${styles["row"]} ${styles["pagination-container"]}`}
						>
							<TablePagination
								paginationSettings={paginationSettings}
								totalTableSize={totalTableSize}
								onChange={modifyPagination}
								showTotal={(total, range) => (
									<div className={styles["column"]}>
										<span className={styles["pagination"]}>
											Mostrando del {range[0]} al {range[1]} de un total de{" "}
											{total} registros
										</span>
										<span
											className={styles["export-button"]}
											onClick={onExportData}
										>
											<i
												className={`fa-solid fa-file-excel ${styles["export-button-icon"]}`}
											></i>
											Exportar data
										</span>
									</div>
								)}
							/>
						</div>
					</>
				)}
			</Panel>
		</div>
	);
};

export default PurchasesPanel;

const FilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.ant-select-selector {
		border-radius: 5px !important;
		border: 1px solid #e0e0e0 !important;
		display: flex;
		align-items: center;
		height: 38px !important;
		font-size: 12px;
	}

	.ant-select-clear {
		display: flex;
		align-items: center;
	}

	.ant-picker .ant-picker-input > input {
		font-size: 12px;
	}

	.ant-input-affix-wrapper:not(:last-child) {
		border-radius: 5px 0 0 5px !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		font-size: 12px;
	}

	.ant-input-search
		> .ant-input-group
		> .ant-input-group-addon:last-child
		.ant-input-search-button {
		border-radius: 0 5px 5px 0 !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input,
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input,
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input,
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 15.18px;
		}
	}
`;
